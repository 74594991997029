import "./styles.css";

function ContentBox(props) {
  const { number, text } = props;

  return (
    <div className="boxCard">
      <h2 className="fw-semibold title mb-0">{number}</h2>
      <h4 className="text mb-0 fw-semibold">{text}</h4>
    </div>
  );
}

export default ContentBox;
