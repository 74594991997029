import { Marker } from "react-leaflet";
import { useCustomIconList } from "../../../utils/MapMarkersList";

export default function EndPatrolMarker({ gpsEndCoordX, gpsEndCoordY }) {
  const { PatrolIcons } = useCustomIconList();
  return (
    <div className="markr__container">
      {gpsEndCoordY && (
        <Marker
          icon={PatrolIcons.patrolEnd}
          position={[gpsEndCoordX, gpsEndCoordY]}
        ></Marker>
      )}
    </div>
  );
}
