import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchObservaionTypeList,
  useFetchSiteSgmntListByProject,
} from "../../../../queries/useFetchOptions";
import {
  createObservaion,
  createObservationLocally,
} from "../../../../actions/projectActions";

// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDate5 } from "../../../../utils/DateFormater";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import unavailableMapPlaceHolder from "../../../../assets/images/unavailableMapPlaceholder.svg";
import uploadImagePlaceHolder from "../../../../assets/images/uploadImagePlaceholder.svg";
import { useTranslation } from "react-i18next";

import ObservationTypePill from "../../ObservationTypePill";
import {
  useCreateNewFemaleEncounter,
  useCreateObservationStore,
} from "../../../../lib/createObs/useCreateObservationData";
import useCreateValidation from "../../../../lib/createObs/useCreateValidation";
import ModalsMap from "../../modalsMolecules/ModalsMap";
import DateTimeSelector from "../../modalsMolecules/DateTimeSelector";
import ConfirmExit from "../createObs/createMolecules/ConfirmExit";
import SiteSegmentInputs from "../createObs/createMolecules/SiteSegmentInputs";
import CreateNewFemaleEncounter from "../createObs/createMolecules/obsDynamicFields/CreateNewFemaleEncounter";
import GroupFamSpecie from "../createObs/createMolecules/GroupFamSpecie";
import ImagesUpload from "../createObs/createMolecules/ImagesUpload";
import CoordinatesInputs from "../createObs/createMolecules/CoordinatesInputs";
import { handleFormatData } from "../../../../lib/createObs/formatDataForCreate";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

dayjs.extend(customParseFormat);

const NewFemaleCreate = ({ handleHide }) => {
  // validation
  const {
    setLocalLatValidation,
    setLocalObsTypeValidation,
    setLocalSpeciesState,
    setLocalGroupValidation,
    setLocalSiteValidation,
    setLocalFamilyValidation,

    setLocalFemEncLengthValidation,
    setLocalFemEncWidthValidation,

    setLocalNotesValidation,

    resetAllValidations,
  } = useCreateValidation();

  // new Form data management
  const {
    newDate,
    currentTime,
    seconds,
    newLocalLat,
    newLocalLong,
    groupeState,
    familyState,
    speciesState,
    siteState,
    segmentState,
    deadOrAlive,
    status,
    femEncTagLeft,
    femEncTagRight,
    femEncLength,
    femEncWidth,

    displaySpecies,
    displayGroupe,
    displayFamily,
    displaySegment,
    displaySite,

    setSeconds,
    newLat,
    newLong,

    setIsFemalePresent,
  } = useCreateObservationStore();
  const {
    images,
    imageData,
    setImages,
    setImageData,
    notes,
    crawlWidth,
    obsTypeState,
    setNotes,
    setCrawlWidth,
    resetFCreateValues,
  } = useCreateNewFemaleEncounter();

  const { t } = useTranslation();

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  // validation

  const validationSchema = yup.object().shape({
    coords: yup.string().required(`${t("required")}`),
    site: yup.string(),
    segment: yup.string(),
    observationType: yup.string().required(`${t("required")}`),
    groupe: yup.string().required(`${t("required")}`),
    family: yup.string().required(`${t("required")}`),
    specie: yup.string().required(`${t("required")}`),
  });

  const {
    register,

    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  function getRandomNumber() {
    let seconds = Math.floor(Math.random() * 60)
      .toString()
      .padStart(2, "0");
    setSeconds(seconds);
  }
  useEffect(() => {
    getRandomNumber();
  }, []);

  const [user, setuser] = useState();
  useEffect(() => {
    if (userInfo) {
      setuser(userInfo.data.email);
    }
  }, []);

  const dispatch = useDispatch();

  // Upload images function

  const [showUploadUI, setShowUploadUI] = useState(false);

  let currentProject = JSON.parse(localStorage.getItem("selectedProject"));
  // setSelectedProject(currentProject);

  const onSubmit = (data) => {
    const newData = handleFormatData({
      projectId: currentProject && currentProject.id,
      newDate,
      currentTime,
      seconds,
      newLocalLat,
      newLocalLong,
      obsTypeState,
      groupeState,
      familyState,
      speciesState,
      siteState,

      segmentState,
      deadOrAlive,
      images,
      imageData,

      femEncTagLeft,
      femEncTagRight,
      femEncLength,
      femEncWidth,
      crawlWidth,
      notes,
    });

    // Local project update
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: "",
      projectId: currentProject && currentProject.id,
      projectName: currentProject && currentProject.name,
      idInaturalist: null,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: "",
      alpha: "",
      collector: "",
      images: {
        img1: "",
        img2: "",
        img3: "",
        img4: "",
      },
      dead: deadOrAlive,
      status: 0,
      TypeObservationId: obsTypeState,
      TypeObservation: displayObserVationType,
      specieId: speciesState,
      specie: displaySpecies,
      familyId: familyState,
      family: displayFamily,
      groupId: groupeState,
      group: displayGroupe,
      user: user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(createObservaion(newData));
    dispatch(createObservationLocally(updtedObservationData));
    resetFCreateValues();
    handleHide();
  };

  const handleCreateObs = () => {
    if (
      newLocalLat &&
      newLocalLong &&
      obsTypeState &&
      speciesState &&
      siteState
    ) {
      onSubmit();
    }
    if (!newLocalLat) {
      setLocalLatValidation(true);
    }
    if (!obsTypeState) {
      setLocalObsTypeValidation(true);
    }
    if (!speciesState) {
      setLocalSpeciesState(true);
    } else {
      setLocalSpeciesState(false);
    }
    if (!groupeState) {
      setLocalGroupValidation(true);
    } else {
      setLocalGroupValidation(false);
    }
    if (!familyState) {
      setLocalFamilyValidation(true);
    } else {
      setLocalFamilyValidation(false);
    }
    if (!siteState) {
      setLocalSiteValidation(true);
    }

    if (femEncLength === 0) {
      setLocalFemEncLengthValidation(true);
    }
    if (femEncWidth === 0) {
      setLocalFemEncWidthValidation(true);
    }

    setConfirmExit(false);
  };

  // exit box
  const [confirmExit, setConfirmExit] = useState(false);
  /**
   * Handles exiting the edit mode for the observation creation modal.
   * This function resets the create values, resets all validations, and hides the modal.
   */
  const handleExitEdit = () => {
    setConfirmExit(false);
    // resetCreateValues();
    resetAllValidations();
    handleHide();
  };
  const handleConfirmExit = () => {
    setConfirmExit(false);
  };

  // input tracker
  /**
   * Handles the logic for exiting the edit mode of the observation creation modal.
   * If there are unsaved changes (`changeTracker` is true), it will display a confirmation modal.
   * Otherwise, it will reset the create values, reset all validations, and hide the modal.
   */
  const [changeTracker, setChangeTracker] = useState(false);
  const handleShowConfirmModal = () => {
    setIsFemalePresent(false);
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      handleExitEdit();
    }
  };

  // display observation type
  const [displayObserVationType, setDisplayObserVationType] = useState(null);

  const cordRef = useRef();
  const modalRef = useRef(null);

  let convertedDate = formatDate5(newDate);

  return (
    <div className="mvp__modals female">
      {/* {user && } */}

      <Modal
        show={true}
        onHide={handleHide}
        backdrop="static"
        centered
        className="mx-auto mvp__modals female create__modal"
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header text-left  "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("createObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                // setSaveConfirmation(true);
                // setConfirmExit(true);
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {!showUploadUI ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="modal-top"
                style={{ borderBottom: "0.3px solid #ebf0ec" }}
              >
                <div
                  className="modal-map"
                  style={{ width: "50%", height: "100%" }}
                >
                  {!newLocalLat && !newLocalLong ? (
                    <img
                      src={unavailableMapPlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt=""
                    />
                  ) : (
                    newLocalLat &&
                    newLocalLong && (
                      <ModalsMap
                        displayObserVationType={t(
                          "dbData.obsTypes.femaleEncounter"
                        )}
                        newLat={newLat}
                        newLong={newLong}
                      />
                    )
                  )}
                </div>
                <div
                  className="modal-imgs "
                  style={{ width: "50%", height: "100%", position: "relative" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {images.length === 0 ? (
                      <img
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        src={uploadImagePlaceHolder}
                        style={{
                          height: "100%",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          backgroundImage: `url(${images[0].data_url})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: " center center",
                          backgroundSize: "cover",
                        }}
                      />
                    )}
                  </div>
                  {images && images.length !== 0 && (
                    <div
                      className="modal-photo-btn create"
                      onClick={() => {
                        setShowUploadUI(!showUploadUI);
                      }}
                    >
                      {images && images.length} + photos {">"}
                    </div>
                  )}
                </div>
              </div>

              <div className="container-fluid modalBody" ref={modalRef}>
                {/* OBS Display */}
                <div className="row ">
                  <div className="col-12 px-4 pt-1 ">
                    <span
                      className="f-bold me-2 single__obs__id"
                      // style={{ fontSize: "1.1rem" }}
                    >
                      Obs {currentProject && currentProject.id}
                    </span>

                    <ObservationTypePill
                      displayObserVationType={t(
                        "dbData.obsTypes.femaleEncounter"
                      )}
                    />
                  </div>
                  <div className="col-5 text-end"></div>
                </div>

                {/* Project Name */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.name}
                  </div>
                </div>

                {/* Author */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {"Observer name"}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.user}
                  </div>
                </div>

                {/* Date, Time */}

                <DateTimeSelector
                  psudoClass="female"
                  errors={errors}
                  setChangeTracker={setChangeTracker}
                />

                {/* Coords */}
                <CoordinatesInputs
                  cordRef={cordRef}
                  setChangeTracker={setChangeTracker}
                  errors={errors}

                  // store
                />

                {/* Site, Segment */}

                <SiteSegmentInputs
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                />

                {/* Group / Family / Species */}
                <GroupFamSpecie
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                />

                {/* NEW NEST DYNAMIC BREAKPOINT*/}
                <div>
                  <div>
                    <CreateNewFemaleEncounter
                      errors={errors}
                      setChangeTracker={setChangeTracker}
                      crawlWidth={crawlWidth}
                      setCrawlWidth={setCrawlWidth}
                    />

                    {/* Notes */}
                    <div className="row px-3 modal__obs__details__row mt-1">
                      <div className="col-5 modal__detail__title">
                        {"Notes"}
                      </div>
                      <div className="col-7 modal__title__value">
                        <input
                          type="text"
                          id="notes"
                          name="notes"
                          value={notes}
                          className={`crud_select crud-input ${
                            errors.notes ? "is-invalid" : ""
                          }`}
                          style={{ width: "101%" }}
                          onChange={(e) => {
                            setNotes(e.target.value);

                            setChangeTracker(true);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className=""
                      style={{
                        height: "100px",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className="modal-footers"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="modal_obs__btn"
                    onClick={() => {
                      handleShowConfirmModal();
                    }}
                  >
                    {t("cancelBtn")}
                  </div>
                  <div
                    // type="submit"
                    className="modal_obs__btn validate"
                    onClick={() => handleCreateObs()}
                  >
                    {t("createBtn")}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <ImagesUpload
            setChangeTracker={setChangeTracker}
            setShowUploadUI={setShowUploadUI}
            showUploadUI={showUploadUI}
            images={images}
            setImages={setImages}
            setImageData={setImageData}
          />
        )}
      </Modal>

      {/* {confirmExit && ( */}
      <ConfirmExit
        confirmExit={confirmExit}
        handleCreateObs={handleCreateObs}
        handleExitEdit={handleExitEdit}
        handleConfirmExit={handleConfirmExit}
      />
    </div>
  );
};

export default NewFemaleCreate;
