import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getRole,
  itemList$,
  // role$,
  setItemList,
  setRefreshUser,
} from "../../services/rxjs";
import { API_URL as API } from "../../services/API";

const ChangeRoleAdmin = ({
  value,

  data,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = useState(0);
  const [idUser, setIdUser] = useState(0);
  const [userName, setUserName] = useState("");
  const [actualItem, setActualItem] = useState({});
  const isUserAdmin = useSelector((state) => state.userRoleOnParticularProject);

  useEffect(() => {
    itemList$.subscribe((result) => setActualItem(result));

    getRole();
  }, []);

  const setDatabase = (id, role) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    axios
      .post(
        API + "api/user/changerole",
        {
          userproject_id: id,
          role: role,
        },
        config
      )
      .then(function (response) {
        updateTable(role);
        setItemList(-1);
        // setShowContent(false);
        handleDeleteModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDeleteModal = () => {
    setShowModal(false);
  };

  const updateTable = (role) => {
    setRefreshUser();
    // let rowsClone = [...tableManager.rowsApi.rows];
    // let updatedRowIndex = rowsClone.findIndex(
    //   (r) => r.userprojectid === data.userprojectid
    // );

    // rowsClone[updatedRowIndex].role = role;

    // setRowsData(rowsClone);
  };

  const save = () => {
    setDatabase(idUser, role);
  };

  const setName = (data) => {
    if (data.firstname !== "" && data.firstname !== null)
      setUserName(data.firstname);
    else {
      if (data.lastname !== "" && data.lastname !== null)
        setUserName(data.lastname);
      else setUserName(data.email);
    }
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    // <div style={styles.root}>

    //     <React.Fragment>
    //       <img style={styles.img} src={data.img1} alt="avatar" />
    //       <span style={styles.text}>{value}</span>
    //     </React.Fragment>

    // </div>

    isUserAdmin.role === false ? (
      <div className="contentRole ">
        <span>{value}</span>
      </div>
    ) : (
      <div
        className="contentRole "
        onClick={(e) => {
          e.stopPropagation();

          if (data.id !== actualItem) setItemList(data.id);
          else setItemList(-1);
          // setShowContent(!showContent);
        }}
      >
        <span>{value}</span>

        <svg
          width="13"
          height="8"
          className="choiserole"
          viewBox="0 0 13 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91 0.589844L6.5 5.16984L11.09 0.589844L12.5 1.99984L6.5 7.99984L0.5 1.99984L1.91 0.589844Z"
            fill="#073B60"
          />
        </svg>

        {data.id === actualItem && (
          <div className="roleselect hideContent">
            <span
              className={`spanslt ${
                value === "Admin" || value === "admin" ? "selected" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setIdUser(data.userprojectid);

                if (data.role === "Regular user") {
                  setRole("Admin");
                  // setUserName(data.firstname);
                  setName(data);
                  setShowModal(true);
                } else {
                  setItemList(-1);
                  setName(data);
                  // setShowContent(false);
                }
                // setDatabase(data.userprojectid,);
              }}
            >
              Admin
            </span>
            <div
              className={`spanslt ${
                value !== "Admin" && value !== "admin" ? "selected" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setIdUser(data.userprojectid);
                if (data.role !== "Regular user") {
                  setRole("Regular user");
                  // setUserName(data.firstname);
                  setName(data);
                  setShowModal(true);
                } else {
                  setItemList(-1);
                  setName(data);
                  // setShowContent(false);
                }

                // setDatabase(data.userprojectid,"Regular user");
              }}
            >
              Regular user
            </div>
          </div>
        )}

        <Modal
          show={showModal}
          onHide={handleDeleteModal}
          backdrop="static"
          centered
          className="mx-auto"
          keyboard={false}
          // size="sm"
          dialogClassName="tailledeletemodal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className=" text-end p-2 ">
            <span
              className="pe-sm-2 pointer "
              onClick={() => {
                handleDeleteModal();
              }}
            >
              <img
                src="assets/elements/images/cross.png"
                alt=""
                style={{ height: "15px", cursor: "pointer" }}
              />
            </span>
          </div>

          <Modal.Body className="pe-4 ps-4">
            <div className=" d-flex justify-content-center">
              <img
                src="assets/elements/images/delete_icon2.png"
                alt=""
                width={40}
              />
            </div>
            <div className="text-center h5 mt-3">
              Assign {userName} as {role}?
            </div>
            <div className="text-center mb-3">
              {role !== "Admin"
                ? `${userName} will only be able to view, edit & delete their own observations.`
                : `${userName} will be able to view, edit & delete all project observations.`}
            </div>
          </Modal.Body>

          <div
            className="d-flex mt-3"
            style={{
              borderTop: ".5px solid #B3B3B3",
            }}
          >
            <div
              className="text-center p-2 fs-7 cancelbtn"
              style={{
                borderRight: ".5px solid #B3B3B3",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              Cancel
            </div>
            <div
              className="p-2 text-center fs-7 validbtn"
              style={{
                width: "50%",
                color: "#404040",
                cursor: "pointer",
              }}
              onClick={() => {
                save();
              }}
            >
              Assign
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default ChangeRoleAdmin;
