import React, { useEffect, useState } from "react";

import "./admin.css";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from "react-hook-form";

import Menuadmin from "../../components/menuadmin/menuadmin";
import AccountContent from "../../components/accoutContent/accountcontent";
import ProjectContent from "../../components/projectContent/projectcontent";
import DetailProject from "../../components/detailProject/detailproject";
import Members from "../../components/members/members";
import { useDispatch } from "react-redux";

import { API_URL as API } from "../../services/API";
import axios from "axios";
import { useFetchadminProjectList } from "../../queries/useMapQueriesHook";
import {
  setProject,
  project$,
  getProject,
  user$,
  getUser,
  role$,
  getRole,
  setUserjob,
} from "./../../services/rxjs";
import Header from "../../components/Header/Header";
import AddProject from "../../components/addProject/addProject";
import { observationTypesAction } from "../../actions/projectActions";
import { useFetchCFunctionList } from "../../queries/useFetchOptions";
import { useTranslation } from "react-i18next";
import SiteSegmentView from "../../components/projectContent/SiteSegmentView";
import QuestionsView from "../../components/questionsAnswers/QuestionsView";

const Admin = ({ singleObservation }) => {
  const { t, i18n } = useTranslation();
  const {
    functionListData,
    functionListError,
    functionListLoading,
    refetchFunctionList,
    originalFuncData,
  } = useFetchCFunctionList();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [user, setUser] = useState({});

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // ///
  //   const [show, setShow] = useState("false");

  //menuadmin
  const [contentMenu, setContentMenu] = useState(1);
  const [lastProject, setLastProject] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [mycountry, setMycountry] = useState({});

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [currentJob, setCurrentJob] = useState();
  const dispatch = useDispatch();
  const [translatedJob, setTranslatedJob] = useState();
  useEffect(() => {
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: [],
      })
    );

    project$.subscribe((changedProject) => setP(changedProject));
    role$.subscribe((changeRole) => setUserRole(changeRole));
    getProject();
    getRole();
    user$.subscribe((user) => setUserInfo(user));

    setUserData();

    setUserData();

    // listProject(userInfo.data.token);

    const getCountries = async () => {
      const response = await axios.get(API + "api/noconnect/country/list");
      const data = response.data.data;

      filloption(data);
    };

    const getCurrentFunction = async () => {
      if (originalFuncData) {
        setCurrentJob(
          ...originalFuncData.filter(
            (cn) => cn.title === userInfo.data.fonction
          )
        );
      }
    };
    getCurrentFunction();

    const getFunctions = async () => {
      if (functionListData && originalFuncData) {
        const currentFunc = originalFuncData?.filter(
          (cn) => cn.title === userInfo.data.fonction
        );
        const currentJobTraslate = currentFunc.reduce((result, item) => {
          if (i18n.language === "en") {
            result.push({ value: item.id, label: item.title });
          } else if (i18n.language === "fr") {
            const matchingItem = functionListData.find(
              (i) => i.id === currentJob.id
            );

            if (matchingItem) {
              result.push({ value: item.id, label: matchingItem.title });
            }
          }
          return result;
        }, []);

        setUserjob(currentJobTraslate);
        setTranslatedJob(currentJobTraslate);
        const sortedData = functionListData.sort((a, b) => {
          if (a.title === "Other" || a.title === "Autre") return 1;
          if (b.title === "Other" || a.title === "Autre") return -1;
          return a.title.localeCompare(b.title);
        });

        setFunctionList(
          sortedData.map((item) => ({ value: item.id, label: item.title }))
        );
      }
    };

    getCountries();
    getFunctions();
  }, [functionListData, originalFuncData, i18n, currentJob]);

  const setP = (changedProject) => {
    setLastProject(changedProject);
  };

  const setUserData = () => {
    setUser({
      api: API,
      id: userInfo.data.id,
      info: {
        country: userInfo.data.country,
      },
      token: userInfo.data.token,
    });
  };

  const filloption = (data) => {
    let content = [];

    if (data.length > 0)
      for (let i = 0; i < data.length; i++) {
        let newops = { value: data[i].id, label: data[i].name };
        content.push(newops);
        if (data[i].name === userInfo.data.country) {
          setMycountry({ value: data[i].id, label: data[i].name });
        }
      }

    // setOps(content);
    setCountryList(content);
  };

  //menuadmin
  const changeMenu = (menu) => {
    // if(menu === 5)
    //   setShowDeleteModal(true);

    if (menu === 2) {
      if (isAdmin) setContentMenu(2);
      else setContentMenu(4);
    } else {
      setContentMenu(menu);
    }
    if (menu === 4) {
      setContentMenu(8);
    } else if (menu === 5) {
      setContentMenu(9);
    }
    // setContentMenu(menu);
  };

  const showFormProject = () => {
    setContentMenu(5);
    // setContentMenu(2);
  };

  const update = (id, name) => {
    setUserRole("admin");
  };

  const { refetch } = useFetchadminProjectList();

  const refreshProjectList = () => {
    refetch();
    setContentMenu(2);
  };

  const setUserRole = (roleUser) => {
    if (roleUser === "Regular user") {
      setIsAdmin(false);
      if (contentMenu === 2) setContentMenu(4);
    } else {
      setIsAdmin(true);
      if (contentMenu === 2) setContentMenu(6);
      if (contentMenu === 4) {
        setContentMenu(2);
      }
      // if (contentMenu === 2) setContentMenu(6);
      if (contentMenu === 6) setContentMenu(2);
    }
  };

  return (
    <div className="header-list-view">
      <Header />

      <div className="flex-container">
        <Menuadmin
          childrenCheck={changeMenu}
          fname={userInfo.data.firstname}
          lname={userInfo.data.lastname}
          job={translatedJob ? translatedJob[0]?.label : ""}
          setmenu={contentMenu}
        />

        {contentMenu === 1 && (
          <AccountContent
            country={countryList}
            info={user.info}
            user={userInfo}
            saveData={userInfo.data}
            userCountry={mycountry}
            userPhone={"+" + userInfo.data.codeTel + " " + userInfo.data.phone}
            telNum={userInfo.data.phone}
            sigle={userInfo.data.sigle}
            codePhone={userInfo.data.codeTel}
            jobs={functionList}
          />
        )}

        {contentMenu === 2 && (
          <ProjectContent
            creation={false}
            admin={isAdmin}
            childrenCheck={changeMenu}
            user={user}
            project={lastProject}
            country={countryList}
            updateProject={update}
            projectRefresh={refreshProjectList}
            publicAttribute={lastProject?.public}
          />
        )}
        {contentMenu === 3 && (
          <Members user={user} project={lastProject} proId={lastProject?.id} />
        )}
        {/* {(contentMenu === 4) &&  <DetailProject project={lastProject} childrenCheck={showFormProject} />} */}
        {contentMenu === 4 && (
          <DetailProject
            project={lastProject}
            childrenCheck={showFormProject}
            goForm={showFormProject}
          />
        )}
        {contentMenu === 5 && (
          <ProjectContent
            creation={true}
            admin={true}
            childrenCheck={changeMenu}
            user={user}
            project={{}}
            country={countryList}
            updateProject={update}
            projectRefresh={refreshProjectList}
          />
        )}
        {contentMenu === 6 && (
          <ProjectContent
            creation={false}
            admin={isAdmin}
            childrenCheck={changeMenu}
            user={user}
            project={lastProject}
            country={countryList}
            updateProject={update}
            projectRefresh={refreshProjectList}
          />
        )}

        {contentMenu === 7 && (
          <AddProject
            creation={false}
            admin={isAdmin}
            childrenCheck={changeMenu}
            user={user}
            project={lastProject}
            country={countryList}
            updateProject={update}
            projectRefresh={refreshProjectList}
          />
        )}
        {contentMenu === 8 && (
          <div
            style={{
              width: "100%",
            }}
          >
            <SiteSegmentView />
          </div>
        )}
        {contentMenu === 9 && <QuestionsView />}

        {/* {{render}} */}

        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModal}
          backdrop="static"
          centered
          className="mx-auto"
          keyboard={false}
          size="sm"
          dialogClassName="tailledeletemodal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className=" text-end p-2 ">
            <span
              className="pe-sm-2 pointer "
              onClick={() => {
                handleDeleteModal();
              }}
            >
              <img
                src="assets/elements/images/cross.png"
                alt=""
                style={{ height: "15px", cursor: "pointer" }}
              />
            </span>
          </div>
          <Modal.Body>
            <div className=" d-flex justify-content-center">
              <img
                src="assets/elements/images/delete_icon2.png"
                alt=""
                width={40}
              />
            </div>
            <div className="text-center h5 mt-3">Save changes?</div>
            <div className="text-center  mt-1">
              Unsaved changes will be lost
            </div>
          </Modal.Body>

          <div
            className="d-flex mt-3"
            style={{
              borderTop: ".5px solid #B3B3B3",
            }}
          >
            <div
              className="text-center p-2 fs-7 validbtn"
              style={{
                borderRight: ".5px solid #B3B3B3",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              Don’t save
            </div>
            <div
              className="p-2 text-center fs-7 validbtn"
              style={{
                width: "50%",
                color: "green",
                cursor: "pointer",
              }}
              onClick={() => {
                // handleDeleteImage();
              }}
            >
              Save
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Admin;
