import "../../dashboard/dashboard.css";
import React, { useEffect, useState } from "react";

import list2 from "../../../assets/images/icon/list2.svg";
import ColorpatrolIcon from "../../../assets/images/icon/coloredPatrolIcon.svg";
import dayjs from "dayjs";
import { Button, DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import DoughnutChart from "../../../components/dashboard/Doughnut";
import HorizontalBarChart from "../../../components/dashboard/HorizontalBarChart";
import VerticalBarChart from "../../../components/dashboard/VerticalBarChart";
import profil from "../../../assets/images/icon/profileColored.svg";
import VIPHeader from "../../../components/vip/header/VIPHeader";
import Loading from "../../../components/LoadingError/Loading";

import { useSelector } from "react-redux";
import {
  useFetchVIPActiveContributors,
  useFetchVIPTopContributors,
  useFetchVIPTotalObservations,
  useFetchVIPTotalPatrolTime,
} from "../../../queries/useFetchVIPDashboard";
import VIPDoughnutChart from "./VIPDoughutChart";
import VIPHorizontalBarChart from "./VIPHorizontalBarChart";
import VIPVerticalBarChart from "./VIPVerticalBarChart";
const VIPDashboard = () => {
  const { t } = useTranslation();

  // date range picker functions
  const { RangePicker } = DatePicker;

  const [openCalendar, setOpenCalendar] = useState(false);
  // Set dates

  const todaysDate = new Date();
  const year = todaysDate.getFullYear();
  const month = String(todaysDate.getMonth() + 1).padStart(2, "0");
  const day = String(todaysDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [dates, setDates] = useState(["2000-01-01", formattedDate]);

  // date range picker functions
  const [form] = Form.useForm();

  const onButton = () => {
    form.resetFields();
    setDates(["2000-01-01", formattedDate]);
    setOpenCalendar(false);
  };

  const onChangeRangeDate = (value, dateString) => {
    if (value) {
      let rd = value.map((items) => {
        let m = dayjs(items).format("YYYY-MM-DD");

        return m;
      });
      setDates(rd);
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    onChangeRangeDate();
  }, [dates]);

  // Query fetchs

  const { topContributor, topContriLoading } = useFetchVIPTopContributors({
    dates,
  });
  const { activeContributor, activeContriLoading } =
    useFetchVIPActiveContributors({ dates });

  const { totalObs, totalObsError, totalObsLoading } =
    useFetchVIPTotalObservations({
      dates,
    });
  const { totalPatrolTime, totalPatrolTimeError, totalPatrolTimeLoading } =
    useFetchVIPTotalPatrolTime({ dates });

  // is user admin

  const userRole = useSelector((state) => state.userRoleOnParticularProject);
  const { role } = userRole;
  const [isUserAdmin, setIsUserAdmin] = useState(null);

  useEffect(() => {
    let userRoleFromStorage = localStorage.getItem("userPrRole");
    if (userRoleFromStorage === "true") {
      setIsUserAdmin(true);
    } else {
      setIsUserAdmin(false);
    }
  }, [role]);

  return (
    <>
      <VIPHeader />
      <div className="dashboard__wrapper">
        <div className="dashboard__container mx-auto">
          <div className="dashboard__top__header">
            <div className="dashboard__datepicker">
              <Form
                form={form}
                // name="advanced_assessment_form"
                className="d-flex date__picker__form "
              >
                <div className="d-flex">
                  <Form.Item>
                    <span
                      className="pe-2 "
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenCalendar(!openCalendar);
                      }}
                    >
                      <img
                        src="assets/elements/images/icon/calander.svg"
                        alt=""
                        className="filtre"
                      />
                    </span>
                  </Form.Item>
                  <Form.Item name="field ">
                    <RangePicker
                      onClick={() => {
                        setOpenCalendar(true);
                      }}
                      placeholder={[
                        `${t("admin.exportData.startDate")}`,
                        `${t("admin.exportData.endDate")}`,
                      ]}
                      allowClear={true}
                      onChange={onChangeRangeDate}
                      clearIcon={null}
                      open={openCalendar}
                    ></RangePicker>
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    onClick={onButton}
                    className={`${
                      dates[0] === "2000-01-01"
                        ? "clear__filters__btn__disabled"
                        : "clear__filters__btn"
                    }`}
                    style={{ marginLeft: ".5rem" }}
                  >
                    {t("clear")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="dashboard__top__obs">
              <div className="unvalid__total">
                {/* {totalObsLoading ? (
                  <div className="mt-1">
                    <Loading />
                  </div>
                ) : (
                  totalObs.totalUnvalidObs
                )} */}
              </div>
              <div className="unvalid__text">
                {/* {t("dashboard.obsPendingValidation")} */}
              </div>
              {/* {isUserAdmin && (
                <button className="unvalid__view__btn btn btn-sm">
                  <Link
                    to={{
                      pathname: "/listview-unvalidate",
                      state: {
                        dashboardDates: dates[0] == "2000-01-01" ? [] : dates,
                      },
                    }}
                    className="linkDeco"
                  >
                    {t("dashboard.viewBtn")}
                  </Link>
                </button>
              )} */}
            </div>
          </div>

          <div className="dashboard__body">
            <div className="stats_obs__container">
              <div className="stats_obs">
                {totalObsLoading ? (
                  <div className="my-2">
                    <Loading />
                  </div>
                ) : (
                  <h1>
                    {Number(totalObs?.totalValidObs) +
                      Number(totalObs?.totalUnvalidObs)}
                  </h1>
                )}

                <div>
                  <Image
                    src={list2}
                    alt=""
                    className="list__dashboard__icon"
                    // width={40}
                  />{" "}
                  <span>Observation</span>
                </div>
              </div>
              <div className="dashboad__doughnut">
                <h1> {t("dashboard.obsByGroup")}</h1>

                <div className="doughnut__container">
                  <VIPDoughnutChart dates={dates} />
                </div>
              </div>
            </div>
            <div className="barcharts__containers">
              <div className="horizontal__barchart">
                <div> {t("dashboard.topSpecies")}</div>
                <VIPHorizontalBarChart dates={dates} />
              </div>
              <div className="vertical__barchart">
                <div> {t("dashboard.obsByMonth")}</div>
                <VIPVerticalBarChart dates={dates} />
              </div>
            </div>
            <div className="dash__col3">
              <div className="stats_obs">
                {activeContriLoading ? (
                  <div className="mt-3">
                    <Loading />
                  </div>
                ) : (
                  <h1>{activeContributor}</h1>
                )}

                <div>
                  <Image
                    src={profil}
                    alt=""
                    className="list__dashboard__icon"
                    // width={40}
                  />{" "}
                  <span> {t("dashboard.activeConributions")}</span>
                </div>
              </div>
              <div className="stats__members">
                <div className="top">
                  <Image
                    src={profil}
                    alt=""
                    className="list__dashboard__icon"
                    // width={40}
                  />
                  <span> {t("dashboard.topContributors")}</span>
                </div>

                <div className="members">
                  <div className="dashboard__member__title ">
                    <h2> {t("dashboard.members")}</h2> <h2># Obs</h2>
                  </div>
                  {topContriLoading && (
                    <div className="mt-3">
                      <Loading />
                    </div>
                  )}
                  {topContributor &&
                    topContributor.map((user, index) => (
                      <div className="single__member" key={index}>
                        <div className="name">{user.name}</div>{" "}
                        <p>{user.obsContributed}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="">
              <div className="stats_obs">
                {totalPatrolTimeLoading ? (
                  <div className="my-2">
                    <Loading />
                  </div>
                ) : (
                  <h1>{totalPatrolTime?.slice(0, -3)}</h1>
                )}
                <div>
                  <Image
                    src={ColorpatrolIcon}
                    alt=""
                    className="list__dashboard__icon"
                    // width={40}
                  />{" "}
                  <span> {t("dashboard.totalPatrol")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VIPDashboard;
