import { Marker } from "react-leaflet";
import { useCustomIconList } from "../../../utils/MapMarkersList";

export default function StartPatrolMarker({ gpsStartCoordX, gpsStartCoordY }) {
  const { PatrolIcons } = useCustomIconList();
  return (
    <div className="markr__container">
      {gpsStartCoordX && (
        <Marker
          icon={PatrolIcons.patrolStart}
          position={[gpsStartCoordX, gpsStartCoordY]}
        ></Marker>
      )}
    </div>
  );
}
