import React, { useState, useEffect } from "react";
import moment from "moment";
import GridTable from "@nadavshaar/react-grid-table";
import "./VIPListView.css";
import carret from "../../../assets/images/pointbas.png";

import { useTranslation } from "react-i18next";
import GetColumnsValidObs from "../../../components/ListViewTable/getColumns";
import Loading2 from "../../../components/LoadingError/Loading2";
import NoObservation from "../../../components/ListViewTable/custom/NoObservation";
import CustomHeader from "../../../components/ListViewTable/custom/TableHeader";
import TableLoader from "../../../components/ListViewTable/custom/TableLoader";
import {
  useVIPNestIdAvailabilityCheck,
  useVIPSegmentAvailabilityCheck,
} from "../../../queries/useFetchVIPOptions";

const VIPListViewTable = ({
  dates,
  obsData,
  handleCallback,
  obsListLoading,
  isRefetching,
}) => {
  // fetch observaion with react query

  // table sort icons

  var head = document.getElementsByClassName("rgt-cell-header-inner");

  // for (var i = 2; i < head.length - 1; i++) {
  for (var i = 1; i < head.length - 1; i++) {
    head[i].style.padding = "0px";
  }

  const [rowData, setRowsData] = useState(obsData);

  // Get updated obs and update  array

  useEffect(() => {
    if (obsData && dates.length !== 0) {
      const filtered = obsData.filter((item) => {
        const createdAt = moment(item.date.date);
        const startOfDateRange = new Date(dates[0]);
        startOfDateRange.setHours(1, 0, 0, 0);
        const endOfDateRange = new Date(dates[1]);
        endOfDateRange.setHours(23, 59, 59, 999);
        return createdAt.isBetween(startOfDateRange, endOfDateRange);
      });

      setRowsData(filtered);
    } else {
      setRowsData(obsData);
    }
    //
  }, [obsData, dates]);

  // get projects ids functions

  const [selectedRows, setSelectedRows] = useState();

  useEffect(() => {
    handleCallback(selectedRows);
  }, [selectedRows, handleCallback]);

  useEffect(() => {
    setRowsData(obsData);
  }, [obsData]);

  // Translation

  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const { isSegAvailable } = useVIPSegmentAvailabilityCheck();
  const { isNestIdAvailable } = useVIPNestIdAvailabilityCheck();

  return (
    <>
      <div className="list__tables">
        {obsListLoading && (
          <div>
            <Loading2 />
          </div>
        )}

        <GridTable
          columns={GetColumnsValidObs({
            setRowsData,
            isSegAvailable,

            isNestIdAvailable,
          })}
          key={`${language}-${isNestIdAvailable}-${isSegAvailable}`}
          // isLoading={loading}
          // selectAllMode={"all"}
          selectedRowsIds={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="sortB" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="sortB" />,
          }}
          components={{
            Header: CustomHeader,
            Loader: TableLoader,
            NoResults: NoObservation,
          }}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          showSearch={false}
          rows={rowData}
        />
      </div>
    </>
  );
};

export default VIPListViewTable;
