import React from "react";
import "./style.css";
import Loading from "../../LoadingError/Loading";
const TableLoader = ({ tableManager }) => {
  return (
    //
    <div>
      <Loading />
    </div>
  );
};

export default TableLoader;
