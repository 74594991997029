import { Marker, Popup } from "react-leaflet";
import { useCustomIconList } from "../../../utils/MapMarkersList";
import { MarkerPopup } from "../MarkerPopup/MarkerPopup";
export default function ModalMarker({
  observation,
  lat,
  long,
  typeObservation,
}) {
  const { CustomIconList } = useCustomIconList();
  return (
    <div className="markr__container">
      {observation !== null ? (
        <Marker
          icon={
            observation.TypeObservation === "Menaces"
              ? CustomIconList.menace
              : observation.TypeObservation === "Threat"
              ? CustomIconList.threats
              : observation.TypeObservation === "Animal"
              ? CustomIconList.animal
              : observation.TypeObservation === "Signs of presence"
              ? CustomIconList.presence
              : observation.TypeObservation === "Flash Observation"
              ? CustomIconList.flash
              : observation.TypeObservation === "Crawl"
              ? CustomIconList.crawl
              : observation.TypeObservation === "Nest"
              ? CustomIconList.nest
              : observation.TypeObservation === "Female Encounter"
              ? CustomIconList.fencounter
              : CustomIconList.others
          }
          position={[observation.coordX, observation.coordY]}
          // iconSize={iconSize}
        ></Marker>
      ) : (
        <Marker
          icon={
            typeObservation
              ? typeObservation === "Menaces"
                ? CustomIconList.menace
                : typeObservation === "Threat"
                ? CustomIconList.threats
                : typeObservation === "Animal"
                ? CustomIconList.animal
                : typeObservation === "Signs of presence"
                ? CustomIconList.presence
                : typeObservation === "Flash Observation"
                ? CustomIconList.flash
                : typeObservation === "Crawl"
                ? CustomIconList.crawl
                : typeObservation === "Nest"
                ? CustomIconList.nest
                : typeObservation === "Female Encounter"
                ? CustomIconList.fencounter
                : CustomIconList.others
              : CustomIconList.others
          }
          position={[lat, long]}
        ></Marker>
      )}
    </div>
  );
}
