export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAIL = "PROJECT_LIST_FAIL";
export const PROJECT_OBSERVATION_REQUEST = "PROJECT_OBSERVATION_REQUEST";
export const PROJECT_OBSERVATION_SUCCESS = "PROJECT_OBSERVATION_SUCCESS";
export const PROJECT_OBSERVATION_FAILD = "PROJECT_OBSERVATION_SUCCESS";

export const PROJECT_OBSERVATION_MAP_REQUEST =
  "PROJECT_OBSERVATION_MAP_REQUEST";
export const PROJECT_OBSERVATION_MAP_SUCCESS =
  "PROJECT_OBSERVATION_MAP_SUCCESS";
export const PROJECT_OBSERVATION_MAP_FAILD = "PROJECT_OBSERVATION_MAP_FAILD";

export const UPDATE_OBSERVATIONS_LIST = "UPDATE_OBSERVATIONS_LIST";

export const CREATE_OBSERVATION_REQUEST = "CREATE_OBSERVATION_REQUEST";
export const CREATE_OBSERVATION_SUCCESS = "CREATE_OBSERVATION_SUCCESS";
export const CREATE_OBSERVATION_FAILD = "CREATE_OBSERVATION_FAILD";

export const UPDATE_OBSERVATION_REQUEST = "UPDATE_OBSERVATION_REQUEST";
export const UPDATE_OBSERVATION_SUCCESS = "UPDATE_OBSERVATION_SUCCESS";
export const UPDATE_OBSERVATION_FAILD = "UPDATE_OBSERVATION_FAILD";

export const DELETE_OBSERVATION_REQUEST = "DELETE_OBSERVATION_REQUEST";
export const DELETE_OBSERVATION_SUCCESS = "DELETE_OBSERVATION_SUCCESS";
export const DELETE_OBSERVATION_FAILD = "DELETE_OBSERVATION_FAILD";

export const DELETE_OBSERVATION_IMAGE_REQUEST =
  "DELETE_OBSERVATION_IMAGE_REQUEST";
export const DELETE_OBSERVATION_IMAGE_SUCCESS =
  "DELETE_OBSERVATION_IMAGE_SUCCESS";
export const DELETE_OBSERVATION_IMAGE_FAILD = "DELETE_OBSERVATION_IMAGE_FAILD";

export const DELETE_SEVERAL_OBSERVATION_REQUEST =
  "DELETE_SEVERAL_OBSERVATION_REQUEST";
export const DELETE_SEVERAL_OBSERVATION_SUCCESS =
  "DELETE_SEVERAL_OBSERVATION_SUCCESS";
export const DELETE_SEVERAL_OBSERVATION_FAILD =
  "DELETE_SEVERAL_OBSERVATION_FAILD";

export const UNVALIDATE_OBSERVATION_LIST_REQUEST =
  "UNVALIDATE_OBSERVATION_LIST_REQUEST";
export const UNVALIDATE_OBSERVATION_LIST_SUCCESS =
  "UNVALIDATE_OBSERVATION_LIST_SUCCESS";
export const UNVALIDATE_OBSERVATION_LIST_FAILD =
  "UNVALIDATE_OBSERVATION_LIST_FAILD";

export const VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST =
  "VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST";
export const VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS =
  "VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS";
export const VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD =
  "VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD";

export const DELETE_LIST_OF_OBSERVATION_REQUEST =
  "DELETE_LIST_OF_OBSERVATION_REQUEST";
export const DELETE_LIST_OF_OBSERVATION_SUCCESS =
  "DELETE_LIST_OF_OBSERVATION_SUCCESS";
export const DELETE_LIST_OF_OBSERVATION_FAILD =
  "DELETE_LIST_OF_OBSERVATION_FAILD";

export const UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST =
  "UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST";
export const UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS =
  "UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS";
export const UPLOAD_OBSERVATION_TO_INATURALIST_FAILD =
  "UPLOAD_OBSERVATION_TO_INATURALIST_FAILD";
export const UPLOAD_OBS_PROGRESS = "UPLOAD_OBS_PROGRESS";

export const DELETE_UNVALIDATE_OBSERVATIONS_REQUEST =
  "DELETE_UNVALIDATE_OBSERVATIONS_REQUEST";
export const DELETE_UNVALIDATE_OBSERVATIONS_SUCCESS =
  "VALIDATE_UNDELETE_OBSERVATIONS_SUCCESS";
export const DELETE_UNVALIDATE_OBSERVATIONS_FAILD =
  "DELETE_UNVALIDATE_OBSERVATIONS_FAILD";

export const PROJECT_OBS_ID_I_STATE = "PROJECT_OBS_ID_I_STATE";
export const PROJECT_OBS_ID_SET = "PROJECT_OBS_ID_SET";

// set project id
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const CHANGE_PROJECT_ID = "CHANGE_PROJECT_ID";

// Set Data
export const SET_DATA = "SET_DATA";

// Set User
export const SET_USER = "SET_USER";

// change marker
export const CHANGE_MARKER = "CHANGE_MARKER";
export const OPEN_POPUP = "OPEN_POPUP";

// success notification

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
export const REFETCH_OBS_LIST = "REFETCH_OBS_LIST";
export const SET_USER_ROLE_ON_PROJECT = "SET_USER_ROLE_ON_PROJECT";

// Update obs Locally
export const UPDATE_OBSERVATION_LOCALY = "UPDATE_OBSERVATION_LOCALY";

// delete obs locally
export const DELETE_OBS_LOCALLY = "DELETE_OBS_LOCALLY";

// validate obs locally
export const ValIDATE_OBS_LOCALLY = "ValIDATE_OBS_LOCALLY";

// Create obs and store locally
export const CREATE_OBS_AND_STORE_LOCALLY = "CREATE_OBS_AND_STORE_LOCALLY";

// Create obs and store locally
export const NEW_OBS_ID = "NEW_OBS_ID";

// get map zoom level
export const MAP_ZOOM_LEVEL = "MAP_ZOOM_LEVEL";

// get obsType for filter

export const OBSERVATIONS_TYPES = "OBSERVATIONS_TYPES";
// dates arrays for filter
export const DATE_RANGE = "DATE_RANGE";

// single observation

export const SINGLE_OBSERVATION = "SINGLE_OBSERVATION";

export const PAN_TRAKER = "PAN_TRAKER";

export const SITE_SEGMENT = "SITE_SEGMENT";

export const EDIT_SITE_SEGMENT = "EDIT_SITE_SEGMENT";

// export const SITE_SEGMENT_COMPONENT_LIST = "SITE_SEGMENT_COMPONENT_LIST";

// Siren international id

export const sirenInterNationalId = 62184;

//
export const ADD_QUESTION_DATA = "ADD_QUESTION_DATA";
export const DELETE_QUESTION = "DELETE_QUESTION";
