import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../actions/userActions";
// Css import
import "./login.css";
// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Loading from "../../components/LoadingError/Loading";
import Message from "../../components/LoadingError/Error";
import { BsArrowLeft, BsArrowLeftShort } from "react-icons/bs";
import { window } from "rxjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Randomly renderin login images
const loginImagesPath = [
  `url(assets/elements/images/turtleCompressed.png)`,
  `url(assets/elements/images/SharkimageCompressed.png)`,
  `url(assets/elements/images/DolphinCompressed.png)`,
];
// mobile images imports
const loginImagesPathMoble = [
  `url(assets/elements/images/Shark-image-small-2.png)`,
  `url(assets/elements/images/Turtle-image-small.png)`,
  `url(assets/elements/images/Dolphin-image-small-3.png)`,
];

let imagePathId = Math.floor(Math.random() * 3);
const mystyle = {
  background: loginImagesPath[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};
const mystyleMobile = {
  background: loginImagesPathMoble[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: "cover",

  backgroundRepeat: "no-repeat",
};

const ResetPassword = () => {
  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = yup.object().shape({
    code: yup.string().required(),
    newPassword: yup.string().min(8).required("New password required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], " Passwords don't match"),
  });

  const {
    register,

    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { userInfo, error, loading } = userResetPassword;
  // const history = useHistory();
  // useEffect(() => {
  //   if (userInfo) {
  //     setTimeout(() => {
  //       history.push("/");
  //     }, 5000);
  //   }
  // }, [userInfo, history]);

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    dispatch(resetPassword(data.code, data.newPassword));
  };

  //   Password visibility (hide/show)
  const [show, setShow] = useState("false");
  const [showConfirm, setShowConfirm] = useState("false");
  const handleShowConfirmPass = () => {
    setShowConfirm(!showConfirm);
  };

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      {/* {userInfo && (
        <SuccessAlert message={"Password reset. redirecting to Login"} />
      )} */}
      <div
        className="container-fluid p-0 login-page"
        style={{ overflow: "hidden" }}
      >
        <div className="row header bg-cl-bl d-md-none d-lg-none d-sm-none ">
          <div className="col-xs-12 text-center">
            <img
              src="assets/elements/images/logo/logo.png"
              alt=""
              className="logo"
            />
          </div>
        </div>
        <div
          className="row header-img bg-cl-bl d-md-none d-lg-none d-sm-none "
          style={mystyleMobile}
        ></div>

        <div
          className={`row ${1} ,row-cols-2,row-cols-3, auto,justify-content-md-center vh-100`}
        >
          <div className="col-md-6 col-sm-9 p-4">
            <div className="row d-none d-sm-block">
              <Link to={"/"}>
                <img
                  src="assets/elements/images/logo/logos.png"
                  alt=""
                  className="logos"
                />
              </Link>
            </div>
            <div
              className="row  position-relative"
              style={{ marginTop: "2.5rem" }}
            >
              {!userInfo && (
                <div
                  className="col-md-8 offset-md-2 align-self-center"
                  id="block-reset"
                >
                  {error && <Message variant="alert-danger">{error}</Message>}

                  <div className="loadingContainer">
                    {loading && <Loading />}
                  </div>

                  <div className="mb-3">
                    <div className="mb-2 h3 f-bold">Reset Password</div>
                    <div className="cl-gr">
                      Check your mails and enter the code received.{" "}
                    </div>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-4 mb-1 position-relative">
                        <label className="mb-1">Enter code</label>
                        <br />
                        <input
                          type="text"
                          name="ConfirmationCode"
                          placeholder="Enter 6 digit code"
                          {...register("code")}
                          className="w-100 emailtxt form-control"
                        />
                        <p className="error">{errors.code?.message}</p>

                        <div
                          className=" position-relative"
                          style={{ height: "80px" }}
                        >
                          <label>New Password</label>
                          <br />
                          <input
                            type={show ? "password" : "text"}
                            name="newPassword"
                            {...register("newPassword")}
                            placeholder="Enter your new password"
                            // className="w-100 rounded password"
                            className={`w-100 rounded password form-control  ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <p className="error">{errors.newPassword?.message}</p>
                          <label onClick={handleShow}>
                            {show ? (
                              <img
                                src="assets/elements/images/icon/eyesc.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            ) : (
                              <img
                                src="assets/elements/images/icon/eyes.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            )}
                          </label>
                        </div>
                        <div
                          className=" position-relative"
                          style={{ height: "80px" }}
                        >
                          <label> Confirm new password</label>
                          <br />
                          <input
                            type={showConfirm ? "password" : "text"}
                            name="confirmPassword"
                            placeholder="Re enter your new password"
                            {...register("confirmPassword")}
                            // className="w-100 rounded password"
                            className={`w-100 rounded password form-control  ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <p className="error">
                            {errors.confirmPassword?.message}
                          </p>
                          <label onClick={handleShowConfirmPass}>
                            {showConfirm ? (
                              <img
                                src="assets/elements/images/icon/eyesc.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            ) : (
                              <img
                                src="assets/elements/images/icon/eyes.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            )}
                          </label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={!isDirty || !isValid}
                        className=" btn-lg btn-block w-100 mt-4 login-btn"
                      >
                        Submit
                      </button>
                      {/* <div className="text-center mt-3">
                    <Link className="lien noDeco" to={"/login"}>
                      {" "}
                      <img
                        src="assets/elements/images/icon/arrow-left.svg"
                        alt=""
                        className="d-inline-block"
                      />{" "}
                      <span className="ps-2">Go to login</span>
                    </Link>
                  </div> */}
                    </form>
                  </div>
                </div>
              )}
              {userInfo && (
                <div
                  className="col-md-8 offset-md-2  align-self-center"
                  id="block-mail"
                >
                  {/* <div className="mb-3">
                    <div className="mb-3 h3 f-bold">Check your email</div>
                    <div className="cl-gr">
                      Password reset successfull. Will be redirected to login
                      page in some seconds... <br />
                      if not redirected,{" "}
                      <NavLink to={"/login"}>click here </NavLink>
                    </div>
                  </div> */}
                  <div className="mb-3">
                    <div className="mb-6 mt-3 pt-5 pb-5 h3 f-bold">
                      Password successfully reset
                    </div>
                    <div className="cl-gr pt-">
                      Your password has been successfully updated. You will be
                      automatically redirected to the login page within 5
                      seconds or click below.
                    </div>
                  </div>
                  {/* <div className="position-relative mt-5">
                    <Link className="lien noDeco" to={"/"}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src="assets/elements/images/icon/arrow-left.svg"
                            alt=""
                            className=""
                            width={"20px"}
                          />
                        </div>
                        <div>
                          <span className="ps-2 f-bold fs-5">
                            
                            Back to login
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                  <div
                    className="position-relative mt-5"
                    style={{
                      color: "#096DBB",
                    }}
                  >
                    <Link className=" noDeco" to={"/login"}>
                      {/* <img
                          src="assets/elements/images/icon/arrow-left.svg"
                          alt=""
                          className="d-inline-block"
                        />{" "} */}
                      <BsArrowLeftShort
                        className="d-inline-block"
                        style={{
                          fontSize: "32px",
                          paddingBottom: "5px",
                        }}
                      />
                      <span
                        // className="ps-1"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Back to login
                      </span>{" "}
                    </Link>
                  </div>
                </div>
              )}
              {/* <div
                className="position-absolute d-none"
                id="block-try"
                style={{ bottom: "0px" }}
              >
                <div className="text-center mt-3">
                  Did not receive the email? Check your spam filter, <br />
                  or{" "}
                  <a className="lien" href="#">
                    {" "}
                    try another email address
                  </a>
                </div>
              </div> */}
            </div>
            <div className="h-70"></div>
          </div>
          <div
            className="col-md-6 col-sm-3 d-none d-md-block"
            //   style={{ background: `url(assets/elements/images/turtle.jpg)` }}
            style={mystyle}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
