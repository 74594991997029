import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import "../style.css";
const ConfirmExit = ({
  confirmExit,
  handleExitEdit,
  handleCreateObs,
  handleConfirmExit,
}) => {
  const { t } = useTranslation();
  return (
    <div className="confrim__modal">
      <Modal
        show={confirmExit}
        //  onHide={handleHideConfirmationModal}
        backdrop="static"
        centered
        className="mx-auto confirmModal"
        keyboard={false}
        size="sm"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span className="pointer " onClick={handleConfirmExit}>
            <RxCross2 className="close__icon" />
          </span>
        </div>

        <Modal.Body>
          <div className=" d-flex justify-content-center">
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          </div>
          <div className="text-center h5 mt-3" style={{ color: " #404040" }}>
            {t("saveChanges")} ?
          </div>
          <div className="text-center mb-3" style={{ color: " #08253B" }}>
            {t("lostUnsaved")}
          </div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".5px solid #CCCCCC",
          }}
        >
          <div
            className="text-center p-2 fs-7 validbtn"
            style={{
              borderRight: ".5px solid #CCCCCC",
              width: "50%",
              cursor: "pointer",
              color: "#404040",
            }}
            onClick={() => {
              handleExitEdit();
            }}
          >
            {t("dontSave")}
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            // type="submit"
            style={{
              width: "50%",
              color: `red`,
              cursor: "pointer",
            }}
            onClick={() => {
              handleCreateObs();
            }}
          >
            {t("saveChanges")}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmExit;
