import "./styles.css";
import TitleComponent from "../../TitleComponent";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ourPartnersImages from "../Data/ourPartnersImages";
import { useTranslation } from "react-i18next";

function OurPartners() {
  const { t } = useTranslation();
  const imagesToCut = [
    "./assets/tubeAwu.png",
    "./assets/DRAM.png",
    "./assets/Agire.jpeg",
  ];
  return (
    <section id="ourPartners">
      <TitleComponent>{t("landingPage.content.ourPartners")}</TitleComponent>
      <Container fluid className="ourPartners">
        {/* <h4 className="ourPartnersHeader">
          Siren is proud to have been the recipient of grants from National
          Geographic in 2021 and 2023.{" "}
        </h4> */}

        <div className="ourPartnersLogoWrapper">
          <div className="ourPartnersLogoSlide">
            {ourPartnersImages.map((image, index) => (
              <Image
                src={image}
                key={index}
                alt="partners"
                className={`img-fluid ourPartnersImages ${
                  imagesToCut.includes(image) && "ourPartnersCutImage"
                }`}
              />
            ))}
          </div>
          <div className="ourPartnersLogoSlide">
            {ourPartnersImages.map((image, index) => (
              <Image
                src={image}
                key={index}
                alt="partners"
                className={`img-fluid ourPartnersImages ${
                  imagesToCut.includes(image) && "ourPartnersCutImage"
                }`}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

export default OurPartners;
