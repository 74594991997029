//check if user is authenticated
export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo"));
  } else {
    return false;
  }
};
let val = false;
export const isAdmin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (val) {
    return false;
  } else {
    return false;
  }
};
