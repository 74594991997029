import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import position from "../../../../../assets/images/position.svg";
import {
  handleSubmitCoords,
  LatRangeCheck,
  LongRangeCheck,
} from "../../../../../lib/createObs/createObsFuncs";
import useCreateValidation from "../../../../../lib/createObs/useCreateValidation";
import { useCreateObservationStore } from "../../../../../lib/createObs/useCreateObservationData";
const CoordinatesInputs = ({ cordRef, setChangeTracker }) => {
  const { t } = useTranslation();
  const {
    newLocalLat,
    newLocalLong,
    newLat,
    newLong,
    setNewLat,
    setNewLong,
    setNewLocalLat,
    setNewLocalLong,
  } = useCreateObservationStore();

  const { localLatValidation } = useCreateValidation();

  const [coordsPosition, setCoordsPosition] = useState();
  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  const [coordsErrorMessage, setCoordsErrorMessage] = useState("");
  const [showCoordsBox, setShowCoordsBox] = useState(false);

  const [isCoordsSet, setIsCoordsSet] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    const lt = LatRangeCheck(newLat);
    const lg = LongRangeCheck(newLong);
    if (lt && lg) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [newLat, newLong]);

  useEffect(() => {
    if (cordRef) {
      setCoordsPosition(cordRef.current.offsetHeight);
      // setCoordsWidth(cordRef.current.offsetWidth);
    }
  }, []);
  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title coords_mobile">
        {t("coords")} <sup>*</sup>
      </div>
      <div
        className="col-7 modal__title__value "
        style={{
          position: "relative",
        }}
      >
        {/* new coords system */}
        <div className="coords__wrapper__content">
          <div
            className="coords__wrapper "
            ref={cordRef}
            onClick={() => {
              setShowCoordsBox(true);
            }}
          >
            <div className="coords__container">
              <div className="coords__eclipe__text">
                {newLocalLat && newLocalLat}
              </div>
              {newLocalLat ? <span>,</span> : ""}
              <div className="coords__eclipe__text">
                {newLocalLong && newLocalLong}
              </div>
            </div>

            <div className="coords__marker">
              <img
                src={position}
                alt=""
                // width={11}
                className="mt-1 me-1 "
              />
            </div>
          </div>
          {showCoordsBox && (
            <div
              className="coords__popup__wrapper"
              style={{
                // width: `${coordsWidth - 50}px`,
                top: `${coordsPosition + 5}px`,
              }}
            >
              {isInvalidCoords && (
                <span
                  className="error"
                  style={{
                    position: "absolute",
                    left: "10px",
                  }}
                >
                  {coordsErrorMessage}
                </span>
              )}
              <div
                className="coords__inputs"
                style={
                  {
                    // width: `${coordsWidth - 50}px`,
                  }
                }
              >
                <div className="coords__inputs__container">
                  <div>
                    <label>Latitude</label>
                    <input
                      value={newLat && newLat}
                      placeholder="XX.XXXXXX"
                      type="number"
                      style={{ fontSize: ".6rem" }}
                      onChange={(e) => {
                        setNewLat(e.target.value);
                      }}
                      step="any"
                    />
                  </div>
                  <div className="ms-2">
                    <label>Longitude</label>
                    <input
                      style={{ fontSize: ".6rem" }}
                      placeholder="XX.XXXXXX"
                      value={newLong && newLong}
                      type="number"
                      step="any"
                      onChange={(e) => {
                        setNewLong(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="coords__btn__coords">
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setShowCoordsBox(false);
                        setIsInvalidCoords(false);
                        setIsCoordsSet(true);
                      }}
                    >
                      {t("cancelBtn")}
                    </button>
                  </div>
                  <div>
                    <button
                      className="save"
                      type="button"
                      onClick={() => {
                        handleSubmitCoords(
                          newLong,
                          newLat,
                          setNewLocalLat,
                          setNewLocalLong,
                          setShowCoordsBox,
                          setIsCoordsSet,
                          setChangeTracker,
                          setIsInvalidCoords,
                          setCoordsErrorMessage
                        );
                      }}
                      disabled={disableBtn || (newLat && newLong) === ""}
                    >
                      {t("saveBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {localLatValidation && <p className="error">{t("required")}</p>}
      </div>
    </div>
  );
};

export default CoordinatesInputs;
