import React, { useEffect, useMemo, useState } from "react";
import "./filter.css";
import { useSelector } from "react-redux";
import { uncheckAll$ } from "../../services/rxjs";

const Filter = ({
  title,
  pdg,
  nivo,
  checker,
  child,
  childrenCheck,
  getContent,
  pid,
  contentParent,
  handleCallback,
}) => {
  const [check, setCheck] = useState(checker);
  const [childSelected, setchildSelected] = useState(0);
  const [allSelect, setAllSelect] = useState(0);
  const [checktext, setChecktext] = useState(false);
  const style = {
    paddingLeft: pdg,
  };
  const nextPadding = pdg + 20;
  const nivoSuivant = nivo + 1;
  const classNamees = "btn btn-toggle niv nivo" + nivo + " " + checktext;
  const classNameeSimple = "btn niv nivo" + nivo + " " + checktext;
  const checkEmpty = "checker empty";
  const checkPartial = "checker check-toggle";
  const checkChecked = "checker check-checked";
  const checkCheckEmpty = "checker check-empty";

  const uncheck = () => {
    setCheck(false);
    setChecktext(false);
  };

  useEffect(() => {
    uncheckAll$.subscribe(() => uncheck());
  }, []);

  const checkOncheck = (e) => {
    e.preventDefault();
    if (!child) {
      if (childrenCheck) childrenCheck(!check);
      setCheck(!check);
      setChecktext(!checktext);
    }
  };

  const choiseSelect = (select) => {
    switch (select) {
      case 0:
        return <div className={checkEmpty}></div>;
      case 1:
        return <div className={checkPartial}></div>;
      case 2:
        return <div className={checkChecked}></div>;
      default:
        return <div className={checkEmpty}></div>;
    }
  };

  // const changeSeclect = (check) => {
  //   var content = 0;
  //   if (check) {
  //     content = childSelected + 1;
  //     setchildSelected(content);
  //   } else {
  //     if (childSelected > 0) {
  //       content = childSelected - 1;
  //       setchildSelected(content);
  //     }
  //   }

  //   if (content === 0) setAllSelect(0);
  //   else {
  //     if (content < child.length) {
  //       setChecktext(false);
  //       if (allSelect === 2) if (contentParent) childrenCheck(false);
  //       setAllSelect(1);
  //     } else {
  //       setChecktext(true);
  //       if (contentParent) childrenCheck(true);
  //       setAllSelect(2);
  //     }
  //   }
  // };

  const changeSeclect = (check, index) => {
    var content = 0;

    if (check) {
      content = childSelected + 1;
      setchildSelected(content);
    } else {
      if (childSelected > 0) {
        content = childSelected - 1;
        setchildSelected(content);

        if (child && child.length > 0) {
          // getContent(childContent, childIndex); // call callback function
        }
      }
    }
  };
  const getIndex = (i) => {
    getContent(i);
  };

  return (
    <li className="nav-item" onClick={checkOncheck}>
      <a
        href="#"
        className={child ? classNamees : classNameeSimple}
        style={style}
        data-bs-toggle="collapse"
        data-bs-target={"#c" + pid + nivoSuivant + "-collapse"}
        data-name={"c" + pid + nivoSuivant + "-collapse"}
        aria-expanded="false"
      >
        <span className="tleft">
          {" "}
          {title}{" "}
          {child ? <span className="tognbr">({child.length})</span> : null}{" "}
        </span>
      </a>
      {child ? (
        <div className="collapse" id={"c" + pid + nivoSuivant + "-collapse"}>
          <ul className="nav nav-pills flex-column mb-auto">
            {child.map((item, index) =>
              item.children.length > 0 ? (
                <Filter
                  key={index}
                  title={child[index].title}
                  pdg={nextPadding}
                  nivo={nivoSuivant}
                  childrenCheck={changeSeclect}
                  pid={child[index].id}
                  child={child[index].children}
                  contentParent={true}
                />
              ) : (
                <Filter
                  title={item.title}
                  pdg={nextPadding}
                  checker={false}
                  nivo={nivoSuivant}
                  childrenCheck={() => {
                    changeSeclect();
                    getIndex(index);
                  }}
                  pid={item.id}
                  contentParent={false}
                />
              )
            )}
          </ul>
        </div>
      ) : null}

      {!child ? (
        <div className={check ? checkChecked : checkCheckEmpty}></div>
      ) : (
        choiseSelect(allSelect)
      )}
    </li>
  );
};

export default Filter;
