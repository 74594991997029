import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function LatestNewsCard({
  img,
  text,
  title,
  caseStudyLink,
  externalLink,
}) {
  const { t } = useTranslation();

  return (
    <Card className={`latestNewCard`}>
      <Container fluid className="p-0 latestNewsCard-imgWrapper">
        <Card.Img className="latestNewsCard-img" variant="top" src={img} />
      </Container>
      <Card.Body className="latestNewCard-body">
        <div>
          <Card.Title className="latestNewCard-title">
            <h4 className="fw-semibold lh-1 ms-sm-0">{title}</h4>
          </Card.Title>
          <Card.Text className="latestNewCard-bodyText ms-sm-0 p-0">
            {text}
          </Card.Text>
        </div>
        {caseStudyLink && (
          <div>
            {!externalLink ? (
              <Link
                className="latestNewCard-more"
                target="_blank"
                to={caseStudyLink}
              >
                {t("landingPage.content.readMore")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  className="latestNewCardBtnRotate"
                >
                  <path
                    d="M12 20a.997.997 0 0 1-.707-.293l-6-6a.999.999 0 1 1 1.414-1.414L12 17.586l5.293-5.293a.999.999 0 1 1 1.414 1.414l-6 6A.997.997 0 0 1 12 20z"
                    fill="#08253b"
                  />
                </svg>
              </Link>
            ) : (
              <a
                className="latestNewCard-more"
                href={caseStudyLink}
                target="_blank"
                rel="noreferrer"
              >
                {t("landingPage.content.readMore")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  className="latestNewCardBtnRotate"
                >
                  <path
                    d="M12 20a.997.997 0 0 1-.707-.293l-6-6a.999.999 0 1 1 1.414-1.414L12 17.586l5.293-5.293a.999.999 0 1 1 1.414 1.414l-6 6A.997.997 0 0 1 12 20z"
                    fill="#08253b"
                  />
                </svg>
              </a>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
