import create from "zustand";

const useCreateValidation = create((set) => ({
  localLatValidation: false,
  localObsTypeValidation: false,
  localSpeciesState: false,
  localGroupValidation: false,
  localSiteValidation: false,
  localFamilyValidation: false,
  localNestIDValidation: false,
  localNestCollectorValidation: false,
  localNestHighTideDistValidation: false,
  localNestStatusValidation: false,
  localNestHealthValidation: false,
  localNestRelocationValidation: false,
  localNestFemalePresence: false,
  localSuspectedNestCreationDateValidation: false,
  localNestEstHatchDateValidation: false,
  localNestEstExhumDateValidation: false,
  localFemEncTagLeftValidation: false,
  localFemEncTagRightValidation: false,
  localFemEncLengthValidation: false,
  localFemEncWidthValidation: false,
  localCrawlWidthValidation: false,
  localNotesValidation: false,
  localNestRelocateSiteValidation: false,
  localReasonForLostValidation: false,

  setLocalLatValidation: (value) => set({ localLatValidation: value }),
  setLocalObsTypeValidation: (value) => set({ localObsTypeValidation: value }),
  setLocalSpeciesState: (value) => set({ localSpeciesState: value }),
  setLocalGroupValidation: (value) => set({ localGroupValidation: value }),
  setLocalSiteValidation: (value) => set({ localSiteValidation: value }),
  setLocalFamilyValidation: (value) => set({ localFamilyValidation: value }),
  setLocalNestIDValidation: (value) => set({ localNestIDValidation: value }),
  setLocalNestCollectorValidation: (value) =>
    set({ localNestCollectorValidation: value }),
  setLocalNestHighTideDistValidation: (value) =>
    set({ localNestHighTideDistValidation: value }),
  setLocalNestStatusValidation: (value) =>
    set({ localNestStatusValidation: value }),
  setLocalNestHealthValidation: (value) =>
    set({ localNestHealthValidation: value }),
  setLocalNestRelocationValidation: (value) =>
    set({ localNestRelocationValidation: value }),
  setLocalNestFemalePresenceValidation: (value) =>
    set({ localNestFemalePresence: value }),
  setLocalSuspectedNestCreationDateValidation: (value) =>
    set({ localSuspectedNestCreationDateValidation: value }),
  setLocalNestEstHatchDateValidation: (value) =>
    set({ localNestEstHatchDateValidation: value }),
  setLocalNestEstExhumDateValidation: (value) =>
    set({ localNestEstExhumDateValidation: value }),
  setLocalFemEncTagLeftValidation: (value) =>
    set({ localFemEncTagLeftValidation: value }),
  setLocalFemEncTagRightValidation: (value) =>
    set({ localFemEncTagRightValidation: value }),
  setLocalFemEncLengthValidation: (value) =>
    set({ localFemEncLengthValidation: value }),
  setLocalFemEncWidthValidation: (value) =>
    set({ localFemEncWidthValidation: value }),
  setLocalCrawlWidthValidation: (value) =>
    set({ localCrawlWidthValidation: value }),
  setLocalNotesValidation: (value) => set({ localNotesValidation: value }),
  setLocalNestRelocateSiteValidation: (value) =>
    set({ localNestRelocateSiteValidation: value }),
  setLocalReasonForLostValidation: (value) =>
    set({ localReasonForLostValidation: value }),

  resetAllValidations: () =>
    set({
      localLatValidation: false,
      localObsTypeValidation: false,
      localSpeciesState: false,
      localGroupValidation: false,
      localSiteValidation: false,
      localFamilyValidation: false,
      localNestIDValidation: false,
      localNestCollectorValidation: false,
      localNestHighTideDistValidation: false,
      localNestStatusValidation: false,
      localNestHealthValidation: false,
      localNestRelocationValidation: false,
      localNestFemalePresence: false,
      localSuspectedNestCreationDateValidation: false,
      localNestEstHatchDateValidation: false,
      localNestEstExhumDateValidation: false,
      localFemEncTagLeftValidation: false,
      localFemEncTagRightValidation: false,
      localFemEncLengthValidation: false,
      localFemEncWidthValidation: false,
      localCrawlWidthValidation: false,
      localNotesValidation: false,
      localNestRelocateSiteValidation: false,
      localReasonForLostValidation: false,
    }),
}));

export default useCreateValidation;
