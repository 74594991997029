import React, { useEffect, useMemo, useState } from "react";
import { BiChevronDown, BiErrorCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../modals/ConfirmationModal";
import { editSiteSegment } from "../../actions/projectActions";
import axios from "axios";
import { API_URL as API } from "../../services/API";

import { useTranslation } from "react-i18next";

const EditSiteSegment = ({
  siteIndex,
  componentIndex,
  handleUpdate,
  handleGetLoading,
  arrayList,
  siteSeg,
  user,
  sendSiteIndexToParent,
  handleShowSegDeleteNotif,
  handleShowSiteDeleteNotif,
  handleGetValidateState,
  refetchSiteSegment,
}) => {
  const { t } = useTranslation();

  const [singleSiteSegmentData, setSingleSiteSegmentData] = useState({
    site: "",
    segment: [],
    index: siteIndex,
    siteId: -1,
  });

  const [showAddSegmentFLield, setShowAddSegmentFLield] = useState(false);
  const [singleSegment, setSingleSegment] = useState({ name: "", id: -1 });
  const [checkEditSegment, setCheckEditSegment] = useState(false);
  const [singleSegmentId, setSingleSegmentId] = useState(null);
  const [segmentIndex, setSegmentIndex] = useState(null);
  const [singleSite, setSingleSite] = useState(siteSeg.site ?? "");
  const [segmentList, setSegmentList] = useState(siteSeg.segment ?? []);

  const [toggleSegmentDelete, setToggleSegmentDelete] = useState(false);
  const [toggleSiteDelete, setToggleSiteDelete] = useState(false);

  const [segmentDeleteMessage, setSegmentDeleteMessage] = useState([]);

  const [segmentDeleteMessageDetails, setSegmentDeleteMessageDetails] =
    useState("");
  const dispatch = useDispatch();

  useMemo(() => {
    setSingleSite(siteSeg.site);
    setSegmentList(siteSeg.segment);
    setSingleSiteSegmentData(siteSeg);
  }, [siteSeg]);

  const handleAddSingleSegmentToList = (event) => {
    event.preventDefault();
    if (singleSegment.name && singleSegment.name.trim() !== "") {
      if (checkEditSegment) {
        setSegmentList((prevList) =>
          prevList.map((item, index) => {
            if (index === singleSegmentId) {
              setSingleSiteSegmentData((prevState) => ({
                ...prevState,
                segment: prevState.segment.map((d, i) => {
                  if (i === index) {
                    return singleSegment;
                  } else {
                    return d;
                  }
                }),
              }));
              return singleSegment;
            } else {
              return item;
            }
          })
        );
        setSingleSegment({ name: "", id: -1 });
        setCheckEditSegment(false);
      } else {
        setSegmentList((prev) => {
          if (prev) {
            return [...prev, singleSegment];
          } else {
            return [singleSegment];
          }
        });
        setSingleSiteSegmentData((prevState) => ({
          ...prevState,
          segment: prevState.segment
            ? [...prevState.segment, singleSegment]
            : [singleSegment],
        }));
        setSingleSegment({ name: "", id: -1 });
      }
    }

    setSingleSegmentId(null);
  };
  useEffect(() => {
    setSingleSiteSegmentData((prev) => ({
      ...prev,
      segment: segmentList,
    }));
  }, [segmentList]);

  useMemo(() => {
    if (singleSiteSegmentData.site !== "") {
      dispatch(
        editSiteSegment({ editSiteSegmentObject: singleSiteSegmentData })
      );
    }
  }, [singleSiteSegmentData, dispatch]);

  const [segId, setSegId] = useState(null);
  // Delete singleSegment
  const handleDeleteSingleSegment = async () => {
    setToggleSegmentDelete(false);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };
    if (segId !== -1) {
      handleGetLoading(true);
      try {
        const response = await axios.delete(
          API + `api/segment/delete?segmentId=${segId}`,
          config
        );

        handleGetLoading(false);
        const newSegments = segmentList.filter(
          (item, index) => index !== segmentIndex
        );
        setSegmentList(newSegments);
        handleGetLoading(false);
        setSingleSiteSegmentData((prevState) => ({
          ...prevState,
          segment: newSegments,
        }));
        handleShowSegDeleteNotif();
        return response;
      } catch (error) {
        handleGetLoading(false);
        console.log(error, "delete segment error ");
      }
    } else {
      handleGetLoading(false);
      const newSegments = segmentList.filter(
        (item, index) => index !== segmentIndex
      );
      setSegmentList(newSegments);
      setSingleSiteSegmentData((prevState) => ({
        ...prevState,
        segment: newSegments,
      }));
    }
  };

  const closeSegmentDeleteModal = () => {
    setToggleSegmentDelete(false);
    setSegmentIndex(null);
  };
  const closeSiteDeleteModal = () => {
    setToggleSiteDelete(false);
  };

  const handleShowAddSegmentBox = () => {
    if (singleSite && singleSite.trim() !== "") {
      setShowAddSegmentFLield((prev) => !prev);
    }
  };

  const handleDeleteSiteAndSegment = async () => {
    setToggleSiteDelete(false);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    const siteId = arrayList[siteIndex].siteId;

    if (siteId !== -1) {
      handleGetLoading(true);

      try {
        const response = await axios.post(
          API + `api/siteSegment/delete`,
          {
            siteId: siteId,
          },
          config
        );
        console.log(response, "response");
        sendSiteIndexToParent(siteIndex);

        setSingleSiteSegmentData({
          site: "",
          segment: [],
        });
        refetchSiteSegment();

        setSegmentList([]);
        setSingleSite("");
        setSingleSegment({ name: "", id: -1 });
        handleShowSiteDeleteNotif(componentIndex);
        handleGetLoading(false);
      } catch (error) {
        handleGetLoading(false);
        console.log(error, "site delete error ");
      }
    } else {
      handleGetLoading(false);
      sendSiteIndexToParent(siteIndex);
      setSingleSiteSegmentData({
        site: "",
        segment: [],
      });
      setSegmentList([]);
      setSingleSite("");
      setSingleSegment({ name: "", id: -1 });
    }
    setShowAddSegmentFLield(false);
    setToggleSiteDelete(false);
  };

  const handleUpdateSiteSegObj = (value, ide, id, index) => {
    if (ide === "site") {
      const idSite = siteSeg.siteId ? siteSeg.siteId : -1;
      setSingleSiteSegmentData((prevState) => ({
        ...prevState,
        site: value,
        siteId: idSite,
        segment: segmentList,
        index: siteIndex,
      }));
    }
  };

  return (
    <>
      {/* {<Loading2 />} */}
      <div className="row pb-3">
        <div className="col-md-4 offset-md-1 newpromt mt-3 pt-1">
          <div className="mt-1 mb-2  position-relative">
            <label className="site_lable">Site</label>
            <br />
            <div className="site__field_wrapper">
              <input
                type="text"
                name="siteName"
                id="siteName"
                value={siteIndex === arrayList.length - 1 ? "" : singleSite}
                onChange={(e) => {
                  setSingleSite(e.target.value);

                  handleUpdateSiteSegObj(e.target.value, "site");
                  handleGetValidateState(true);
                }}
                className={` site__input`}
                placeholder={`${t("siteSeg.sitePlaceholder")}`}
                onClick={() => {
                  setSingleSite(null);

                  if (arrayList.length - 1 === siteIndex) {
                    handleUpdate(siteIndex);
                    setSingleSiteSegmentData({
                      site: "",
                      segment: [],
                      index: siteIndex,
                      siteId: -1,
                    });
                  }
                }}
              />
              <div>
                <CgClose
                  className="site_feild_icon"
                  onClick={() => {
                    if (singleSite && singleSite.trim() !== "") {
                      setToggleSiteDelete(true);
                      dispatch(
                        editSiteSegment({ editSiteSegmentObject: null })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5  newpromt mt-0 pt-2">
          <div className="mt-3 mb-2  position-relative">
            <label className="site_lable">Segments</label>
            <br />

            <div
              className={`w-100 segment_feild`}
              style={{
                borderBottom: `${
                  showAddSegmentFLield
                    ? "0.5px solid #073b60"
                    : "0.5px solid #cccccc"
                }`,
              }}
            >
              {!segmentList || (segmentList && segmentList.length === 0) ? (
                <div
                  className="ps-2 w-100 "
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleShowAddSegmentBox();
                  }}
                >
                  + {t("siteSeg.segBtnplaceholder")}
                </div>
              ) : (
                <div className="add_segments__container">
                  {segmentList &&
                    segmentList.map((seg, i) => (
                      <div
                        key={i}
                        className={` ${
                          singleSegmentId === i
                            ? "selectedSegment"
                            : "added__segment"
                        }`}
                      >
                        {/* Check condition is segdata exist and instead update array */}
                        <div
                          onClick={() => {
                            setSingleSegment(segmentList[i]);
                            setCheckEditSegment(true);
                            setSingleSegmentId(i);
                            setShowAddSegmentFLield(true);
                          }}
                        >
                          {seg.name}
                        </div>{" "}
                        <CgClose
                          className="segment_close_icon ms-2 "
                          style={{
                            paddingTop: "2px",
                          }}
                          onClick={() => {
                            setSegmentIndex(i);
                            setSegmentDeleteMessage(
                              `${t("siteSeg.removeSegTitle")} ${
                                segmentList[i].name
                              }`
                            );
                            setSegmentDeleteMessageDetails(
                              ` Segment ${segmentList[i].name} ${t(
                                "siteSeg.removeSegDetails"
                              )}`
                            );
                            setToggleSegmentDelete(true);
                            setSegId(segmentList[i]?.id);
                          }}
                        />
                      </div>
                    ))}
                </div>
              )}

              <div>
                <BiChevronDown
                  className={`segment_chevron ${
                    showAddSegmentFLield
                      ? "segment_feild_icon_rotate"
                      : "segment_feild_icon"
                  }`}
                  onClick={() => {
                    handleShowAddSegmentBox();
                  }}
                />
              </div>
            </div>
          </div>
          {showAddSegmentFLield && (
            <div className="segment__addBox">
              <div
                className="pb-1 "
                style={{
                  marginLeft: "5px",
                  color: "#595959",
                }}
              >
                {t("siteSeg.segTitle")}
              </div>

              <div className="segment__field_wrapper">
                <input
                  type="text"
                  name="segmentName"
                  id="segmentName"
                  value={singleSegment.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    const id = singleSegment.id || -1;
                    setSingleSegment({ id, name: value });
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleAddSingleSegmentToList(event);

                      handleGetValidateState(true);
                    }
                  }}
                  className={`${
                    checkEditSegment ? "segment__input_edit" : "segment__input"
                  }`}
                  placeholder={`${t("siteSeg.segPlaceholder")}`}
                />
                <div
                  className="addSegment__btn"
                  onClick={(e) => {
                    handleAddSingleSegmentToList(e);

                    handleGetValidateState(true);
                  }}
                >
                  <MdAdd className="segment_feild_icon" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {toggleSegmentDelete && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${segmentDeleteMessage}?`}
          details={segmentDeleteMessageDetails}
          btn1Text={t("siteSeg.cancelBtn")}
          btn2Text={t("siteSeg.removeBtn")}
          color={"red"}
          handleConfirmAction={handleDeleteSingleSegment}
          handleHideConfirmationModal={closeSegmentDeleteModal}
          closeConfirm={closeSegmentDeleteModal}
        />
      )}
      {toggleSiteDelete && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("siteSeg.removeSiteTitle")} ${singleSite}?`}
          details={`Site ${singleSite} ${t("siteSeg.removeSiteDetails")}`}
          btn1Text={t("siteSeg.cancelBtn")}
          btn2Text={t("siteSeg.removeBtn")}
          color={"red"}
          handleConfirmAction={handleDeleteSiteAndSegment}
          handleHideConfirmationModal={closeSiteDeleteModal}
          closeConfirm={closeSiteDeleteModal}
        />
      )}
    </>
  );
};

export default EditSiteSegment;
