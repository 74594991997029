import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCalendar } from "react-icons/ai";
import DatePicker from "react-date-picker";
import { formatDate5 } from "../../../../../utils/DateFormater";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";
import { TimePicker } from "antd";
import { BiTimeFive } from "react-icons/bi";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const format = "HH:mm";

const EditDateTime = ({ editData }) => {
  const { t } = useTranslation();
  const { setSeconds, setCurrentTime, setConvertedDate, setChangeTracker } =
    useEditObservationStore();

  const [newDate, setNewDate] = useState(editData.date.date);
  const [cTime, setCTime] = useState(editData.date.date.slice(11, 16));

  useEffect(() => {
    if (editData) {
      let setDateObject = new Date(newDate);
      let convertedDate = formatDate5(setDateObject);
      setConvertedDate(convertedDate);
    }
  }, [editData, newDate]);

  // Effect to update currentTime when cTime changes
  useEffect(() => {
    setCurrentTime(cTime);
  }, [cTime, setCurrentTime]);

  const onChangeTime = (time, timeString) => {
    setCTime(timeString); // Update cTime when time changes
    setChangeTracker(true);
  };

  useEffect(() => {
    const getRandomNumber = () => {
      let seconds = Math.floor(Math.random() * 60)
        .toString()
        .padStart(2, "0");
      setSeconds(seconds);
    };
    getRandomNumber();
  }, [setSeconds]);

  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title">
        {t("dateTime")}
        <sup>*</sup>
      </div>

      <div className="col-7 modal__title__value d-flex">
        <div className="d-flex col-12">
          <div className="col-6 " style={{ width: "50%" }}>
            <DatePicker
              calendarIcon={<AiOutlineCalendar />}
              clearIcon={null}
              onChange={(e) => {
                setNewDate(formatDate5(new Date(e)));
                setChangeTracker(true);
              }}
              calendarClassName="calender__picker"
              className="calender__picker"
              value={newDate}
              required={true}
              format={"y-MM-dd"}
            />
          </div>

          <div className="col-6 " style={{ width: "50%" }}>
            <TimePicker
              className="time-picker-input"
              onChange={onChangeTime}
              value={cTime ? dayjs(cTime, format) : null} // Use cTime for the TimePicker value
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={format}
              clearIcon={null}
              suffixIcon={<BiTimeFive />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDateTime;
