import React from "react";
import spinner from "../../assets/images/Spinner.svg";
import "./Loading.css";
const Loading = () => {
  return (
    <div>
      {" "}
      <img src={spinner} width={30} alt="" />
    </div>
  );
};

export default Loading;
