import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import PatrolTable from "../../components/patrols/homeTable/PatrolTable";
import { useCallback } from "react";
import { useState } from "react";
import { useFetchPatrolList } from "../../queries/useFetchPatrols";
import { useSelector } from "react-redux";

const SinglePatrols = () => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const {
    patrolData,

    isRefetchingPatrol,
  } = useFetchPatrolList({ id: localProjectId });

  const [rowData, setRowsData] = useState([]);

  useEffect(() => {
    const handleSetProjectPatrols = () => {
      const patrols =
        patrolData &&
        patrolData !== "undefined" &&
        patrolData.pages.flatMap((page) => {
          return page.data;
        });
      setRowsData(patrols);
      // setUpdateObsArray(obs);
    };
    handleSetProjectPatrols();
  }, [patrolData, isRefetchingPatrol]);

  const [idList, setIdList] = useState([]);

  const CallBack = useCallback(
    (childrenData) => {
      setIdList(childrenData);
      if (idList && idList.length !== 0) {
      } else {
      }

      return childrenData;
    },
    [idList]
  );
  return (
    <div className="patrol__homePage">
      <Header />
      <div className="patrole__subHeader"></div>
      <PatrolTable handleCallback={CallBack} patrolData={rowData} />
    </div>
  );
};

export default SinglePatrols;
