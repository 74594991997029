export const observationStyle = {
  Crawls: {
    marginLeft: 5,
    background: "#c7f0ff",
    borderRadius: "20px",
    fontSize: "12px",
    color: "black",
    fontWeight: "bold",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noStyle: {
    marginLeft: 5,
    color: "white",
    background: "#808080",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  others: {
    marginLeft: 5,
    color: "white",
    background: "#808080",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Threat: {
    marginLeft: 5,
    background: "#e60909",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Animal: {
    marginLeft: 5,
    background: "#042b4a",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  FlashObs: {
    marginLeft: 5,
    background: "#0ad4e0",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  SignsPresence: {
    marginLeft: 5,
    background: "#3a8ac9",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Nest: {
    marginLeft: 5,
    background: "#22a6c1",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  FemaleEn: {
    marginLeft: 5,
    background: "#20628b",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
