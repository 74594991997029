const ourPartnersImages = [
  "./assets/iucn.png",
  "./assets/AFD.png",
  "./assets/Agire.jpeg",
  "./assets/BENIN.png",
  "./assets/epaa.png",
  "./assets/manta.png",
  "./assets/nationalGeographic.png",
  "./assets/minepia.png",
  "./assets/DRAM.png",
  "./assets/keta.jpeg",
  "./assets/tubeAwu.png",
  "./assets/swot.png"
];

export default ourPartnersImages;
