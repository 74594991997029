import React from "react";

const KM = ({ data }) => {
  return (
    <div>
      <div
        className=""
        style={{
          width: "160px",
          paddingRight: "30px",
          textAlign: "right ",
          display: "flex",
          justifyContent: "right",
        }}
      >
        {data.totalDistance} Km
      </div>
    </div>
  );
};

export default KM;
