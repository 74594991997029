import React, { useState } from "react";
import EditQuestion from "../EditQuestion";

const QuestionId = ({
  tableManager,
  value,
  onChange,
  isEdit,
  data,
  column,
  rowIndex,
  searchText,
  isFirstEditableCell,
}) => {
  const [toggleShowModal, settoggleShowModal] = useState(false);
  const handleToggleModal = () => {
    settoggleShowModal((prev) => !prev);
  };
  return (
    <div>
      <div className="obsId" onClick={handleToggleModal}>
        {data.id}
      </div>
      {toggleShowModal && (
        <EditQuestion closeModal={handleToggleModal} data={data} />
      )}
    </div>
  );
};

export default QuestionId;
