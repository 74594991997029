import React from "react";
import TitleComponent from "../../TitleComponent";
import SignUpBtn from "../SignUpBtn";
import "./styles.css";
import { useTranslation } from "react-i18next";
export default function ProductHighlights() {
  const { t } = useTranslation();
  return (
    <section className="ProductHighlights">
      <TitleComponent>{t("landingPage.products.title")}</TitleComponent>
      <div className="ProductHighlights-Content">
        <div className="ProductWrapper">
          <div className="ProductHighlights-Product">
            <img src="./assets/productDataPrivacy.png" alt="" />
            <div className="ProductHighlights-ProductTitle">
              {t("landingPage.products.prod1.topic")}
            </div>
            <div className="ProductHighlights-ProductText">
              {t("landingPage.products.prod1.content")}
            </div>
          </div>

          <div className="ProductHighlights-Product middleProduct">
            <img src="./assets/MultiLanguageSupport.png" alt="" />
            <div className="ProductHighlights-ProductTitle">
              {t("landingPage.products.prod2.topic")}
            </div>
            <div className="ProductHighlights-ProductText middleProductText">
              {t("landingPage.products.prod2.content")}
            </div>
          </div>

          <div className="ProductHighlights-Product">
            <img src="./assets/swotFormat.png" alt="" />
            <div className="ProductHighlights-ProductTitle">
              {t("landingPage.products.prod3.topic")}
            </div>
            <div className="ProductHighlights-ProductText">
              {t("landingPage.products.prod3.content")}
            </div>
          </div>
        </div>
        <div className="ProductHighlights-btnWrapper">
          <SignUpBtn />
        </div>
      </div>
    </section>
  );
}
