import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";
import backArrow from "../../../../../assets/images/backArrow.png";
import plus from "../../../../../assets/images/plus.png";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";
import { API_URL } from "../../../../../services/API";
import axios from "axios";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../../../../queries/useMapQueriesHook";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../ConfirmationModal";
import { BiErrorCircle } from "react-icons/bi";
import Loading from "../../../../LoadingError/Loading";
const EditImageIpload = ({
  editData,
  editModalState,
  setEditModalState,
  userInfo,
  setTrackDeleteImage,
  setImgWasDeleted,
}) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { prevImages, setPrevImages, setChangeTracker } =
    useEditObservationStore();
  const maxNumber = 4;

  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteLink, setDeleteLink] = useState();

  const [deleteImageLoading, setDeleteImageLoading] = useState(false);

  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const uploadImages = (imageList, addUpdateIndex) => {
    setChangeTracker(true);
    // Adding images data to array
    const extractedUrls = imageList.map((image) => image.data_url);

    extractedUrls.forEach((url) => {
      if (!prevImages.includes(url)) {
        setPrevImages([...prevImages, ...extractedUrls]);
        // setImages
      }
    });
  };

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const handleDeleteImage = async () => {
    setShowConfirmModal(false);
    setDeleteImageLoading(true);
    const deleteImg = {
      id: editData.id,
      link: deleteLink,
    };

    try {
      const token = userInfo.data.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        API_URL + `api/observationImage/delete`,
        {
          id: deleteImg.id,
          lien: deleteImg.link,
        },
        config
      );
      console.log(data);

      deleteNewlyUploadedImage(deleteIndex);
      queryRefetchObsList();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
      setDeleteImageLoading(false);
      setImgWasDeleted(true);
      // setDeleteImageSuccess(!deleteImageSuccess);
      setDeleteSuccess(!deleteSuccess);
      setTrackDeleteImage(true);
    } catch (err) {
      console.log(err);
      setDeleteImageLoading(false);
    }
  };
  const deleteNewlyUploadedImage = (index) => {
    const newImages = prevImages.filter((_, i) => i !== index);
    setPrevImages(newImages);
  };
  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const handleCloseConfirmOnly = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      {deleteImageLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            opacity: "9",
            zIndex: "9999",

            transform: "translate(-50%, -50%)}",
          }}
        >
          <Loading />{" "}
        </div>
      )}
      <div className="p-2">
        <div className="create_image_modal">
          <div>
            <ImageUploading
              multiple
              // value={images}
              onChange={uploadImages}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={["jpg", "jpeg", "png", "hevc", "heic", "gif"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <>
                  {prevImages && prevImages.length === 4 ? (
                    <div className="d-flex justify-content-between align-items-center mx-1 ">
                      <div
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>

                      <button
                        style={{
                          background: "#073B60",
                          opacity: "0.3",
                          padding: "10px 15px",
                          color: "white",
                          borderRadius: "10px",
                          fontSize: ".8rem",
                        }}
                      >
                        <img src={plus} alt="plus" className="me-2" />
                        {t("uploadNewPhoto")}
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>

                      <button
                        style={{
                          background: "#073b60",

                          padding: "10px 15px",
                          color: "white",
                          fontSize: ".8rem",
                          borderRadius: "10px",
                        }}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img src={plus} alt="plus" className="me-2" />
                        {t("uploadNewPhoto")}
                      </button>
                    </div>
                  )}

                  <div className="">
                    <div className="row d-flex ">
                      {prevImages.length === 0 ? (
                        <div
                          className="d-flex gap-1 align-items-center justify-content-center  ms-4 pt-1 "
                          style={{ height: "400px" }}
                        >
                          <div> {t("noImgAvailable")}</div>
                        </div>
                      ) : (
                        <>
                          {prevImages &&
                            prevImages.map((image, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                }}
                                className="imageWrapper ms-3"
                              >
                                {/* <div className="delete__img__icon__container"> */}
                                <div className="create__delete__img__icon__container   ">
                                  <AiFillDelete
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      display: "block",
                                      top: "5px",
                                      zIndex: "9999",
                                      cursor: "pointer",

                                      opacity: "1",
                                    }}
                                    className="deleteIcon"
                                    onClick={() => {
                                      setDeleteLink(image);

                                      if (!image.startsWith("https")) {
                                        // local image delete
                                        deleteNewlyUploadedImage(index);
                                      } else {
                                        // server image
                                        setShowConfirmModal(true);

                                        setDeleteIndex(index);
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  className=" tableImage details "
                                  style={{
                                    backgroundImage: `url(${image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    cursor: "pointer",
                                    position: "relative",
                                  }}
                                  onClick={() => {}}
                                />
                              </div>
                              // </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </ImageUploading>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={t("deleteItems")}
          details={t("itemsDeleted")}
          btn1Text={t("cancelBtn")}
          btn2Text={t("deleteBtn")}
          color={"#404040"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleDeleteImage}
          closeConfirm={handleCloseConfirmOnly}
        />
      )}
    </>
  );
};

export default EditImageIpload;
