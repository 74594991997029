import React from "react";

import { useTranslation } from "react-i18next";
import QuestionId from "./QuestionId";
import AnswersOptions from "./AnswersOptions";
import DeleteQuestion from "./DeleteQuestion";

const GetQuestionsColumn = ({ setRowsData }) => {
  const { t } = useTranslation();
  return [
    {
      id: "1",
      field: `id`,
      label: "ID",
      cellRenderer: (props) => <QuestionId {...props} />,
      pinned: true,
      className: "question__id",
      width: "150px",
    },

    {
      id: "2",
      field: "title",
      label: "Title",

      width: "300px",
      minResizeWidth: 120,
      className: "center columnWith",
    },
    {
      id: "3",
      field: `type`,
      label: "Type of Answer",
      className: "center columnWith ",
      minResizeWidth: 120,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },
    {
      id: "4",
      field: `answers`,
      label: "Answers Options",
      className: "center columnWith ",
      cellRenderer: (props) => <AnswersOptions {...props} />,
      minResizeWidth: 120,
      width: "250px",
    },
    {
      id: "buttons",
      width: "max-content",
      pinned: true,
      sortable: false,
      resizable: false,
      cellRenderer: (props) => <DeleteQuestion {...props} />,
    },
  ];
};

export default GetQuestionsColumn;
