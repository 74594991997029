import React, { useEffect } from "react";
import Header from "../../components/Header/Header";

import { useCallback } from "react";
import { useState } from "react";
import left_caret from "../../assets/images/icon/left_caret.svg";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import {
  deleteListOfObservations,
  deleteObservationLocally,
} from "../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import ListViewTable from "../../components/ListViewTable/ListViewTable";

import { useFetchPatrolValidObs } from "../../queries/useFetchPatrols";
import { useFetchProjectList } from "../../queries/useMapQueriesHook";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
const PatrolValidObs = () => {
  const { deleteListLoading, deleteListSuccess, deleteListMessage } =
    useSelector((state) => state.deleteNumerousObservation);
  let { patrolId } = useParams();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { projectList } = useFetchProjectList();

  const {
    patrolValidObs,
    patrolValidObsLoading,
    patrolValidError,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingPatrolValidObs,
    queryRefetchPatrolValidObs,
  } = useFetchPatrolValidObs({ id: patrolId });

  const dispatch = useDispatch();
  const [rowData, setRowsData] = useState([]);

  useEffect(() => {
    const handleSetProjectPatrols = () => {
      const patrols =
        patrolValidObs &&
        patrolValidObs !== "undefined" &&
        patrolValidObs.pages.flatMap((page) => {
          return page.data;
        });
      setRowsData(patrols);
      // setUpdateObsArray(obs);
    };
    handleSetProjectPatrols();
  }, [patrolValidObs, isRefetchingPatrolValidObs]);

  useEffect(() => {
    queryRefetchPatrolValidObs();
    setTimeout(() => {
      queryRefetchPatrolValidObs();
    }, 1000);
  }, [localProjectId, projectList, deleteListSuccess]);

  const [idList, setIdList] = useState([]);
  const [actionStyle, setActionStyle] = useState("btn-action-off");
  const [disabled, setDisabled] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const CallBack = useCallback(
    (childrenData) => {
      setIdList(childrenData);
      if (idList && idList.length !== 0) {
        setActionStyle("btn-action-on");
        setDisabled(false);
      } else {
        setActionStyle("btn-action-off");
        setDisabled(true);
        setShowActions(false);
      }

      return childrenData;
    },
    [idList]
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDeleteObservations = () => {
    dispatch(deleteListOfObservations(idList));
    dispatch(deleteObservationLocally(idList));
    // handleHideConfirmationModal();
    setShowConfirmModal(false);
  };
  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const userRoleOnProject = useSelector(
    (state) => state.userRoleOnParticularProject
  );
  const { role } = userRoleOnProject;
  const { t } = useTranslation();
  return (
    <>
      <div className="patrol__homePage">
        <Header />
        <div className="patrole__subHeader">
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              <Link to="/patrols">
                <img src={left_caret} alt="left_caret" height={20} />
              </Link>
            </div>
            <div
              className="patrol_subHeader_text"
              style={{
                marginTop: "5px",
              }}
            >
              {t("patrols.id")} {patrolId}{" "}
              <span style={{ marginLeft: "20px" }}>
                {t("patrols.validObs")} {`(${!rowData ? 0 : rowData.length})`}
              </span>
            </div>
          </div>

          <div className="px-3 d-flex align-items-center relative justify-content-center act-cnt">
            {role ? (
              <button
                type="button"
                className={`  px-2 ${actionStyle} `}
                disabled={disabled}
                // btn-action-on

                onClick={() => {
                  setShowActions(!showActions);
                }}
              >
                Actions
              </button>
            ) : (
              <button
                type="button"
                className={`  px-2 btn-action-off `}
                disabled={disabled}
                // btn-action-on

                // onClick={() => {
                //   setShowActions(!showActions);
                // }}
              >
                Actions
              </button>
            )}
            {showActions && !disabled && (
              <div
                className="actionLists"
                style={{
                  zIndex: "10",
                  width: "120px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  // positio: "fixed",
                }}
              >
                <span
                  className="d-block px-2 py-3 actionItem  disabled__btn"
                  style={{
                    borderBottom: ".5px solid #f0f0f0",
                  }}
                >
                  <span className="ms-2 me-sm-1 ">
                    <AiOutlineQuestionCircle
                      style={{
                        fontSize: "20px",
                        marginBottom: "2px",
                      }}
                    />
                  </span>{" "}
                  upload{t("patrols.upload")}
                </span>
                <span
                  className="d-block px-2 py-3 actionItem"
                  onClick={() => {
                    setShowConfirmModal(true);
                  }}
                >
                  <span className="ms-2 me-sm-1">
                    <MdDelete
                      style={{
                        fontSize: "20px",
                        marginBottom: "2px",
                      }}
                    />
                  </span>{" "}
                  {t("patrols.delete")}
                </span>
              </div>
            )}
          </div>
        </div>
        {/* <PatrolTable handleCallback={CallBack} /> */}
        <div className="patrol_mainTable">
          <ListViewTable
            handleCallback={CallBack}
            projectId={39}
            dates={[]}
            obsData={rowData}
            obsListLoading={patrolValidObsLoading}
            isRefetching={isRefetchingPatrolValidObs}
          />
        </div>
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineInfoCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("deleteItems")}`}
          details={`${t("itemsDeleted")}`}
          btn1Text={`${t("cancelBtn")}`}
          btn2Text={`${t("deleteBtn")}`}
          color={"#404040"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleDeleteObservations}
          closeConfirm={handleHideConfirmationModal}
        />
      )}
    </>
  );
};

export default PatrolValidObs;
