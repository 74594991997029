import React from "react";

import ID from "../custom/ID";
import Obs from "../custom/Obs";
import Val from "../custom/Val";
import UnVal from "../custom/UnVal";
import KM from "../custom/KM";
import { useTranslation } from "react-i18next";

const GetPatrolColumns = () => {
  const { t } = useTranslation();
  return [
    {
      id: "1",
      field: `id`,
      label: t("patrols.id"),
      cellRenderer: (props) => <ID {...props} />,
      pinned: true,
      width: "135px",
    },

    {
      id: "2",
      field: "observations",
      label: "# Obs",
      cellRenderer: (props) => <Obs {...props} />,
      pinned: false,
      width: "90px",
      minResizeWidth: 90,
      className: "",
    },
    {
      id: "3",
      field: `valObs`,
      label: "Val",
      cellRenderer: (props) => <Val {...props} />,
      className: "center ",
      minResizeWidth: 90,
      maxResizeWidth: null,
      pinned: false,
      width: "90px",
    },
    {
      id: "4",
      field: "unValidObs",
      label: t("patrols.unVal"),
      cellRenderer: (props) => <UnVal {...props} />,
      className: "center ",
      minResizeWidth: 90,
      maxResizeWidth: null,
      pinned: false,
      width: "110px",
    },
    {
      id: "5",
      field: "author",
      label: t("patrols.author"),
      className: "center columnWith ",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "140px",
    },
    {
      id: "6",
      field: "date",
      label: "Dates",
      getValue: ({ value }) => value.date.slice(0, 10),
      className: " columnWith table__number__align",
      minResizeWidth: 80,
      maxResizeWidth: null,
      pinned: false,
      width: "120px",
    },
    {
      id: "7",
      field: "startTime",
      label: t("patrols.startTime"),
      getValue: ({ value }) => value.date.slice(11, 16),
      className: "  columnWith table__number__align",
      minResizeWidth: 70,
      maxResizeWidth: null,
      pinned: false,
      width: "100px",
    },

    {
      id: "8",
      field: "endTime",
      label: t("patrols.endTime"),
      className: " columnWith table__number__align",
      getValue: ({ value }) => value.date.slice(11, 16),
      minResizeWidth: 70,
      maxResizeWidth: null,
      pinned: false,

      width: "90px",
    },

    {
      id: "9",
      field: "gpsStartCoordX",
      label: t("patrols.gpsStart"),
      width: "150px",
      className: "columnWith table__number__align",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
    },
    {
      id: "10",
      field: "gpsEndCoordX",
      label: t("patrols.gpsEnd"),
      className: " columnWith table__number__align",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "150px",
    },
    {
      id: "11",
      field: "totalPatrolTime",
      label: t("patrols.totalPatTime"),
      className: " columnWith table__number__align",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "175px",
    },
    {
      id: "12",
      field: "totalDistance",
      label: t("patrols.totalDistance"),
      cellRenderer: (props) => <KM {...props} />,
      className: " columnWith table__number__align",
      minResizeWidth: 110,
      maxResizeWidth: null,
      pinned: false,
      width: "160px",
    },
    {
      id: "13",
      field: "notes",
      label: "Notes",
      className: "center ",
      minResizeWidth: 180,
      maxResizeWidth: null,
      pinned: false,
      width: "180px",
    },
  ];
};

export default GetPatrolColumns;
