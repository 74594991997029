import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronUp } from "react-icons/bs";
import { Link } from "react-router-dom";

import { registerUser } from "../../actions/userActions";

import Message from "../../components/LoadingError/Error";
import Loading from "../../components/LoadingError/Loading";

// form Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Css import
import "./login.css";
// import "react-phone-number-input/style.css";

import { PhoneInput } from "react-simple-phone-input";

import { formatPhoneNumber } from "react-phone-number-input";

import {
  useFetchCFunctionList,
  useFetchCountryList,
} from "../../queries/useFetchOptions";
import { useTranslation } from "react-i18next";
// Randomly renderin login images
const loginImagesPath = [
  `url(assets/elements/images/turtleCompressed.png)`,
  `url(assets/elements/images/SharkimageCompressed.png)`,
  `url(assets/elements/images/DolphinCompressed.png)`,
];
// mobile images imports
const loginImagesPathMoble = [
  `url(assets/elements/images/Shark-image-small-2.png)`,
  `url(assets/elements/images/Turtle-image-small.png)`,
  `url(assets/elements/images/Dolphin-image-small-3.png)`,
];
let imagePathId = Math.floor(Math.random() * 3);

const mystyle = {
  background: loginImagesPath[imagePathId],
  // backgroundSize: "cover",
  width: "50%",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};
const mystyleMobile = {
  background: loginImagesPathMoble[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};

const Register = () => {
  // Translation
  // Translate switcher
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("localLangVal");
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);
  //   Getting countries list from the backend api
  const [countryList, setCountryList] = useState([]);
  const [funcList, setFuncList] = useState([]);

  const { queryCountryList } = useFetchCountryList();

  const { functionListData } = useFetchCFunctionList();

  useEffect(() => {
    function sortCountryByName() {
      if (queryCountryList) {
        queryCountryList.sort((obs1, obs2) => {
          let fa = obs1.name.toLowerCase(),
            fb = obs2.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        setCountryList(queryCountryList);
      }
    }
    sortCountryByName();
    const getFunctions = async () => {
      const sortedData = functionListData?.sort((a, b) => {
        if (a.title === "Other" || a.title === "Autre") return 1;
        if (b.title === "Other" || a.title === "Autre") return -1;
        return a.title.localeCompare(b.title);
      });

      setFuncList(sortedData);
    };

    getFunctions();
  }, [queryCountryList, functionListData]);

  // You validation schema
  const [phone, setPhone] = useState();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(`${t("required")}`),
    fname: Yup.string().required(`${t("required")}`),
    lname: Yup.string().required(`${t("required")}`),
    fonction: Yup.string().required(`${t("required")}`),
    location: Yup.string().required(`${t("required")}`),
    country: Yup.string().required(`${t("required")}`),
    organization: Yup.string().required(`${t("required")}`),
    password: Yup.string()
      .min(8)
      .required(`${t("required")}`),
    confirmPassword: Yup.string()
      .required(`${t("required")}`)
      .oneOf([Yup.ref("password"), null], `${t("passDntMatch")}`),
  });
  const [phoneError, setPhoneError] = useState(false);
  useEffect(() => {
    if (phone && !formatPhoneNumber(phone.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [phone]);
  // get phone number without code
  const [phoneWithoutCode, setPhoneWithoutCode] = useState();
  const handleGetPhoneWithoutCode = (phone) => {
    let countryCode = phone.dialCode;
    let phoneNumber = phone.value.replace(countryCode, "");
    setPhoneWithoutCode(phoneNumber);
  };

  // importing the default register and handle submit functions from react hook forms
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",

    resolver: yupResolver(validationSchema),
  });

  // onSubmit function responsible for collecting the form Field  data
  const dispatch = useDispatch();

  //   Password visibility (hide/show)
  const [show, setShow] = useState("false");
  const [showConfirm, setShowConfirm] = useState("false");

  const handleShow = () => {
    setShow(!show);
  };
  const handleShowConfirmPass = () => {
    setShowConfirm(!showConfirm);
  };

  const onSubmit = (data) => {
    if (phone && formatPhoneNumber(phone.value)) {
      setPhoneError(false);
      dispatch(
        registerUser(
          data.fname,
          data.lname,
          data.organization,
          data.email,
          data.password,
          data.location,
          phoneWithoutCode,
          phone.dialCode,
          phone.code,
          data.fonction,
          data.country
        )
      );
    } else {
      setPhoneError(true);
    }
  };

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, user } = userRegister;

  return (
    <>
      {/* {user && (
        <SuccessAlert
          message={
            "Account successfully created check your mails to confirm your account"
          }
        />
      )} */}
      <div className="container-fluid p-0 login-page">
        <div className="row header bg-cl-bl d-md-none d-lg-none d-sm-none ">
          <div className="col-xs-12 text-center">
            <Link to={"/"}>
              <img
                src="assets/elements/images/logo/logo.png"
                alt=""
                className="logo"
              />
            </Link>
          </div>
        </div>
        <div
          className="row header-img bg-cl-bl d-md-none d-lg-none d-sm-none "
          style={mystyleMobile}
        ></div>

        <div className={` vh-100 register__container`}>
          <div className="pt-5 ps-3 form__section">
            <div className="row d-none d-sm-block p-3">
              <Link to={"/"}>
                <img
                  src="assets/elements/images/logo/logos.png"
                  alt=""
                  className="logos"
                />
              </Link>
            </div>
            <div className="  mt-2 ">
              {!user && (
                <div
                  className="  offset-md-2 align-self-center pb-3"
                  id="block-register"
                  // style={{ maxWidth: "700px" }}
                >
                  <div className="mb-0">
                    <div
                      className="mb-3 h3 f-bold"
                      style={{ color: "#073B60" }}
                    >
                      {t("joinSiren")}
                    </div>
                    <div className="cl-gr fs-6 " style={{ width: "80%" }}>
                      {t("aboutYou")}
                    </div>
                  </div>
                  {error && (
                    <Message
                      variant="alert-danger"
                      dynamicClass="register__error"
                    >
                      {error}
                    </Message>
                  )}
                  <div>
                    <div className="loadingContainer">
                      {loading && <Loading />}
                    </div>
                    <div className="register__fixed__div">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="register__form"
                        style={
                          {
                            // width: "80%",
                          }
                        }
                      >
                        <div
                          className=" "
                          style={{
                            color: "#073B60",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginBottom: "8px",
                          }}
                        >
                          {t("accountName")}
                        </div>
                        <div
                          className=" position-relative"
                          // style={{  }}
                        >
                          <label htmlFor="">
                            {t("fName")} <sup>*</sup>
                          </label>
                          <br />
                          <input
                            type="text"
                            name="firstName"
                            placeholder={`${t("namePlaceholder")}`}
                            // className="w-100"
                            className={`w-100 form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            id="fname"
                            {...register("fname")}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.fname?.message}</p>
                          </div>
                        </div>
                        <div
                          className="mt-1 position-relative"
                          style={{ marginBottom: "30px" }}
                        >
                          <label htmlFor="">
                            {t("lName")} <sup>*</sup>
                          </label>
                          <br />
                          <input
                            type="text"
                            name="lname"
                            placeholder={`${t("lNamePlaceholder")}`}
                            // className="w-100"
                            className={`w-100 form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            id="lname"
                            {...register("lname")}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.lname?.message}</p>
                          </div>
                        </div>
                        <div
                          className=""
                          style={{
                            color: "#073B60",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {t("contactInfo")}
                        </div>
                        <div className="mt-1 position-relative" style={{}}>
                          <label htmlFor="">{t("phone")}</label> <sup>*</sup>
                          <br />
                          <PhoneInput
                            country="US"
                            // countryCallingCodeEditable={false}
                            international
                            // dialCodeInputField
                            iconComponent={<BsChevronUp />}
                            value={phone}
                            onChange={(data) => {
                              setPhone(data);
                              handleGetPhoneWithoutCode(data);
                            }}
                          />
                          <div style={{ height: "20px" }}>
                            {phoneError && (
                              <p className="error">{`This Field  is required`}</p>
                            )}
                          </div>
                        </div>
                        <div className="mt-1 position-relative" style={{}}>
                          <label htmlFor="">Email</label> <sup>*</sup>
                          <br />
                          <input
                            type="email"
                            name="email"
                            placeholder="Email..."
                            // className="w-100"
                            className={`w-100 form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            {...register("email")}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.email?.message}</p>
                          </div>
                        </div>
                        <div
                          className=""
                          style={{
                            color: "#073B60",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginTop: "30px",
                          }}
                        >
                          {t("location")}
                        </div>
                        <div className="mt-1 mb-2  position-relative">
                          <label>{t("locationExamples")}</label>
                          <sup>*</sup>
                          <br />
                          <input
                            type="text"
                            name="location"
                            placeholder={`${t("locationPlaceholder")}`}
                            className={`w-100  form-control  ${
                              errors.location ? "is-invalid" : ""
                            }`}
                            {...register("location")}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.location?.message}</p>
                          </div>
                        </div>
                        <div
                          className="mt-2  position-relative"
                          style={{ marginBottom: "30px" }}
                        >
                          <label htmlFor=""> {t("country")}</label> <sup>*</sup>
                          <br />
                          <select
                            className="form-control"
                            name="country"
                            id="country"
                            {...register("country")}
                          >
                            <option disabled selected value="">
                              {t("countryPlaceholder")}
                            </option>

                            {countryList &&
                              countryList.map((country) => (
                                <option value={country.id} key={country.id}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.country?.message}</p>
                          </div>
                        </div>
                        <div
                          className=""
                          style={{
                            color: "#073B60",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {t("prof")}
                        </div>
                        <div className="mt-2 mb-1 position-relative">
                          <label htmlFor="">
                            {t("job")} <sup>*</sup>
                          </label>
                          <br />

                          <select
                            className=" form-control" //form-select
                            name="fonction"
                            id="fonction"
                            {...register("fonction")}
                          >
                            <option disabled selected value="">
                              {t("jobPlaceholder")}
                            </option>

                            {funcList &&
                              funcList.map((func) => (
                                <option value={func.id} key={func.id}>
                                  {func.title}
                                </option>
                              ))}
                          </select>
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.fonction?.message}</p>
                          </div>
                        </div>
                        <div className=" position-relative">
                          <label htmlFor="">
                            {t("organization")} <sup>*</sup>
                          </label>
                          <br />
                          <input
                            type="text"
                            name="organization"
                            placeholder={`${t("orgaPlaceholder")}`}
                            // className="w-100"
                            className={`w-100 form-control ${
                              errors.organization ? "is-invalid" : ""
                            }`}
                            id="city"
                            {...register("organization")}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">
                              {errors.organization?.message}
                            </p>
                          </div>
                        </div>
                        <div
                          className=""
                          style={{
                            color: "#073B60",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginTop: "30px",
                          }}
                        >
                          {t("password")}
                        </div>
                        <div className=" position-relative" style={{}}>
                          <label>
                            {t("password")} <sup>*</sup>
                          </label>
                          <br />
                          <input
                            type={show ? "password" : "text"}
                            name="password"
                            {...register("password")}
                            placeholder={`${t("regPassPlaceholder")}`}
                            // style={{ fontSize: ".7rem" }}
                            // className="w-100 rounded password"
                            className={`w-100 rounded password form-control  ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">{errors.password?.message}</p>
                          </div>
                          <label onClick={handleShow}>
                            {show ? (
                              <img
                                src="assets/elements/images/icon/eyesc.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            ) : (
                              <img
                                src="assets/elements/images/icon/eyes.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            )}
                          </label>
                        </div>
                        <div className=" position-relative" style={{}}>
                          <label>
                            {t("confirmPass")} <sup>*</sup>
                          </label>
                          <br />
                          <input
                            type={showConfirm ? "password" : "text"}
                            name="password"
                            {...register("confirmPassword")}
                            placeholder={`${t("regiConfirmPass")}`}
                            id="confirmPassword"
                            // style={{ fontSize: ".7rem" }}
                            // className="w-100 rounded password"
                            className={`w-100 rounded password form-control  ${
                              errors.confirmPassword ? "is-invalid" : ""
                            }`}
                          />
                          <div style={{ height: "20px" }}>
                            <p className="error">
                              {errors.confirmPassword?.message}
                            </p>
                          </div>
                          <label onClick={handleShowConfirmPass}>
                            {showConfirm ? (
                              <img
                                src="assets/elements/images/icon/eyesc.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            ) : (
                              <img
                                src="assets/elements/images/icon/eyes.svg"
                                alt=""
                                className="d-inline-block eye-close pointer"
                              />
                            )}
                          </label>
                        </div>

                        <button
                          disabled={!isDirty || !isValid}
                          type="submit"
                          className=" btn-lg btn-block w-100 mt-4 login-btn"
                        >
                          {t("submitForm")}
                        </button>
                        <div className="text-center mt-3">
                          {t("alreadyHaveAccnt")}?{" "}
                          <Link className="lien" to={"/login"}>
                            {t("signIn")}
                          </Link>
                        </div>
                        <div className="register__spacer"></div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {user && (
                <div
                  className="col-md-8 offset-md-2  align-self-center"
                  // id="block-tanks"
                >
                  <div className="mb-3">
                    <div className="mb-3 h3 f-bold">
                      {t("thanksForsubmitting")}
                    </div>
                    <div className="cl-gr ">{t("requestSubmited")}</div>
                  </div>
                  <button
                    type="submit"
                    className=" btn-lg btn-block  mt-4 login-btn"
                  >
                    <Link
                      className="lien"
                      to={"/login"}
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      {t("backLogin")}
                    </Link>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            className="col-md-6 col-sm-3 d-none d-sm-block login-image "
            style={mystyle}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Register;
