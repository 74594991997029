import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./projectcontent.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

// import { useNavigate } from "react-router-dom"

import {
  displaySuccessMsgCreateProject,
  getRole,
  operationOnProject,
  project$,
  role$,
  setCreateProject,
  setModPro,
  setProject,
  setProjectData,
  setSuccessModifieProject,
  successCreateProject$,
  successModifieAccount$,
} from "./../../services/rxjs";
import { API_URL as API } from "../../services/API";
import SucccessMsg from "../adminSuccessMsg/successMsg";

import Loading2 from "../LoadingError/Loading2";
import DataExport from "./dataExportFeature/dataexport";
import { useFetchProjectList } from "../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";
import { Share } from "react-bootstrap-icons";
import ShareLink from "./shareLink/ShareLink";
import { useSelector } from "react-redux";

const ProjectContent = ({
  childrenCheck,
  user,
  project,
  country,
  updateProject,
  admin,
  creation,
  projectRefresh,
  publicAttribute,
}) => {
  // Translation
  const { t } = useTranslation();

  // Project list fetch
  const { projectList, refetchProjects, isLoading } = useFetchProjectList();

  const [countryChoise, setCountryChoise] = useState([]);
  const [roleAdmin, setRoleAdmin] = useState(project?.role);
  const [publicDefault, setpublicDefault] = useState();
  const [radio, setRadio] = useState();
  const [update, setUpdate] = useState("New Project");
  const [isDifferent, setIsDifferent] = useState(false);
  // const [country, setCountry] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [modified, setModified] = useState(false);
  const [created, setCreated] = useState(false);
  const [changed, setChanged] = useState(false);
  const [lproject, setLproject] = useState({});
  const [projectCountry, setProjectCountry] = useState({});
  const [newProject, setNewProject] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [inputEnterTest, setInputEnterTest] = useState([
    true,
    true,
    true,
    false,
    false,
    false,
  ]);
  const [nomDuProject, setNomDuProject] = useState("");
  const [nomDuLocation, setNomDuLlocation] = useState("");
  const [nomOrganization, setNomOrganization] = useState("");
  const [errorProjectName, setErrorProjectName] = useState(false);
  const [validate, setValidate] = useState(false);

  const [errorLocation, setErrorLocation] = useState(false);
  const [errorOrganization, setErrorOrganization] = useState(false);

  // const [selectedTimezone, setSelectedTimezone] = useState("africa/nigeria");
  const [selectedTimezone, setSelectedTimezone] = useState(project?.timezone);

  const onChangeProject = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    setNomDuProject(e.target.value);
    setProjectData({ name: "name", value: e.target.value });
    if (e.target.value === "") {
      setErrorProjectName(true);
      copy[0] = false;
      isempty = true;
    } else {
      setErrorProjectName(false);
      // copy[0] = true;
    }

    if (e.target.value !== lproject.organization && !isempty) copy[0] = true;
    if (e.target.value === lproject.organization) copy[0] = true; //adding

    copy[3] = true;
    setInputEnterTest(copy);
    checkValidate(0, copy[0]);
  };

  const onChangeLocation = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    setNomDuLlocation(e.target.value);
    setProjectData({ name: "location", value: e.target.value });
    if (e.target.value === "") {
      setErrorLocation(true);
      copy[1] = false;
      isempty = true;
    } else {
      setErrorLocation(false);
    }

    if (e.target.value !== lproject.organization && !isempty) copy[1] = true;
    if (e.target.value === lproject.organization) copy[1] = true;

    copy[4] = true;
    setInputEnterTest(copy);
    checkValidate(1, copy[1]);
  };

  const onChangeOrganization = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    setNomOrganization(e.target.value);
    setProjectData({ name: "organization", value: e.target.value });
    if (e.target.value === "") {
      setErrorOrganization(true);
      copy[2] = false;
      isempty = true;
    } else {
      setErrorOrganization(false);
    }

    if (e.target.value !== lproject.organization && !isempty) copy[2] = true;
    if (e.target.value === lproject.organization) copy[2] = true;

    copy[5] = true;
    setInputEnterTest(copy); //nest pas tjrs modifier
    checkValidate(2, copy[2]);
  };

  const checkValidate = (position, val) => {
    let check = false;
    switch (position) {
      case 0:
        // check = (val || inputEnterTest[1] || inputEnterTest[2]) && empty;
        check = val && inputEnterTest[1] && inputEnterTest[2];
        break;
      case 1:
        check = val && inputEnterTest[0] && inputEnterTest[2];
        break;
      case 2:
        check = val && inputEnterTest[1] && inputEnterTest[0];
        break;
      default:
        check = false;
    }

    setValidate(check);
    operationOnProject(check);
    setCreateProject(check); //we use the same thing with the creation part

    //est modifier apres ca
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const goToAdd = () => {
    childrenCheck(7);
  };

  const setModif = (bool) => {
    setModified(bool);
  };

  const displayMsg = () => {
    const timer = setTimeout(() => {
      setModif(false);
      // if (creation === true) projectRefresh();
    }, 4000);
  };

  const changeProject = (changedProject) => {
    setNomDuProject(changedProject?.name);
    setNomDuLlocation(changedProject?.location);
    setNomOrganization(changedProject?.organization);
    setPublic(changedProject?.public);

    isRoleAdmin(changedProject?.role);

    let pays = country.filter((cn) => cn.label === changedProject?.country);

    setProjectCountry(pays[0]);
    setUpdate(changedProject?.name);
    setLproject(changedProject);
    setInputEnterTest([true, true, true]);
    setValidate(false);
    setNewProject(false);
    setSelectedTimezone(changedProject?.timezone);
  };

  const setPublic = (pub) => {
    if (pub === 1) {
      setpublicDefault(true);
      setRadio(true);
    } else {
      setpublicDefault(false);
      setRadio(false);
    }
  };

  const isRoleAdmin = (role) => {
    if (role === "admin") setRoleAdmin(true);
    else setRoleAdmin(false);
  };

  const checkRole = () => {
    isRoleAdmin(project?.role);
  };

  useEffect(() => {
    // setDefault();

    checkRole();
    successModifieAccount$.subscribe((result) => setModified(result));

    project$.subscribe((changedProject) => changeProject(changedProject));
    operationOnProject(1);

    successCreateProject$.subscribe((active) => setSuccessMsg(active));
    displaySuccessMsgCreateProject();

    if (publicAttribute === 1) {
      setpublicDefault(true);
      setRadio(true);
    } else {
      setpublicDefault(false);
      setRadio(false);
    }
  }, []);

  const goToAccount = () => {
    childrenCheck(1);
  };
  //*

  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = Yup.object().shape({
    public: Yup.boolean().required(`${t("required")}`),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setUpdateLoading(true);

    let link = "";
    let publi = radio ? 1 : 0;
    let timez = "";

    link = "api/project/update";
    setChanged(true);
    setCreated(false);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      // onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    };

    if (selectedTimezone.value === undefined) timez = selectedTimezone;
    else timez = selectedTimezone.value;

    let info = {
      id: lproject.id,
      name: nomDuProject,
      country_id: projectCountry?.value,
      country: projectCountry?.label,
      organization: nomOrganization,
      location: nomDuLocation,
      timeZone: timez,
      public: publi,
      timezone: timez,
    };

    axios
      .post(API + link, info, config)
      .then(function (response) {
        setValidate(false);
        response.data.data["role"] = "admin";
        response.data.data.public = publi;
        setModif(true);

        setProject(JSON.stringify(response.data.data), refetchProjects);

        localStorage.setItem("projectName", response.data.data.name);
        localStorage.setItem("projectId", response.data.data.id);

        localStorage.setItem(
          "selectedProject",
          JSON.stringify(response.data.data)
        );
        setModPro();

        // updateProject(response.data.data.id, info);
        refetchProjects();
        setCreateProject(false);
        setSuccessModifieProject();

        isRoleAdmin("admin");
        // childrenCheck(2);
        displayMsg();

        reset({}, { keepValues: true });

        setUpdateLoading(false);
      })
      .catch(function (error) {
        console.log(error, "update project error");
        setUpdateLoading(false);
      });
  };

  const checkRadio = () => {
    setRadio(!radio);
    let valueSend = !radio ? 1 : 0;
    setProjectData({ name: "public", value: valueSend });
    if (!radio !== publicDefault) {
      setIsDifferent(true);
      setValidate(true);
    } else {
      setIsDifferent(false);
    }
  };

  const choix = (c) => {
    let copy = [...inputEnterTest];

    setProjectCountry(c);
    setCountryChoise(c);
    setValidate(true);
    copy[7] = true;
    setInputEnterTest(copy);

    setProjectData({ name: "country", value: c.label });
    setProjectData({ name: "country_id", value: c.value });
  };

  const timezne = (e) => {
    let copy = [...inputEnterTest];
    setSelectedTimezone(e);
    copy[8] = true;
    setInputEnterTest(copy);
    setValidate(true);
    setProjectData({ name: "timezone", value: e.value });
  };

  // site segment edit=============

  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <>
      {deleteLoading || updateLoading ? <Loading2 /> : ""}

      <div className="admin-content flex-fill">
        <div className="container position-relative">
          {modified && (
            <SucccessMsg message={`${t("admin.projectDetails.saveChanges")}`} />
          )}
          {successMsg && (
            <SucccessMsg
              message={`${t("admin.projectDetails.projectCreated")}`}
            />
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="mx-2 ">
            <div className="row">
              <div className="col-md-12 text-end">
                <div
                  className="btn modal_obs__btn this px-1 d-inline-block me-3"
                  onClick={() => {
                    goToAdd();
                  }}
                >
                  <span className="mr-5 fs-4 d-inline-block txtplus">+ </span>
                  <span className="txtadd">
                    {" "}
                    {t("admin.projectDetails.newProject")}
                  </span>
                </div>
              </div>

              {!isLoading && projectList?.data?.length > 0 && (
                <>
                  <div className="col-md-12 offset-md-1 title-text  mb-3">
                    {" "}
                    {lproject.name ? lproject.name : "Project"}
                  </div>
                  <div className="col-md-4 offset-md-1">
                    <div className="mt-1 mb-2  position-relative">
                      <label>{t("admin.projectDetails.projectName")} *</label>
                      <br />
                      {roleAdmin ? (
                        <input
                          type="text"
                          name="projectname"
                          id="projectname"
                          value={nomDuProject}
                          onChange={onChangeProject}
                          className={`w-100 inpProject form-control ${
                            errorProjectName ? "is-invalid" : ""
                          } ${
                            inputEnterTest[0] && inputEnterTest[3]
                              ? "changedInput"
                              : ""
                          }`}
                          placeholder={`${t(
                            "admin.projectDetails.projectPlaceHolder"
                          )}`}
                        />
                      ) : (
                        <span className="margePublic title-text2">
                          {nomDuProject}
                        </span>
                      )}
                    </div>
                    {errorProjectName && (
                      <p className="error">{t("required")}</p>
                    )}
                    <div className="mt-3 mb-2  position-relative">
                      <label>{t("admin.profile.locationDetails")} *</label>
                      <br />
                      {roleAdmin ? (
                        <input
                          type="text"
                          name="location"
                          value={nomDuLocation}
                          onChange={onChangeLocation}
                          placeholder={`${t(
                            "admin.projectDetails.projectLocationPlaceholder"
                          )}`}
                          className={`w-100 loc form-control ${
                            errorLocation ? "is-invalid" : ""
                          }${
                            inputEnterTest[1] && inputEnterTest[4]
                              ? "changedInput"
                              : ""
                          }`}
                        />
                      ) : (
                        <span className="margePublic title-text2">
                          {nomDuLocation}
                        </span>
                      )}
                    </div>
                    {errorLocation && <p className="error">{t("required")}</p>}
                    <div className="mt-3 mb-2  position-relative">
                      <label>{t("admin.profile.organisation")} *</label>
                      <br />
                      {roleAdmin ? (
                        <input
                          type="text"
                          name="organization"
                          value={nomOrganization}
                          onChange={onChangeOrganization}
                          placeholder={`${t(
                            "admin.projectDetails.projectOrganizationPlaceholder"
                          )}`}
                          className={`w-100 inpProject form-control ${
                            errorOrganization ? "is-invalid" : ""
                          }${
                            inputEnterTest[2] && inputEnterTest[5]
                              ? "changedInput"
                              : ""
                          }`}
                        />
                      ) : (
                        <span className="margePublic title-text2">
                          {nomOrganization}
                        </span>
                      )}
                    </div>
                    {errorOrganization && (
                      <p className="error">{t("required")}</p>
                    )}
                  </div>
                  <div className="col-md-4 offset-md-1">
                    <div className="mt-1 mb-2  position-relative">
                      <label>{t("country")} *</label>
                      <br />
                      {roleAdmin ? (
                        <Select
                          className={`${
                            inputEnterTest[7] ? "changedInput" : ""
                          }`}
                          options={country}
                          // {...country}
                          onChange={(choice) => choix(choice)}
                          placeholder={`${t("countryPlaceholder")}`}
                          value={projectCountry}
                        />
                      ) : (
                        <span className="margePublic title-text2">
                          {project.country}
                        </span>
                      )}
                      <p className="error">{errors.country?.message}</p>
                    </div>
                    <div className="mt-3 mb-2  position-relative">
                      <label>
                        {t("admin.projectDetails.projectTimeZone")} *
                      </label>{" "}
                      <br />
                      {roleAdmin ? (
                        <TimezoneSelect
                          className={`${
                            inputEnterTest[8] ? "changedInput" : ""
                          }`}
                          value={selectedTimezone}
                          onChange={timezne}
                          timezones={{
                            ...allTimezones,
                            "America/Lima": "Pittsburgh",
                            "Europe/Berlin": "Frankfurt",
                          }}
                        />
                      ) : (
                        <span className="margePublic title-text2">
                          {selectedTimezone}
                        </span>
                      )}
                    </div>

                    <div className="position-relative ">
                      {roleAdmin ? (
                        <div className="mt-5"></div>
                      ) : (
                        <div className="mt-4"></div>
                      )}
                      <label
                        className="form-check-label pospub title-text2"
                        from="flexSwitchCheckDefault"
                      >
                        Public
                      </label>
                      <div className="form-check form-switch position-relative">
                        {roleAdmin ? (
                          <input
                            className="form-check-input posradio"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            name="public"
                            checked={radio}
                            {...register("public")}
                            onChange={() => {
                              checkRadio();
                            }}
                          />
                        ) : radio ? (
                          <span className="margePublic">
                            {t("admin.projectDetails.yes")}
                          </span>
                        ) : (
                          <span className="margePublic">
                            {t("admin.projectDetails.no")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {roleAdmin && (
              <div className="col-md-4 offset-md-1">
                <ShareLink />
              </div>
            )}

            {roleAdmin && (
              <div className="row mt-4 addProject__btn container d-flex flex-column flex-sm-row">
                <div className="col-md-4 offset-md-1">
                  <div className="text-start">
                    <DataExport user={user} project={project} />
                  </div>
                </div>
                <div className="col-md-4 offset-md-1">
                  <div className="text-end">
                    <button
                      disabled={!validate}
                      type="submit"
                      className=" btn-block mt-4 login-btn admin-btn-dark"
                    >
                      {t("saveBtn")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>

        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModal}
          backdrop="static"
          centered
          className="mx-auto"
          keyboard={false}
          size="sm"
          dialogClassName="tailledeletemodal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className=" text-end p-2 ">
            <span
              className="pe-sm-2 pointer "
              onClick={() => {
                handleDeleteModal();
              }}
            >
              <img
                src="assets/elements/images/cross.png"
                alt=""
                style={{ height: "15px", cursor: "pointer" }}
              />
            </span>
          </div>

          <Modal.Body>
            <div className=" d-flex justify-content-center">
              <img
                src="assets/elements/images/delete_icon2.png"
                alt=""
                width={40}
              />
            </div>
            <div className="text-center h5 mt-3">
              {" "}
              {t("admin.modal.leavePage")} ?
            </div>
            <div className="text-center mb-3">
              {t("admin.modal.projectNotCreated")}
            </div>
          </Modal.Body>

          <div
            className="d-flex mt-3"
            style={{
              borderTop: ".5px solid #B3B3B3",
            }}
          >
            <div
              className="text-center p-2 fs-7 validbtn"
              style={{
                borderRight: ".5px solid #B3B3B3",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                goToAccount();
              }}
            >
              {t("admin.modal.leave")}
            </div>
            <div
              className="p-2 text-center fs-7 validbtn"
              style={{
                width: "50%",
                color: "#404040",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              {t("admin.modal.stay")}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProjectContent;
