import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  // Routes,
  Route,
  Switch,
  // Routes,
} from "react-router-dom";
import ForgottenPassword from "./pages/auth/forgottenPassword";
import "./App.css";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";

import Navigation from "./router/Navigation";
// Toast notification import

import ResetPassword from "./pages/auth/ResetPassword";
import RegisterConfirmation from "./pages/auth/RegisterConfirmation";
import Draft from "./components/Draft/Draft";
// import MapComponent from "./components/map/MapComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { setItemList } from "./services/rxjs";
import Home from "./pages/Home";
import { useSelector } from "react-redux";
import ProgressBarComponent from "./components/progressBar/ProgressBar";
import CaseStudy from "./components/CaseStudySections/CaseStudy";
import VIPMapView from "./pages/vip/map/VIPMapView";
import VIPListView from "./pages/vip/listview/VIPListView";
import VIPDashboard from "./pages/vip/dashboard/VIPDashboard";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      ReactQueryDevtools: true,
    },
  },
});

const App = () => {
  const uploadObsToInaturalist = useSelector(
    (state) => state.uploadObsToInaturalist
  );
  const { uploadInaturalistLoading } = uploadObsToInaturalist;
  return (
    <div className="body">
      <div>
        <script src="%PUBLIC_URL%/assets/elements/js/functions.js"></script>
      </div>
      <div
        className="App"
        onClick={(e) => {
          setItemList(-1);
        }}
      >
        {uploadInaturalistLoading && <ProgressBarComponent />}
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen /> */}

          <Router>
            <Switch>
              <Route exact path="/" component={Home}></Route>

              <Route path={`/draft`} component={Draft}></Route>

              <Route path="/login" component={Login}></Route>

              <Route path="/mapdraft" component={Draft}></Route>
              <Route
                path="/explore/map/:projectId"
                component={VIPMapView}
              ></Route>
              <Route
                path="/explore/listview/:projectId"
                component={VIPListView}
              ></Route>
              <Route
                path="/explore/dashboard/:projectId"
                component={VIPDashboard}
              ></Route>
              <Route
                // path="/confirm-registration"
                path="/confirm-registration/:token"
                component={RegisterConfirmation}
              ></Route>
              <Route path="/register" component={Register}></Route>
              <Route
                path="/forgottenPassword"
                component={ForgottenPassword}
              ></Route>
              <Route path="/reset_password" component={ResetPassword}></Route>

              <Route
                path="/case-studies/:studyName"
                component={CaseStudy}
              ></Route>

              <Route path="/" component={Navigation} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </div>
    </div>
  );
};

export default App;
