import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import plus from "../../../../../assets/images/plus.png";
import backArrow from "../../../../../assets/images/backArrow.png";
import { AiFillDelete } from "react-icons/ai";
import "../style.css";
const ImagesUpload = ({
  setChangeTracker,
  showUploadUI,
  setShowUploadUI,
  images,

  setImages,
  setImageData,
}) => {
  const { t } = useTranslation();

  const uploadImages = (imageList, addUpdateIndex) => {
    setChangeTracker(true);
    if (imageList.length === 1) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
      }));
    } else if (imageList.length === 2) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
      }));
    } else if (imageList.length === 3) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
        img3: imageList[2].data_url,
      }));
    } else if (imageList.length === 4) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
        img3: imageList[2].data_url,
        img4: imageList[3].data_url,
      }));
    }

    setImages(imageList);
  };
  const maxNumber = 4;
  return (
    <Modal.Body>
      <div className="p-2">
        <div className="create_image_modal">
          <div>
            <ImageUploading
              multiple
              value={images}
              onChange={uploadImages}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={["jpg", "jpeg", "png", "hevc", "heic", "gif"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <>
                  {/* Conditionally showing upload button */}
                  {images.length === 4 ? (
                    <div className="d-flex justify-content-between align-items-center mx-1">
                      <div
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>

                      <button
                        style={{
                          background: "#073B60",
                          opacity: "0.3",
                          padding: "10px 15px",
                          color: "white",
                          fontSize: ".8rem",
                          borderRadius: "10px",
                        }}
                      >
                        <img src={plus} alt="plus" className="me-2" />
                        {t("uploadNewPhoto")}
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center mx-2">
                      <div
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>

                      <button
                        style={{
                          background: "#073b60",
                          fontSize: ".8rem",
                          padding: "10px 15px",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img src={plus} alt="plus" className="me-2" />
                        {t("uploadNewPhoto")}
                      </button>
                    </div>
                  )}

                  <div
                    className={`modal__gallery__centering ${
                      imageList.length > 1
                        ? "justify-content-center"
                        : "modal__gallery__centering__single"
                    }`}
                  >
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                        }}
                        className="imageWrapper ms-2"
                      >
                        <div className="create__delete__img__icon__container ">
                          <AiFillDelete
                            style={{
                              position: "absolute",
                              right: "5px",
                              display: "block",
                              top: "5px",
                              zIndex: "9999",
                              cursor: "pointer",

                              opacity: "1",
                            }}
                            className="deleteIcon"
                            onClick={() => onImageRemove(index)}
                          />
                        </div>
                        <div
                          className=" tableImage  details"
                          style={{
                            backgroundImage: `url(${image.data_url})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        />
                      </div>
                      // </div>
                    ))}
                  </div>
                </>
              )}
            </ImageUploading>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default ImagesUpload;
