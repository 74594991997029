import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import "../projectcontent.css";
import "./dataexport.css";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import {
  formatDate3,
  formatDate4,
  formatDate5,
  formatDate6,
} from "../../../utils/DateFormater";
import * as XLSX from "xlsx";
import axios from "axios";
import DatePicker from "react-date-picker";
import { API_URL as API } from "../../../services/API";
import { useInfiniteQuery } from "@tanstack/react-query";

import ModalLoader from "../../LoadingError/ModalLoader";
import { queryClient } from "../../../App";
import { useTranslation } from "react-i18next";
import { AiOutlineCalendar } from "react-icons/ai";

const DataExportModal = ({ user, project, closeDataExportModal }) => {
  // Translation

  const { t } = useTranslation();

  const [observationsData, setObservationsData] = useState([]);
  const [patrolsData, setPatrolsData] = useState([]);
  const [totalObs, setTotalObs] = useState();
  const [totalPatrols, setTotalPatrols] = useState();
  const [totalImages, setTotalImages] = useState();
  const [includePatrols, setIncludePatrols] = useState();
  const [includeImages, setIncludeImages] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [showIncludePatrols, setShowIncludePatrols] = useState(null);
  const todaysDate = formatDate6(new Date());
  const [projectStartDate, setProjectStartDate] = useState();
  const [toggleLoader, setToggleLoader] = useState(false);

  const LIMIT = 3500;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["obs-to-export"],
    ({ pageParam = 1 }) => fetchData(pageParam),
    {
      enabled: false, // to stop query to automatically fetch on mount
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.observations.length === LIMIT
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
    }
  );

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      !isFetching && fetchNextPage();
    }
  }, [data, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (
      data?.pages.length &&
      !hasNextPage &&
      !isFetchingNextPage &&
      !isFetching
    ) {
      // Once there is nothing more to fetch, combine all pages and update the state. updating the state will write to excel.

      const allPagesObservations = [];

      data?.pages?.forEach((page) => {
        allPagesObservations?.push(...page?.observations);
      });

      setObservationsData(allPagesObservations);
      setPatrolsData(data?.pages[0].patrols);
    }
  }, [data, hasNextPage, isFetching, isFetchingNextPage]);

  useEffect(() => {
    if (project) {
      setProjectStartDate(formatDate6(new Date(project?.date?.date)));
    }
  }, [project]);

  useEffect(() => {
    setSelectedStartDate(projectStartDate);
    setSelectedEndDate(todaysDate);
  }, [todaysDate, projectStartDate]);

  useEffect(() => {
    const fetchDataCounts = async (projectId, startDate, endDate) => {
      const startDateTime = startDate + " 00:00:00"; // date format "yyyy-mm-dd hh:mm:ss" requested by backend.
      const endDateTime = endDate + " 23:59:00"; // Since we are going by date range, time should not matter.

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      try {
        setToggleLoader(true);
        const response = await axios.get(
          API +
            `api/observation/countData?project_id=${projectId}&start_date=${startDateTime}&end_date=${endDateTime}`,
          config
        );

        const dataCounts = response.data;

        setTotalObs(dataCounts.totalObservations);
        setTotalPatrols(dataCounts.totalPatrols);
        setTotalImages(dataCounts.totalImages);
        setToggleLoader(false);
      } catch (err) {
        setToggleLoader(false);
        console.log(err, "Data Export fetching data counts error");
      }
    };

    fetchDataCounts(project.id, selectedStartDate, selectedEndDate);
  }, [selectedStartDate, selectedEndDate, project, user, projectStartDate]);

  useEffect(() => {
    totalPatrols ? setShowIncludePatrols(true) : setShowIncludePatrols(false);
  }, [totalPatrols]);

  useEffect(() => {
    totalPatrols ? setIncludePatrols(true) : setIncludePatrols(false);
  }, [totalPatrols]);

  useEffect(() => {
    if (observationsData?.length || patrolsData?.length) {
      exportExcelFile();

      closeDataExportModal();
    }
  }, [observationsData, patrolsData]); // Need to put data in obsData & patsData once all pages are fetched, any changes in them will write to excel.

  useEffect(() => {
    return () => {
      setObservationsData([]);
      setPatrolsData([]);
      queryClient.setQueryData(["obs-to-export"], (data) => ({
        pages: [],
        pageParams: [],
      }));
      setTotalObs();
      setTotalImages();
      setTotalPatrols();
    };
  }, []);
  const fixObservationsData = (data) => {
    const content = [];
    data?.observations?.forEach((obs) => {
      const dateValue = obs.date.date;
      obs.date = dateValue; // to display the nested date value in the excel sheet.

      if (obs["images"]) {
        const links = [];
        for (const img in obs["images"]) {
          if (obs["images"][img] !== "NULL") {
            links.push(obs["images"][img]);
          }
        }

        const allLinks = links.length ? links.join("; ") : null;

        obs["images"] = allLinks; // to display the nested img links in the excel sheet.
      }

      // Format the date field
      const obsDateString = new Date(obs.date);
      const obsDate = formatDate5(obsDateString);
      obs.date = obsDate;

      // creating a time field in each obs from the date object
      obs.time = obsDateString.toLocaleTimeString();

      // change the value of dead from figures to word for better users understanding
      if (obs.dead === 0) {
        obs.dead = "Alive";
      } else {
        obs.dead = "Dead";
      }

      const arrangedKeysObs = {
        id: obs.id,
        projectName: obs.projectName,
        TypeObservation: obs.TypeObservation,
        nest_id: obs.nest_id === -1 ? "" : obs.nest_id, // Check if nest_id is -1 and return an empty string if it is
        date: obs.date,
        time: obs.time,
        coordX: obs.coordX,
        coordY: obs.coordY,
        group: obs.group,
        family: obs.family,
        specie: obs.specie,
        user: obs.user,
        collector: obs.collector,
        note: obs.note,
        idInaturalist: obs.idInaturalist,
        alpha: obs.alpha,
        dead: obs.dead,
        segment: obs.segment,
        site: obs.site,
        images: obs.images,
      };
      // obs.questionsAnwsers.forEach((qa) => {
      //   arrangedKeysObs[`${qa.question_title}`] = qa.answers;
      // });

      obs.questionsAnwsers.forEach((qa) => {
        if (
          ![
            320,
            // 11, 16, 17, 22, 23, 35, 82, 83, 84, 298, 299, 300, 301, 302, 303,
            // 313, 314, 315,
          ].includes(qa.question.id)
        ) {
          arrangedKeysObs[`${qa.question.title}`] = qa?.content;
        }
      });

      // obs.questionsAnwsers.forEach((qa) => {
      //   if (
      //     qa.question_title !== "Site" &&
      //     qa.question_title !== "Segment" &&
      //     qa.question_title !== "Notes"
      //   ) {
      //     arrangedKeysObs[`${qa.question_title}`] = qa.answers;
      //   }
      // });
      content.push(arrangedKeysObs);
    });

    data.observations = content; // modifying data obs with the new obs for each chunk.
  };

  const fixPatrolsData = (data) => {
    // formating date and time for patrols
    data.patrols.forEach((patrol) => {
      const date = patrol.date.date;
      const startTimeOjct = new Date(patrol.startTime.date);
      const endTimeObjt = new Date(patrol.endTime.date);
      const patrolStartDate = formatDate5(new Date(date));
      const startTime = startTimeOjct.toLocaleTimeString();
      const endTime = endTimeObjt.toLocaleTimeString();

      patrol.date = patrolStartDate;
      patrol.startTime = startTime;
      patrol.endTime = endTime;
    });
  };

  const fetchData = async (pageNum) => {
    const startDateTime = selectedStartDate + " 00:00:00"; // date format "yyyy-mm-dd hh:mm:ss" requested by backend.
    const endDateTime = selectedEndDate + " 23:59:59"; // Since we are going by date range, time should not matter.

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    try {
      setToggleLoader(true);

      const response = await axios.get(
        API +
          `api/observation/exportByPage?project_id=${project.id}&start_date=${startDateTime}&end_date=${endDateTime}&include_image=${includeImages}&include_patrol=${includePatrols}&page_num=${pageNum}&per_page=${LIMIT}`,
        config
      );
      const data = response.data;

      if (data.observations?.length) {
        fixObservationsData(data);
      }
      if (data.patrols?.length) {
        fixPatrolsData(data);
      }
      setToggleLoader(false);

      return data;
    } catch (err) {
      setToggleLoader(false);
      console.log(err, "Data Export fetching observations error");
    }
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSelectedStartDate = debounce((newDate) => {
    const formattedDate = formatDate5(newDate);

    setSelectedStartDate(formattedDate);
  }, 300); // 1000 milliseconds = 1 second

  const handleSelectedEndDate = debounce((newDate) => {
    const formattedDate = formatDate5(newDate);
    setSelectedEndDate(formattedDate);
  }, 300);

  const handleIncludePatrolsChange = (checked, e) => {
    checked ? setIncludePatrols(true) : setIncludePatrols(false);
  };

  const handleIncludeImagesChange = (checked, e) => {
    checked ? setIncludeImages(true) : setIncludeImages(false);
  };

  const exportExcelFile = () => {
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(observationsData);

    let ws2;
    if (includePatrols && patrolsData.length) {
      ws2 = XLSX.utils.json_to_sheet(patrolsData);
    }

    XLSX.utils.book_append_sheet(wb, ws1, "observations");

    if (includePatrols && patrolsData.length) {
      XLSX.utils.book_append_sheet(wb, ws2, "patrols");
    }

    const u8 = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    const blob = new Blob([u8]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = "observations.xlsx";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  const handleExport = () => {
    // fetch the actual data to export to excel.
    refetch(); // to mannually triggering the query to fetch when export button is clicked.
  };

  return (
    <>
      <Modal
        show={true}
        onHide={closeDataExportModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals view_modal"
        keyboard={false}
        size="lg"
        style={{
          borderTopLeftRadius: "20px",
        }}
        dialogClassName="modal-size"
        contentClassName="export-modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {toggleLoader && <ModalLoader />}
        <Modal.Header
          className="bgPrimary"
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title export-modal-header">
            {t("admin.exportData.exportDataText")}
          </Modal.Title>

          <div className="">
            <span className=" pointer" onClick={closeDataExportModal}>
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        <form>
          <Modal.Body>
            <div className="d-flex export-modal-title">
              <p className="project-name-text">{project.name}</p>
              <span className="obs-total-text">({totalObs} observations)</span>
            </div>
            <div className="export-modal-body">
              <div className="export-modal-body-parts input">
                <label htmlFor="start-date">
                  {" "}
                  {t("admin.exportData.startDate")}
                </label>
                {/* <input
                  type="date"
                  id="start-date"
                  name="startDate"
                  value={selectedStartDate} // "yyyy-mm-dd"
                  className="export-date-input"
                  onChange={(e) => handleSelectedStartDate(e.target.value)}
                  // min={projectStartDate}
                  max={todaysDate}
                  required
                /> */}
                <DatePicker
                  calendarIcon={
                    <AiOutlineCalendar style={{ color: "#073b60" }} />
                  }
                  clearIcon={null}
                  onChange={handleSelectedStartDate}
                  // calendarClassName="calender__picker"
                  className={`calender__picker_export`}
                  id="start-date"
                  value={selectedStartDate}
                  required={true}
                  format={"y/MM/dd"}
                />
              </div>
              <div className="export-modal-body-parts input">
                <label htmlFor="end-date">
                  {" "}
                  {t("admin.exportData.endDate")}
                </label>
                {/* <input
                  type="date"
                  id="end-date"
                  name="endDate"
                  value={selectedEndDate}
                  className="export-date-input"
                  onChange={(e) => handleSelectedEndDate(e.target.value)}
                  min={selectedStartDate} // selectedStartDate is the minimum here to always select a date a head.
                  max={todaysDate}
                  required
                /> */}
                <DatePicker
                  calendarIcon={
                    <AiOutlineCalendar style={{ color: "#073b60" }} />
                  }
                  clearIcon={null}
                  onChange={handleSelectedEndDate}
                  // calendarClassName="calender__picker"
                  className={`calender__picker_export`}
                  id="start-date"
                  value={selectedEndDate}
                  // maxDate={todaysDate}
                  required={true}
                  format={"y/MM/dd"}
                />
              </div>
              {showIncludePatrols && (
                <div className="export-modal-body-parts">
                  <p>
                    {t("admin.exportData.includePatrol")}
                    <span className="pats-imgs-total-text">
                      ({totalPatrols} {t("admin.exportData.patrols")})
                    </span>
                  </p>
                  <div className="include-toggles">
                    <span>{includePatrols ? "Yes" : "No"}</span>
                    <Switch
                      checked={includePatrols}
                      size="small"
                      onChange={handleIncludePatrolsChange}
                    />
                  </div>
                </div>
              )}
              <div className="export-modal-body-parts">
                <p>
                  {" "}
                  <span className="pats-imgs-total-text">
                    ({totalImages} images)
                  </span>
                </p>
                <div className="include-toggles">
                  <span>{includeImages ? "Yes" : "No"}</span>
                  <Switch
                    size="small"
                    onChange={handleIncludeImagesChange}
                    checked={includeImages}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="export-modal-footer">
              <button
                type="button"
                onClick={() => {
                  handleExport();
                }}
                className="login-btn export-modal-btn"
              >
                {t("admin.exportData.export")}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DataExportModal;
