import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { setChangePass } from "../../services/rxjs";
import { API_URL as API } from "../../services/API";

const PasswordModal = ({ showMod, setShowMod }) => {
  const [showModal, setShowModal] = useState(showMod);
  const [show, setShow] = useState("false");
  const [showc, setShowc] = useState("false");
  const [shown, setShown] = useState("false");
  const [showcn, setShowcn] = useState("false");
  const [errorPass, setErrorPass] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const handleShowc = () => {
    setShowc(!showc);
  };

  const handleShown = () => {
    setShown(!shown);
  };

  const handleShowcn = () => {
    setShowcn(!showcn);
  };

  const handleHideModal = (change) => {
    setShowMod(change);
    setShowModal(false);
    setErrorPass(false);
  };

  const onSubmit = (data) => {
    setErrorPass(false);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    axios
      .post(
        API + "api/userPassword/update",
        {
          current_password: data.actual,
          new_password: data.password,
        },
        config
      )
      .then(function (response) {
        if (response.data.message === "Incorrect current password")
          setErrorPass(true);
        else {
          setChangePass(true);
          handleHideModal(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const validationSchema = Yup.object().shape({
    actual: Yup.string().required("This field is required"),
    // actual: Yup.string().required("Must be at least 8 characters"),
    password: Yup.string().min(8).required("Must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Password don't match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <Modal
      show={showMod}
      onHide={handleHideModal}
      backdrop="static"
      centered
      className="mx-auto"
      keyboard={false}
      size="lg"
      dialogClassName="modal-size"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className="bg-cl-bl text-left " style={{ color: "white" }}>
        <Modal.Title className="w-100 text-left">Change password?</Modal.Title>

        <div className="legend-close text-end">
          <span
            className="pe-sm-2 m-2 pointer d-block mt-3"
            onClick={() => {
              handleHideModal(false);
            }}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "15px" }}
            />
          </span>
        </div>
      </Modal.Header>

      {true ? (
        <>
          <Modal.Body>
            {/* <div className="infochangepass pt-2 pb-2 ps-5 pe-5 mb-3">
                Check your email for the instructions to reset your password
              </div> */}

            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="pe-5 ps-5">
                <div className="position-relative h90">
                  <span className="d-block ">Current password.</span>

                  <input
                    type={showc ? "password" : "text"}
                    name="cpassword"
                    // onChange={handleChange}
                    placeholder="Enter password"
                    {...register("actual")}
                    className={`w-100 rounded password form-control `}
                  />
                  <p className="error">{errors.actual?.message}</p>
                  <span className="forgotpass ">
                    <Link
                      className="lien noDeco"
                      to={{ pathname: "/forgottenPassword", state: "profil" }}
                    >
                      Forgot Password?
                    </Link>
                  </span>
                  {errorPass && !errors.actual ? (
                    <p className="error poserr">Wrong password</p>
                  ) : null}
                  <label onClick={handleShowc}>
                    {showc ? (
                      <img
                        src="assets/elements/images/icon/eyesc.svg"
                        alt=""
                        className="d-inline-block eye-close eclose pointer"
                      />
                    ) : (
                      <img
                        src="assets/elements/images/icon/eyes.svg"
                        alt=""
                        className="d-inline-block eye-close pointer"
                      />
                    )}
                  </label>
                </div>
                <div className="position-relative h90">
                  <span className="d-block ">New password</span>

                  <input
                    type={shown ? "password" : "text"}
                    name="npassword"
                    placeholder="Enter password"
                    {...register("password")}
                    className={`w-100 rounded password form-control `}
                  />
                  <p className="error">{errors.password?.message}</p>
                  <label onClick={handleShown}>
                    {shown ? (
                      <img
                        src="assets/elements/images/icon/eyesc.svg"
                        alt=""
                        className="d-inline-block eye-close eclose pointer"
                      />
                    ) : (
                      <img
                        src="assets/elements/images/icon/eyes.svg"
                        alt=""
                        className="d-inline-block eye-close pointer"
                      />
                    )}
                  </label>
                </div>
                <div className="position-relative h90">
                  <span className="d-block ">Confirm New password</span>

                  <input
                    type={showcn ? "password" : "text"}
                    name="cnpassword"
                    placeholder="Re-enter new password"
                    {...register("confirmPassword")}
                    className={`w-100 rounded password form-control`}
                  />
                  <p className="error">{errors.confirmPassword?.message}</p>
                  <label onClick={handleShowcn}>
                    {showcn ? (
                      <img
                        src="assets/elements/images/icon/eyesc.svg"
                        alt=""
                        className="d-inline-block eye-close eclose pointer"
                      />
                    ) : (
                      <img
                        src="assets/elements/images/icon/eyes.svg"
                        alt=""
                        className="d-inline-block eye-close pointer"
                      />
                    )}
                  </label>
                </div>
              </div>
              <div
                className="d-flex justify-content-end modal-footer pe-5 ps-5"
                style={{
                  width: "100%",
                }}
              >
                {/* <div
                        className="btn btn-lg validate1 px-5 me-3"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </div> */}
                <button
                  // className="btn btn-lg  px-3 btn-validate1 mx-1"
                  className="btn btn-lg px-3 login-btn btn-validate1 mx-1"
                  onClick={() => {
                    handleHideModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={!isDirty || !isValid}
                  type="submit"
                  // className="btn btn-lg  px-3 btn-validate2 mx-1"
                  // className="btn btn-lg px-3 btn-validate2"
                  className="btn btn-lg px-3 login-btn admin-btn-dark"
                  // onClick={() => {
                  //   handleHideModal();
                  // }}
                >
                  Change
                </button>
              </div>
            </form>
          </Modal.Body>
        </>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default PasswordModal;
