// Fetch Top contributors

import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../services/API";
import { useQuery } from "@tanstack/react-query";

export const useFetchVIPTopContributors = ({ dates }) => {
  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["VIPtopContributors", dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/topContributors_vip?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    // document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates]);

  return {
    topContributor: data?.topContributors,
    topContriError: error,
    topContriLoading: isLoading,
    refechTopContributors: refetch,
  };
};

// Fetch Active contributors

export const useFetchVIPActiveContributors = ({ dates }) => {
  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["VIPactiveContributors", dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/totalActiveContributors_vip
?id=${localStorage.getItem("vid_projectId")}&start_date=${dates[0]}&end_date=${
              dates[1]
            }`
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    //   document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates]);

  return {
    activeContributor: data?.totalActiveContributors,
    activeContriError: error,
    activeContriLoading: isLoading,
    refechActiveContributors: refetch,
  };
};

// Total Patrol Time

export const useFetchVIPTotalPatrolTime = ({ dates }) => {
  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["totalPatrolTime", dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/totalPatrolTime_vip?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    //   document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates]);

  return {
    totalPatrolTime: data?.totalPatrolTime,
    totalPatrolTimeError: error,
    totalPatrolTimeLoading: isLoading,
    refechTotalPatrolTime: refetch,
  };
};

export const useFetchVIPTotalObservations = ({ dates }) => {
  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["totalObs", dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/total_observations_vip_dated?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    //   document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates]);

  return {
    totalObs: data,
    totalObsError: error,
    totalObsLoading: isLoading,
    refechTotalObs: refetch,
  };
};
// obsByGroup

export const useFetchVIPObsByGroup = ({ dates }) => {
  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["obsStatsByGroup", dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/obs_stats_vip_by_group?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    //   document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates]);

  return {
    obsByGroup: data,
    obsByGroupError: error,
    obsByGroupLoading: isLoading,
    refechObsByGroup: refetch,
  };
};

// top species

export const useFetchVIPTopSpecies = ({ dates, localProjectId }) => {
  const [topSpecies, setTopSpecies] = useState(null);
  const [topSpeciesError, setTopSpeciesError] = useState(null);
  const [topSpeciesLoading, setTopSpeciesLoading] = useState(false);

  useEffect(() => {
    const fetchTopSpecies = async () => {
      try {
        setTopSpeciesLoading(true);
        const response = await axios.get(
          API_URL +
            `api/dashboard/top_vip_species?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        );
        setTopSpecies(response.data.topSpecies);
        setTopSpeciesLoading(false);
      } catch (error) {
        setTopSpeciesError(error);
        setTopSpeciesLoading(false);
      }
    };

    fetchTopSpecies();
  }, [dates, localProjectId]);

  return {
    topSpecies,
    topSpeciesError,
    topSpeciesLoading,
  };
};

// top species

export const useFetchVIPObsByMonth = ({ dates }) => {
  const [obsStatsByMonth, setObsStatsByMonth] = useState(null);
  const [obsStatsByMonthError, setObsStatsByMonthError] = useState(null);
  const [obsStatsByMonthLoading, setObsStatsByMonthLoading] = useState(false);

  useEffect(() => {
    const fetchObsByMonth = async () => {
      setObsStatsByMonth(null);

      try {
        setObsStatsByMonthLoading(true);
        const response = await axios.get(
          API_URL +
            `api/dashboard/obs_stats_vip_by_month?id=${localStorage.getItem(
              "vid_projectId"
            )}&start_date=${dates[0]}&end_date=${dates[1]}`
        );
        setObsStatsByMonth(response.data);
        setObsStatsByMonthLoading(false);
      } catch (error) {
        setObsStatsByMonthError(error);
        setObsStatsByMonthLoading(false);
      }
    };

    fetchObsByMonth();
  }, [dates]);

  return {
    obsStatsByMonth,
    obsStatsByMonthError,
    obsStatsByMonthLoading,
  };
};
