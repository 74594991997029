import React from "react";
import SignUpBtn from "../SignUpBtn";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function LandingCarousel() {
  const { t } = useTranslation();
  return (
    <section>
      <div
        id="LandingCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-pause={false}
      >
        <div className="carousel-indicators Landing-indicators">
          <button
            type="button"
            data-bs-target="#LandingCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#LandingCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#LandingCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div
            className="carousel-item active relative"
            data-bs-interval="4000"
          >
            <img
              src="assets/images/landingPageC1.png"
              className="d-block w-100 LandingCarouselImg"
              alt="..."
            />
            <div className="LandingCarouselItemBody">
              <h2 className="LandingCarouselItemTitle">
                {t("landingPage.carousel.card1.topic1")} <br />{" "}
                {t("landingPage.carousel.card1.topic2")}
              </h2>
              <div className="LandingCarouselItemText">
                {t("landingPage.carousel.card1.content1")}
                <br /> {t("landingPage.carousel.card1.content2")}
              </div>
              <SignUpBtn />
            </div>
          </div>
          <div className="carousel-item relative" data-bs-interval="4000">
            <img
              src="assets/images/landingPageC2.png"
              className="d-block w-100 LandingCarouselImg"
              alt="..."
            />
            <div className="LandingCarouselItemBody">
              <h2 className="LandingCarouselItemTitle">
                {t("landingPage.carousel.card2.topic1")} <br />
                {t("landingPage.carousel.card2.topic2")}
              </h2>
              <div className="LandingCarouselItemText">
                {t("landingPage.carousel.card2.content1")} <br />{" "}
                {t("landingPage.carousel.card2.content2")}
              </div>
              <SignUpBtn />
            </div>
          </div>
          <div className="carousel-item relative" data-bs-interval="4000">
            <img
              src="assets/images/landingPageC3.png"
              className="d-block w-100 LandingCarouselImg"
              alt="..."
            />
            <div className="LandingCarouselItemBody">
              <h2 className="LandingCarouselItemTitle">
                {t("landingPage.carousel.card3.topic1")}
                <br /> {t("landingPage.carousel.card3.topic2")}
              </h2>
              <div className="LandingCarouselItemText">
                {t("landingPage.carousel.card3.content1")}
                <br /> {t("landingPage.carousel.card3.content2")}
              </div>
              <SignUpBtn />
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#LandingCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#LandingCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
}
