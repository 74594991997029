import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL as API } from "../services/API";
import { logout } from "../actions/userActions";
import { useFetchProjectList } from "./useMapQueriesHook";
import { refetchObs } from "../actions/projectActions";

export const useFetchPatrolList = ({ id, enabled = true }) => {
  const { projectList } = useFetchProjectList();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 1000;

  useMemo(() => {
    setPage(id);
  }, [id]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;

  const fetchPatrolsList = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      API +
        `api/patrol/list?numPage=${page}&projectId=${localProjectId}&itemsPerPage=${LIMIT}`,
      config
    );
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    failureReason,
    isRefetching,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["patrolList", id],
    ({ pageParam = 1 }) => fetchPatrolsList(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchPatrolsList(pageParam),
      enabled, // Control whether the query runs based on the enabled flag
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;
        return nextPage;
      },
      queryKey: id,
      // refetchOnMount: true,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [localProjectId, refetch, projectList, enabled]);

  return {
    patrolData: data,
    patrolListLoading: isLoading,
    patrolListError: isError,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingPatrol: isRefetching,
    queryRefetchPatrolList: refetch,
    isSuccess,
    hasNextPage,
  };
};

export const useFetchPatrolValidObs = ({ id }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 2500;
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const fetchPatrolsValidObs = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      API +
        `api/Patrol/observationValid?patrolId=${id}&numPage=${page}&projectId=${localProjectId}&itemsPerPage=${2500}`,
      config
    );
    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    failureReason,
    isRefetching,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["patrolValidObs"],
    ({ pageParam = 1 }) => fetchPatrolsValidObs(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchPatrolsValidObs(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      // refetchOnMount: true,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }
  useEffect(() => {
    refetch();
  }, [localProjectId, refetch]);

  return {
    patrolValidObs: data,
    patrolValidObsLoading: isLoading,
    patrolValidError: isError,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingPatrolValidObs: isRefetching,
    queryRefetchPatrolValidObs: refetch,
    isSuccess,
    hasNextPage,
  };
};
export const useFetchPatrolUnValidObs = ({ id }) => {
  const refetchObsList = useSelector((state) => state.setRefetchObsList);

  const { refetchObsValue } = refetchObsList;
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 2500;
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const fetchPatrolsUnValidObs = async (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      API +
        `api/Patrol/observationUnvalid?patrolId=${id}&numPage=${page}&projectId=${localProjectId}&itemsPerPage=${2500}`,
      config
    );
    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    failureReason,
    isRefetching,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["patrolUnValidObs"],
    ({ pageParam = 1 }) => fetchPatrolsUnValidObs(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchPatrolsUnValidObs(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      // refetchOnMount: true,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [localProjectId, refetch]);
  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch]);

  return {
    patrolUnValidObs: data,

    patrolUnValidObsLoading: isLoading,
    patrolUnValidError: isError,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingPatrolUnValidObs: isRefetching,
    queryRefetchPatrolUnValidObs: refetch,
    isSuccess,
    hasNextPage,
  };
};
