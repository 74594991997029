import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_OBSERVATION_REQUEST,
  PROJECT_OBSERVATION_SUCCESS,
  PROJECT_OBSERVATION_FAILD,
  CREATE_OBSERVATION_REQUEST,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_FAILD,
  UPDATE_OBSERVATION_REQUEST,
  UPDATE_OBSERVATION_FAILD,
  UPDATE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_REQUEST,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_FAILD,
  UNVALIDATE_OBSERVATION_LIST_REQUEST,
  UNVALIDATE_OBSERVATION_LIST_SUCCESS,
  UNVALIDATE_OBSERVATION_LIST_FAILD,
  VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST,
  VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS,
  VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD,
  DELETE_LIST_OF_OBSERVATION_REQUEST,
  DELETE_LIST_OF_OBSERVATION_SUCCESS,
  DELETE_LIST_OF_OBSERVATION_FAILD,
  DELETE_OBSERVATION_IMAGE_REQUEST,
  DELETE_OBSERVATION_IMAGE_SUCCESS,
  DELETE_OBSERVATION_IMAGE_FAILD,
  SET_DATA,
  SET_USER,
  CHANGE_MARKER,
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  REFETCH_OBS_LIST,
  SET_USER_ROLE_ON_PROJECT,
  PROJECT_OBSERVATION_MAP_REQUEST,
  PROJECT_OBSERVATION_MAP_SUCCESS,
  PROJECT_OBSERVATION_MAP_FAILD,
  UPDATE_OBSERVATION_LOCALY,
  DELETE_OBS_LOCALLY,
  ValIDATE_OBS_LOCALLY,
  CREATE_OBS_AND_STORE_LOCALLY,
  NEW_OBS_ID,
  MAP_ZOOM_LEVEL,
  OBSERVATIONS_TYPES,
  SINGLE_OBSERVATION,
  PAN_TRAKER,
  OPEN_POPUP,
  DATE_RANGE,
  SITE_SEGMENT,
  EDIT_SITE_SEGMENT,
  UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST,
  UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS,
  UPLOAD_OBSERVATION_TO_INATURALIST_FAILD,
  UPLOAD_OBS_PROGRESS,
  ADD_QUESTION_DATA,
  DELETE_QUESTION,
  // SITE_SEGMENT_COMPONENT_LIST,
} from "../constants/projectConstants";

export const projectListReducer = (
  state = { isLoading: true, projects: [] },
  action
) => {
  switch (action.type) {
    case PROJECT_LIST_REQUEST:
      return { isLoading: true, projects: [] };
    case PROJECT_LIST_SUCCESS:
      return {
        isLoading: false,
        projects: action.payload,
      };
    case PROJECT_LIST_FAIL:
      return { isLoading: false, isError: action.payload };
    default:
      return state;
  }
};
export const projectObservationReducer = (
  state = { loading: true, projectsObs: [] },
  action
) => {
  switch (action.type) {
    case PROJECT_OBSERVATION_REQUEST:
      return { loading: true, projectsObs: [], error: null };
    case PROJECT_OBSERVATION_SUCCESS:
      return {
        loading: false,
        projectsObs: action.payload,
        error: null,
      };
    case PROJECT_OBSERVATION_FAILD:
      return { loading: false, error: action.payload, projectsObs: [] };

    default:
      return state;
  }
};
export const projectObservationMapReducer = (
  state = { fetchObsloading: true, Obslist: [], obsError: "" },
  action
) => {
  switch (action.type) {
    case PROJECT_OBSERVATION_MAP_REQUEST:
      return { fetchObsloading: true, Obslist: [], obsError: null };
    case PROJECT_OBSERVATION_MAP_SUCCESS:
      return {
        fetchObsloading: false,
        Obslist: action.payload,
        obsError: null,
      };
    case PROJECT_OBSERVATION_MAP_FAILD:
      return { fetchObsloading: false, Obslist: [], obsError: action.payload };

    default:
      return state;
  }
};
export const unvalidatedProjectObservationReducer = (
  state = { loading: true, projectsObs: [] },
  action
) => {
  switch (action.type) {
    case UNVALIDATE_OBSERVATION_LIST_REQUEST:
      return { loading: true, projectsObs: [], error: null };
    case UNVALIDATE_OBSERVATION_LIST_SUCCESS:
      return {
        loading: false,
        projectsObs: action.payload,
        error: null,
      };
    case UNVALIDATE_OBSERVATION_LIST_FAILD:
      return { loading: false, error: action.payload, projectsObs: [] };
    default:
      return state;
  }
};

// create project observation
export const createObservationReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_OBSERVATION_REQUEST:
      return { obsCreateLoading: true, obsCreateSuccess: false };
    case CREATE_OBSERVATION_SUCCESS:
      return {
        obsCreateLoading: false,
        obsCreateSuccess: true,
        createdObs: action.payload,
      };
    case CREATE_OBSERVATION_FAILD:
      return {
        obsCreateLoading: false,
        obsCreateError: action.payload,
        obsCreateSuccess: false,
      };
    default:
      return state;
  }
};

// edit/ update project observation
export const editObservationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_OBSERVATION_REQUEST:
      return { updateLoading: true };
    case UPDATE_OBSERVATION_SUCCESS:
      return {
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload,
      };
    case UPDATE_OBSERVATION_FAILD:
      return { updateLoading: false, updateError: action.payload };
    default:
      return state;
  }
};

// delete  project observation
export const deleteObservationReducer = (
  state = {
    deleteLoading: false,
    deleteSucces: false,
    deleteMessage: "",
    deleteError: "",
  },
  action
) => {
  switch (action.type) {
    case DELETE_OBSERVATION_REQUEST:
      return { deleteLoading: true };
    case DELETE_OBSERVATION_SUCCESS:
      return {
        deleteLoading: false,
        deleteSucces: true,
        deleteMessage: action.payload,
      };
    case DELETE_OBSERVATION_FAILD:
      return { deleteLoading: false, deleteError: action.payload };
    default:
      return state;
  }
};

export const validateUnValidatedObservationsReducer = (
  state = {
    validateLoading: false,
    validateSuccess: false,
    validateMessage: "",
    validateError: "",
  },
  action
) => {
  switch (action.type) {
    case VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST:
      return { validateLoading: true };
    case VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS:
      return {
        validateLoading: false,
        validateSuccess: true,
        validateMessage: action.payload,
      };
    case VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD:
      return { validateLoading: false, validateError: action.payload };
    default:
      return state;
  }
};
export const deleteListOfObservationsRducer = (
  state = {
    deleteListLoading: false,
    deleteListSuccess: false,
    deleteListMessage: "",
    deleteListError: "",
  },
  action
) => {
  switch (action.type) {
    case DELETE_LIST_OF_OBSERVATION_REQUEST:
      return { deleteListLoading: true };
    case DELETE_LIST_OF_OBSERVATION_SUCCESS:
      return {
        deleteListLoading: false,
        deleteListSuccess: true,
        deleteListMessage: action.payload,
      };
    case DELETE_LIST_OF_OBSERVATION_FAILD:
      return { deleteListLoading: false, deleteListError: action.payload };
    default:
      return state;
  }
};

// Upload obs to iNaturalist reducer
export const uploadObsToInaturalistRducer = (
  state = {
    uploadInaturalistLoading: false,
    uploadInaturalistSuccess: false,
    uploadInaturalistMessage: "",
    uploadInaturalistError: "",
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST:
      return { uploadInaturalistLoading: true };
    case UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS:
      return {
        uploadInaturalistLoading: false,
        uploadInaturalistSuccess: true,
        uploadInaturalistMessage: action.payload,
      };
    case UPLOAD_OBSERVATION_TO_INATURALIST_FAILD:
      return {
        uploadInaturalistLoading: false,
        uploadInaturalistError: action.payload,
      };
    default:
      return state;
  }
};

export const deleteSigleImageRducer = (
  state = {
    deleteImageLoading: false,
    deleteImageSuccess: false,
    deleteImageMessage: "",
    deleteImageError: "",
  },
  action
) => {
  switch (action.type) {
    case DELETE_OBSERVATION_IMAGE_REQUEST:
      return { deleteImageLoading: true };
    case DELETE_OBSERVATION_IMAGE_SUCCESS:
      return {
        deleteImageLoading: false,
        deleteImageSuccess: true,
        deleteImageMessage: action.payload,
      };
    case DELETE_OBSERVATION_IMAGE_FAILD:
      return { deleteImageLoading: false, deleteImageError: action.payload };
    default:
      return state;
  }
};

export const ProjectIdreducer = (state = { localProjectId: null }, action) => {
  switch (action.type) {
    case SET_DATA:
      return { localProjectId: action.payload };
    default:
      return state;
  }
};

export const setUserReducer = (state = { userUpdate: false }, action) => {
  switch (action.type) {
    case SET_USER:
      return { userUpdate: action.payload };
    default:
      return state;
  }
};

// change marker

export const changeMarkerReducer = (
  state = { markerDetails: { id: "", index: "" } },
  action
) => {
  switch (action.type) {
    case CHANGE_MARKER:
      return { markerDetails: action.payload };
    default:
      return state;
  }
};
// opn popup marker

export const openPopupReducer = (
  state = { popupdetails: { id: "", index: "" } },
  action
) => {
  switch (action.type) {
    case OPEN_POPUP:
      return { popupdetails: action.payload };
    default:
      return state;
  }
};

// success notifications

export const successNotifReducer = (
  state = {
    createObsSuccessNotif: false,
    updateObsSuccessNotif: false,
    uploadSuccessNotif: false,
    deleteObsSuccessNotif: false,
    deleteObsImageNotif: false,
    validateObsSuccessNotif: false,
  },
  action
) => {
  switch (action.type) {
    case SUCCESS_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
};

export const errorNotifReducer = (
  state = {
    createObsErrorNotif: false,
    updateObsErrorNotif: false,
    deleteObsErrorNotif: false,
    uploadObsEror: false,
    validateObsErrorNotif: false,
  },
  action
) => {
  switch (action.type) {
    case ERROR_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
};
export const refetchObsReducer = (
  state = {
    refetchObsValue: false,
  },
  action
) => {
  switch (action.type) {
    case REFETCH_OBS_LIST:
      return action.payload;
    default:
      return state;
  }
};
export const userProjectRoleReducer = (
  state = {
    role: false,
  },
  action
) => {
  switch (action.type) {
    case SET_USER_ROLE_ON_PROJECT:
      return action.payload;
    default:
      return state;
  }
};

// Update project locally
export const updateObservationLocallyReducer = (
  state = { updtedObservationData: null },
  action
) => {
  switch (action.type) {
    case UPDATE_OBSERVATION_LOCALY:
      return { updtedObservationData: action.payload };
    default:
      return state;
  }
};

// create observation and store locally
export const createObservationLocallyReducer = (
  state = {
    newObsCreated: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_OBS_AND_STORE_LOCALLY:
      return { newObsCreated: action.payload };
    default:
      return state;
  }
};

// new obs crested id
export const newObservationIdReducer = (state = { newObsId: null }, action) => {
  switch (action.type) {
    case NEW_OBS_ID:
      return { newObsId: action.payload };
    default:
      return state;
  }
};

// delete project locally
export const deleteObservationLocallyReducer = (
  state = { deleteId: [] },
  action
) => {
  switch (action.type) {
    case DELETE_OBS_LOCALLY:
      return { deleteId: action.payload };
    default:
      return state;
  }
};
// delete project locally
export const validateObservationLocallyReducer = (
  state = { valideId: [] },
  action
) => {
  switch (action.type) {
    case ValIDATE_OBS_LOCALLY:
      return { valideId: action.payload };
    default:
      return state;
  }
};

// delete project locally
export const mapZoomReducer = (
  state = {
    zoomValue: false,
  },
  action
) => {
  switch (action.type) {
    case MAP_ZOOM_LEVEL:
      return action.payload;
    default:
      return state;
  }
};

//  fetch observations  by observation types.
export const observationTypesReducer = (
  state = {
    observationTypesListFromReducer: [0],
  },
  action
) => {
  switch (action.type) {
    case OBSERVATIONS_TYPES:
      return action.payload;
    default:
      return state;
  }
};
//  date ranges to filtr  observations  by dates.
export const datesRangesReducer = (
  state = {
    dateRangesArray: [0, 0],
  },
  action
) => {
  switch (action.type) {
    case DATE_RANGE:
      return action.payload;
    default:
      return state;
  }
};

//  Single observation
export const singleObservationReducer = (
  state = {
    singleObsId: null,
  },
  action
) => {
  switch (action.type) {
    case SINGLE_OBSERVATION:
      return action.payload;
    default:
      return state;
  }
};

export const panTrackerReducer = (
  state = {
    isPanning: false,
  },
  action
) => {
  switch (action.type) {
    case PAN_TRAKER:
      return action.payload;
    default:
      return state;
  }
};

export const siteSegmentReducer = (
  state = {
    siteSegmentObject: null,
  },
  action
) => {
  switch (action.type) {
    case SITE_SEGMENT:
      return action.payload;
    default:
      return state;
  }
};
export const editSiteSegmentReducer = (
  state = {
    editSiteSegmentObject: null,
  },
  action
) => {
  switch (action.type) {
    case EDIT_SITE_SEGMENT:
      return action.payload;
    default:
      return state;
  }
};
export const uploadProgressReducer = (
  state = {
    progressArray: [0, 0],
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_OBS_PROGRESS:
      return action.payload;
    default:
      return state;
  }
};

export const addQuestionDataReducer = (
  state = {
    questionData: {
      id: null,
      title: "",
      type: "",
      answers: [],
    },
  },
  action
) => {
  switch (action.type) {
    case ADD_QUESTION_DATA:
      return action.payload;
    default:
      return state;
  }
};
export const deleteQuestionReducer = (
  state = {
    questionId: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_QUESTION:
      return action.payload;
    default:
      return state;
  }
};
