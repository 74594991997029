import React from "react";
import StudyHeader from "../StudyHeader";
import Image from "react-bootstrap/Image";
import "./CaseStudyStyles.css";

function HawksbillTurtlesCaseStudy() {
  const impactImagesPath = "/assets/caseStudyImages";

  const studyHeaderData = {
    titleImg: `${impactImagesPath}/IMG-20240318-WA0003.jpg`,
    title: [
      "PROTECTING CRITICALLY ENDANGERED",
      "HAWKSBILL TURTLES",
      "IN THE MIDDLE EAST GULF",
    ],
    pdfLink:
      "Protecting-The-Critically-Endangered-Hawksbill-Turtles-In-Middle-East-Gulf.pdf",
  };

  return (
    <div>
      <StudyHeader studyData={studyHeaderData} />
      <div className="studyLayout">
        <div className="studyIntroduction">
          <p>
            The Hawksbill turtle, one of the seven existing sea turtle species,
            is a critically endangered species, with only about 8000 nesting
            females left worldwide. The Gulf of the Middle East has some of the
            most important hawksbill nesting sites, with several hundred turtles nesting annually on a single island alone.
          </p>
          <p>
            To conserve the population of the hawksbill turtles and to protect
            the biodiversity of the region, the regional government has
            established a Marine Turtle Conservation, Monitoring and Research
            Program. As part of this program, researchers collect data on the
            quality and quantity of the turtle nesting sites so they can track
            the health of the nesting colonies, observe changes across nesting
            seasons, and measure the progress of the conservation efforts.
          </p>
        </div>
        <div className="studyApproach">
          <div className="approachLeft">
            <h3 className="studyHeader">CHALLENGES</h3>
            <p>
              The government's environmental agency partners with local
              non-profit organizations based on the island to monitor the turtle
              nests during the nesting season (March to June). Daily patrols are
              performed, and research associates and volunteers record data
              about the turtles and nesting activity every day. Comprehensive
              data is recorded such as beach number, date when nest was dug, GPS
              location, female turtle dimensions, nest number, distance of the
              nest from the high tide line, temperature, date hatched, number of
              eggs hatched, signs of predation, etc. This data gave the
              researchers a very good baseline about the turtle population,
              their composition and nesting health and activity.
            </p>
            <p>
              When they started the project, the data was being recorded in
              paper forms. But they soon realized the inefficiency of using
              paper forms because:
            </p>

            <h4 className="studyBulletTag">
              1. Low lighting & Water conditions
            </h4>
            <p className="studyBullet">
              Most of the activity on nesting sites happens at night since
              nesting females generally come ashore at night, often during high
              tide. The combination of low lighting and presence of water around
              the turtles made it difficult to record data on paper.
            </p>

            <h4 className="studyBulletTag">2. Aggregating data for analysis</h4>
            <p className="studyBullet">
              Once the paper forms were collected, it was then up to the project
              manager to input the data into excel spreadsheets for analysis.
              This resulted in a large amount of time being spent by the project
              manager for data entry versus actual scientific research and
              analysis.
            </p>

            <h4 className="studyBulletTag">
              3. Reporting data across multiple nesting
            </h4>
            <p className="studyBullet">
              Spreadsheets and formulas provided valuable insights on the
              current nesting season. But without data from multiple years in
              one centralized location, it was difficult to get a multi-year
              perspective of the health of the nesting sites and population
              trends.
            </p>
          </div>
          <div className="approachRight">
            <div className="studyQuoteBlockText">
              <p>
                With Siren, researchers can see multi-year trends and answer
                questions like:
              </p>
              <ul>
                <li>How is the population changing year to year?</li>
                <li>
                  What is the average turtle size. Is it growing/shrinking?
                </li>
                <li>What is the health of the nesting sites?</li>
                <li>Are there too many females versus males?</li>
                <li>
                  How do temperature changes impact turtle nesting activity?
                </li>
                <li>Do they need to relocate nests for any reason?</li>
                <li>Are there turtles coming back to the same site?</li>
                <li>
                  Do we need more staffing to adequately patrol all the nesting
                  colonies?
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="studySolutionImpactSiren">
          <div className="studyLeft">
            <h3 className="studyHeader">THE SIREN SOLUTION</h3>
            <p>
              The agency then decided to look for solutions to digitize the
              recording of their data. They found the Siren app from the SWOT
              (State of the World's Sea Turtles) magazine and decided to use
              Siren and another third-party app to record observations, along
              with Google Maps to add points of interest.
            </p>
            <p>
              But maintaining 3 different tools was proving to be cumbersome.
              They wanted a single location for all their data. Based on Siren's
              product roadmap, they decided to choose Siren as the platform to
              for better tracking and analysis.
            </p>
            <div className="studySolutionBullets">
              <p className="studySolutionBulletTag">
                Siren's solution consists of 2 pieces:
              </p>
              <ul>
                <li className="studySolutionBullet">
                  <p>
                    An easy-to-use mobile app that associates and volunteers use
                    to record observations while out in the field with limited
                    or no connectivity, and even under low lighting conditions.
                  </p>
                </li>
                <li className="studySolutionBullet">
                  <p>
                    A desktop site allows researchers to analyze the aggregated
                    data to track, validate and report their findings.
                  </p>
                </li>
              </ul>
              <p className="studySolutionBulletTag">
                Siren also has multiple features that are specially relevant for
                research organizations worldwide:
              </p>
              <ul>
                <li className="studySolutionBullet">
                  <p>
                    Single interface: With Siren, researchers can view all the
                    data in one location with an intuitive map interface.
                  </p>
                </li>
                <li className="studySolutionBullet">
                  <p>
                    Multi-language support: Data can be entered and viewed in
                    the local language.
                  </p>
                </li>
                <li className="studySolutionBullet">
                  <p>
                    Data Privacy: The recorded data belongs to the project and
                    is not made public unless the organization chooses to do so.
                  </p>
                </li>
                <li className="studySolutionBullet">
                  <p>
                    Data format: Data for turtles is recorded using the SWOT
                    format for data consistency and interoperability across
                    regions if desired.
                  </p>
                </li>
              </ul>
            </div>
            <h3 className="studyHeader">THE IMPACT & NEXT STEPS</h3>
            <p>
              The Siren solution is now used extensively by the environmental
              agency for the marine turtle monitoring project. With Siren,
              researchers can record and analyze comprehensive data for the
              entire duration of the turtle nesting season. But more
              importantly, with data recorded over multiple seasons, they can
              see multi-year trends.
            </p>
            <p>
              Having seen success using Siren for the 2022/2023 nesting seasons,
              they are now ready to roll out Siren for the upcoming 2024 season.
            </p>
          </div>
          <div className="studyRight">
            <div className="aboutSiren">
              <h3 className="studyHeader">ABOUT SIREN</h3>
              <p>
                Siren enables marine researchers and scientists to turn their
                marine wildlife observations into actionable conservation data.
              </p>
              <p>
                The Siren app was created by AMMCO (African Marine Mammal
                Conservation Organization) as part of their turtle conservation
                program. It has since expanded to support projects across
                multiple countries covering a variety of species.
              </p>
              <p>
                With Siren, researchers can easily record observations on marine
                fauna - either through citizen science projects with local
                communities and fishermen, or with dedicated teams out in the
                field. Consolidated data can be downloaded offline for detailed
                analysis of species, population distribution and health and
                growth trends. Findings can be shared with peers, government
                agencies, policy makers and funding organizations to get
                visibility, raise awareness, facilitate policy changes, and to
                secure funds to further expand conservation efforts.
              </p>
              <p>
                Learn more about Siren at&nbsp;&nbsp;
                <a
                  href="https://sirenammco.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.sirenammco.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="studyEndBorder" />
    </div>
  );
}

export default HawksbillTurtlesCaseStudy;
