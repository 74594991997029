import { observationStyle } from "../../../utils/TypeObsStyle";
import "./style.css";
import { ReactComponent as PlaceholderImage } from "../../../assets/images/no-image.svg";
import Loading from "../../LoadingError/Loading";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ConfirmationModal from "../../modals/ConfirmationModal";
import EditObservation from "../../modals/observations/editObs/EditObservation";
import ImageCarosel from "../../modals/ImageCarosel";
import DetailModal from "../../modals/observations/detailObs/DetailModal";
import {
  deleteObservaion,
  deleteObservationLocally,
} from "../../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTranslateObsType } from "../../../utils/useTranslateObservationType";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useFetchSingleObs } from "../../../queries/useFetchOptions";
export const MarkerPopup = ({ singleObsId }) => {
  const {
    singleObs,
    singleObsLoaing,
    refetchSIngleObs,
    singleObsIsRefetching,
  } = useFetchSingleObs({
    singleObsId,
  });
  useEffect(() => {
    refetchSIngleObs();
  }, [singleObsId, refetchSIngleObs]);

  const locationPath = useLocation();
  const [preventModalOpen, setPreventModalOpen] = useState(true);
  useEffect(() => {
    if (locationPath.pathname === "/explore/map") {
      setPreventModalOpen(false);
    } else {
      setPreventModalOpen(true);
    }
  }, [locationPath.pathname]);
  const { t } = useTranslation();
  const handleHideModal = () => {
    setShowProjectModal(false);
  };

  //edit modal hide and show state and function
  const [showEditModal, setShowEditModal] = useState(false);

  // caroel
  const [showCarosel, setShowCarosel] = useState(false);
  const handleShowImageCarosel = () => {
    setShowCarosel(true);
    setShowProjectModal(false);
  };
  const hideImageCarosel = () => {
    setShowCarosel(false);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
  };
  const handleOpenEditModal = () => {
    setShowEditModal(true);
    setShowProjectModal(false);
  };
  const dispatch = useDispatch();

  const [showProjectModal, setShowProjectModal] = useState(false);

  // delete modal state and functions
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [observaionId, setObservaionId] = useState();

  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const deleteObservation = useSelector((state) => state.deleteObservation);
  const { deleteLoading, deleteError } = deleteObservation;

  const handleConfirmAction = () => {
    dispatch(deleteObservaion(observaionId));
    dispatch(deleteObservationLocally([observaionId]));
    handleHideConfirmationModal();
  };

  // deleteObsFromView

  const [deleteObsFromView, setDeleteObsFromView] = useState(false);
  const handleDeleteObsFromView = () => {
    setDeleteObsFromView(!deleteObsFromView);
  };
  const closeConfirm = () => {
    setDeleteObsFromView(false);
  };
  const closeConfirm2 = () => {
    setShowConfirmModal(false);
  };
  const translatedObsType = useTranslateObsType(
    singleObs?.data?.TypeObservation
  );
  function getCurrentURL() {
    return window.location.pathname;
  }
  const url = getCurrentURL();
  return (
    <>
      <div className="marker-popup marker-obs">
        {(singleObsLoaing || singleObsIsRefetching) && (
          <div
            className=""
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              viewBox="0 0 40 40"
              width="40"
              height="40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="spinner-gradient">
                  <stop offset="0%" stop-color="#073B60" />
                  <stop offset="100%" stop-color="#4980ad" />
                </linearGradient>
              </defs>
              <circle
                cx="20"
                cy="20"
                r="16"
                stroke="url(#spinner-gradient)"
                stroke-width="3"
                fill="none"
                stroke-dasharray="80 50"
                stroke-linecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>
            </svg>
          </div>
        )}
        {!singleObsLoaing && singleObs && !singleObsIsRefetching && (
          <>
            <div className="marker-img marker-details">
              {singleObs?.data?.images.img1 !== "NULL" ? (
                <img
                  src={singleObs?.data?.images.img1}
                  alt="marker-img"
                  width={`90px`}
                  height={`90px`}
                />
              ) : (
                <div className="marker__img__container">
                  <PlaceholderImage className="no-image" />
                </div>
              )}
            </div>
            <table
              className=" popup__table"
              style={{
                height: "150px",
              }}
            >
              <thead>
                <tr>
                  <td>
                    {preventModalOpen ? (
                      <div
                        className="marker-head"
                        onClick={() => {
                          if (url === "/map") {
                            setShowProjectModal(true);
                          }
                        }}
                      >
                        Obs {singleObsId}
                      </div>
                    ) : (
                      <div
                        className="marker-head"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        Obs {singleObsId}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="obs__btn__container">
                      <div
                        className="obs-btn"
                        style={
                          singleObs?.data?.TypeObservation === "Threat"
                            ? observationStyle.Threat
                            : singleObs?.data?.TypeObservation === "Crawl"
                            ? observationStyle.Crawls
                            : singleObs?.data?.TypeObservation === "Animal"
                            ? observationStyle.Animal
                            : singleObs?.data?.TypeObservation ===
                              "Flash Observation"
                            ? observationStyle.FlashObs
                            : singleObs?.data?.TypeObservation ===
                              "Signs of presence"
                            ? observationStyle.SignsPresence
                            : singleObs?.data?.TypeObservation ===
                              "Female Encounter"
                            ? observationStyle.FemaleEn
                            : singleObs?.data?.TypeObservation === "Nest"
                            ? observationStyle.Nest
                            : observationStyle.noStyle
                          //  styles.noStyle
                        }
                      >
                        <p>{translatedObsType}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="obs_title">{t("author")}:</div>
                  </td>
                  <td>
                    <div className="obs_details obs_author">
                      {singleObs?.data?.user}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="obs_title">{t("location")}:</div>
                  </td>
                  <td>
                    <div className="obs_details">{singleObs?.data?.site}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="obs_title">Date:</div>
                  </td>
                  <td>
                    <div className="obs_details obs__time">
                      {singleObs?.data?.date.date.slice(0, 10)}{" "}
                      <div>{singleObs?.data?.date.date.slice(11, 16)}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="obs_title">{t("group")}:</div>
                  </td>
                  <td>
                    <div className="obs_details">{singleObs?.data?.group}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="obs_title">{t("family")}:</div>
                  </td>
                  <td>
                    <div className="obs_details">{singleObs?.data?.family}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="obs_title">{t("species")}:</div>
                  </td>
                  <td>
                    <div className="obs_details">{singleObs?.data?.specie}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>

      {showProjectModal && (
        <DetailModal
          editData={singleObs?.data}
          closeDetailModal={handleHideModal}
          openIsEditModal={handleOpenEditModal}
          handleShowImageCarosel={handleShowImageCarosel}
        />
      )}
      {showEditModal && (
        <EditObservation
          editData={singleObs?.data}
          closeIsEditModal={handleHideEditModal}
        />
      )}

      {showCarosel && (
        <ImageCarosel
          // handleShowImageCarosel={handleShowImageCarosel}
          hideImageCarosel={hideImageCarosel}
          images={singleObs?.data?.images}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleConfirmAction}
          closeConfirm={closeConfirm2}
        />
      )}
      {deleteObsFromView && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleDeleteObsFromView}
          closeConfirm={closeConfirm}
          handleConfirmAction={handleConfirmAction}
        />
      )}

      {deleteLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)}",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};
