import React from "react";

import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import Loading2 from "./Loading2";
import "./Loading.css";
const MapLoading = () => {
  return (
    <div
      className="relative"
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Loading2 />
      <div className="map__loader_container">
        <MapContainer
          center={[3.8886167140522083, 11.5045313369437]}
          zoom={4}
          scrollWheelZoom={true}
          zoomControl={false}
          layer-type="base"
          style={{ width: "100vw", height: "91vh", zIndex: "1" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <div className="">
            <ZoomControl
              position="bottomright"
              zoomInText="+"
              zoomOutText="-"
            />
          </div>
        </MapContainer>
      </div>
    </div>
  );
};

export default MapLoading;
