import { useEffect, useState } from "react";
import { logout } from "../actions/userActions";
import axios from "axios";
import { API_URL } from "../services/API";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { formatDate5 } from "../utils/DateFormater";

export const useFetchObsCount = ({ dates }) => {
  const todaysDate = new Date();
  const year = todaysDate.getFullYear();
  const month = String(todaysDate.getMonth() + 1).padStart(2, "0");
  const day = String(todaysDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState(formattedDate);
  useEffect(() => {
    if (dates?.length > 0) {
      const dt = new Date(dates[0]);
      const dt2 = new Date(dates[1]);
      const st = formatDate5(dt);
      const st2 = formatDate5(dt2);
      setStartDate(st);
      setEndDate(st2);
    }
  }, [dates]);

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["listviewtotalObs", localProjectId, dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/totalObservationsDated?project_id=${localProjectId}&start_date=${startDate}&end_date=${endDate}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, startDate, endDate, localProjectId]);

  return {
    totalObs: data,
    totalObsError: error,
    totalObsLoading: isLoading,
    refechTotalObs: refetch,
  };
};

// Total unvalide Obs
export const useFetchUnvalidObsCount = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["listviewtotalUnvalidObs", localProjectId, dates],
    () => {
      return axios
        .get(
          API_URL +
            `api/dashboard/totalUnvalidObs?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    totalUnvalidObs: data?.totalUnvalidObs,
    totalUnvalidObsError: error,
    totalUnvalidObsLoading: isLoading,
    refechTotalUnvalidObs: refetch,
  };
};
