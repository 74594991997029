
export const formatDate = (dbdate) => {
  const date = new Date(dbdate);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h12",
  });
  return formattedDate;
};
export const formatDate2 = (dbdate) => {
  const date = new Date(dbdate);
  const formattedDate = date.toLocaleDateString("es-CL", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // hourCycle: "h12",
  });
  return formattedDate;
};
export const formatDate3 = (dbdate) => {
  const date = new Date(dbdate);
  const formattedDate = date.toLocaleDateString("es-CL", {
    day: "numeric",
    month: "numeric",
    year: "numeric",

    // hourCycle: "h12",
  });
  return formattedDate;
};
export const formatDate4 = (dbdate) => {
  const date = new Date(dbdate);
  const formattedDate = date.toLocaleDateString("es-CL", {
    year: "numeric",
    month: "numeric",
    day: "numeric",

    // hourCycle: "h12",
  });
  return formattedDate;
};
export const formatDate5 = (dbdate) => {
  let objectDate = dbdate;

  const year = objectDate.getFullYear();
  const month = objectDate.getMonth() + 1;
  const day = objectDate.getDate();

  const formattedDate = [year, month, day].join("-");

  return formattedDate;
};

export const formatDate6 = (dbdate) => {
  let objectDate = dbdate;

  const year = objectDate.getFullYear();
  const month = objectDate.getMonth() + 1;
  const day = objectDate.getDate();

  const formattedList = [year, month, day].map(ele => {
    return (ele < 10 ? "0" : "") + ele.toString();
  });
  const formattedDate = formattedList.join("-");

  return formattedDate;
};
