import { t } from "i18next";

export const useTranslateObsType = (obsType) => {
  switch (obsType) {
    case "Flash Observation":
      return `${t("dbData.obsTypes.flashObservation")}`;
    case "Animal":
      return `${t("dbData.obsTypes.animal")}`;
    case "Threat":
      return `${t("dbData.obsTypes.threat")}`;
    case "Crawl":
      return `${t("dbData.obsTypes.crawl")}`;
    case "Female Encounter":
      return `${t("dbData.obsTypes.femaleEncounter")}`;
    case "Nest":
      return `${t("dbData.obsTypes.nest")}`;
    case "Signs of presence":
      return `${t("dbData.obsTypes.signPresence")}`;
    case "Other":
      return `${t("dbData.obsTypes.others")}`;
    default:
      return obsType;
  }
};
