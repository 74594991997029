import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { deleteQuestion } from "../../../actions/projectActions";

const styles = {
  select: { margin: "0 20px" },
  divstyle: {
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  buttonsCellContainer: {
    padding: "0 20px",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.3)"
    borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
  },
  editButton: {
    background: "transparent",
    outline: "none",
    cursor: "pointer",
    padding: 4,
    display: "inline-flex",
  },
};

const DeleteQuestion = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const remove = () => {
    dispatch(deleteQuestion({ questionId: data.id }));

    handleCloseModal();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div style={styles.buttonsCellContainer}>
      <button
        title="Remove"
        style={styles.editButton}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <img src="assets/images/icon/removeuser.svg" alt="" />
      </button>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        centered
        className="mx-auto"
        keyboard={false}
        // size="sm"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span
            className="pe-sm-2 pointer "
            onClick={() => {
              handleCloseModal();
            }}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "15px", cursor: "pointer" }}
            />
          </span>
        </div>

        <Modal.Body className="pe-4 ps-4">
          <div className=" d-flex justify-content-center">
            <img
              src="assets/elements/images/delete_icon2.png"
              alt=""
              width={40}
            />
          </div>
          <div className="text-center h5 mt-3">
            Remove question with id {data.id} ?
          </div>
          <div className="text-center mb-3">
            This question will no longer be part of this project.
          </div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".5px solid #B3B3B3",
          }}
        >
          <div
            className="text-center p-2 fs-7 cancelbtn"
            style={{
              borderRight: ".5px solid #B3B3B3",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            Cancel
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            style={{
              width: "50%",
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => {
              remove();
            }}
          >
            Remove
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteQuestion;
