import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";
import position from "../../../../../assets/images/position.svg";
const EditCordsInput = ({ cordRef }) => {
  const { t } = useTranslation();

  const {
    newLong,
    newLat,
    setNewLat,
    setNewLong,
    newLocalLat,
    newLocalLong,
    setNewLocalLat,
    setNewLocalLong,
    setChangeTracker,
  } = useEditObservationStore();

  const [coordsPosition, setCoordsPosition] = useState();
  const [coordsWidth, setCoordsWidth] = useState();
  useEffect(() => {
    if (cordRef && cordRef.current) {
      setCoordsPosition(cordRef.current.offsetHeight);
      setCoordsWidth(cordRef.current.offsetWidth);
    }
  }, []);
  const [showCoordsBox, setShowCoordsBox] = useState(false);

  const [isCoordsSet, setIsCoordsSet] = useState(true);
  const [coordsErrorMessage, setCoordsErrorMessage] = useState("");
  useEffect(() => {
    if (newLat && newLong) {
      setIsCoordsSet(false);
    }
  }, [newLong, newLat]);

  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  function LongRangeCheck(number) {
    if (Number(number) >= -180 && Number(number) <= 180) {
      // Number is within the range

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }
  function LatRangeCheck(number) {
    if (Number(number) >= -90 && Number(number) <= 90) {
      // Number is within the range
      // , 29.211659283924526

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }
  const handleSubmitCoords = () => {
    if (LongRangeCheck(newLong) && LatRangeCheck(newLat)) {
      setNewLocalLat(newLat);
      setNewLocalLong(newLong);
      // LatRangeCheck(newLat);

      setShowCoordsBox(!showCoordsBox);
      setIsCoordsSet(true);

      // hide error message if one

      // // setChangeTracker
      setChangeTracker(true);
      setIsInvalidCoords(false);
    }

    if (!LatRangeCheck(newLat) && !LongRangeCheck(newLong)) {
      setCoordsErrorMessage(`${t("invalidLongLat")}`);

      setIsInvalidCoords(true);
    } else if (!LatRangeCheck(newLat)) {
      setCoordsErrorMessage(`${t("invalidLat")}`);
      setIsInvalidCoords(true);
    } else if (!LongRangeCheck(newLong)) {
      setCoordsErrorMessage(`${t("invalidLong")}`);
      setIsInvalidCoords(true);
    }
  };
  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title coords_mobile">
        {t("coords")} <sup>*</sup>
      </div>
      <div
        className="col-7 modal__title__value "
        style={{
          position: "relative",
        }}
      >
        {/* new coords system */}
        <div className="coords__wrapper__content">
          <div
            className="coords__wrapper "
            ref={cordRef}
            onClick={() => {
              setShowCoordsBox(true);
            }}
          >
            <div className="coords__container">
              <div className="lat__container">{newLocalLat && newLocalLat}</div>
              <span>,</span>
              <div className="log__container">
                {newLocalLong && newLocalLong}
              </div>
            </div>

            <div className="coords__marker">
              <img src={position} alt="" width={11} className="mt-1 me-2 " />
            </div>
          </div>
          {showCoordsBox && (
            <div
              className="coords__popup__wrapper"
              style={{
                // width: `${coordsWidth - 50}px`,
                top: `${coordsPosition + 5}px`,
              }}
            >
              {isInvalidCoords && (
                <span
                  className="error"
                  style={{
                    position: "absolute",
                    left: "10px",
                  }}
                >
                  {coordsErrorMessage}
                </span>
              )}
              <div
                className="coords__inputs"
                style={
                  {
                    // width: `${coordsWidth - 50}px`,
                  }
                }
              >
                <div className="coords__inputs__container">
                  <div>
                    <label>Latitude</label>
                    <input
                      type="number"
                      placeholder="XX.XXXXXX"
                      defaultValue={newLocalLat && newLocalLat}
                      style={{ fontSize: ".6rem" }}
                      onChange={(e) => {
                        setNewLat(e.target.value);
                        setChangeTracker(true);
                      }}
                    />
                  </div>
                  <div className="ms-2">
                    <label>Longitude</label>
                    <input
                      style={{ fontSize: ".6rem" }}
                      placeholder="XX.XXXXXX"
                      type="number"
                      defaultValue={newLocalLong && newLocalLong}
                      onChange={(e) => {
                        setNewLong(e.target.value);
                        setChangeTracker(true);
                      }}
                    />
                  </div>
                </div>
                <div className="coords__btn__coords">
                  <div>
                    <button
                      onClick={() => {
                        setShowCoordsBox(false);
                        setIsInvalidCoords(false);
                        setIsCoordsSet(true);
                      }}
                    >
                      {t("cancelBtn")}
                    </button>
                  </div>
                  <div>
                    <button
                      className="save"
                      onClick={() => {
                        handleSubmitCoords();
                      }}
                      disabled={isCoordsSet}
                    >
                      {t("saveBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCordsInput;
