import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { API_URL as API } from "../services/API";

let user = {};
let project = {};
let userCountry = {};
let role = "";
let refreshUsers = false;
let createProject = false;
let userJob = {};
let successCreateProject = false;
let successModifieProject = false;
let successModifieAccount = false;
let projectCreationModification = false;
let uncheckAll = false;
let itemList = -1;
let modpro = false;
let memberadd = false;
let changePass = false;
let userDelete = false;
let changeAccount = false;
let accountData = [];
let projectData = [];
let sendData = {};
let sendProjectData = {};
let pan = false;
let panTest = false;

export const successModifieAccount$ = new BehaviorSubject(
  successModifieAccount
);
export const accountData$ = new BehaviorSubject(accountData);
export const pan$ = new BehaviorSubject(pan);
export const panTest$ = new BehaviorSubject(panTest);
export const projectData$ = new BehaviorSubject(projectData);
export const changeAccount$ = new BehaviorSubject(changeAccount);
export const userDelete$ = new BehaviorSubject(userDelete);
export const changePass$ = new BehaviorSubject(changePass);
export const itemList$ = new BehaviorSubject(itemList);
export const modpro$ = new BehaviorSubject(modpro);
export const uncheckAll$ = new BehaviorSubject(uncheckAll);
export const user$ = new BehaviorSubject(user);
export const role$ = new BehaviorSubject(role);
export const project$ = new BehaviorSubject(project);
export const userCountry$ = new BehaviorSubject(userCountry);
export const refreshUsers$ = new BehaviorSubject(refreshUsers);
export const createProject$ = new BehaviorSubject(createProject);
export const userJob$ = new BehaviorSubject(userJob);
export const memberadd$ = new BehaviorSubject(memberadd);
export const successCreateProject$ = new BehaviorSubject(successCreateProject);
export const successModifieProject$ = new BehaviorSubject(
  successModifieProject
);
export const projectCreationModification$ = new BehaviorSubject(
  projectCreationModification
);

export const setUser = (item) => {
  localStorage.setItem("userInfo", JSON.stringify(item));
  user = item;
  user$.next(user);
};

export const setPan = (val) => {
  pan = val;
  pan$.next(pan);
};

export const setPanTest = (val) => {
  panTest = val;
  panTest$.next(panTest);
};

export const getPan = () => {
  return pan;
};

export const getPanTest = () => {
  return panTest;
};

export const setProject = (item) => {
  localStorage.setItem("selectedProject", item);

  project = item;
  project$.next(JSON.parse(project));
};

export const setProjectRole = (item, role) => {
  localStorage.setItem("selectedProject", item);

  localStorage.setItem("role", role);

  project = item;
  project$.next(JSON.parse(project));
};

export const getProject = () => {
  project = localStorage.getItem("selectedProject");
  project$.next(JSON.parse(project));
};

export const getUser = () => {
  user = localStorage.getItem("userInfo");
  user$.next(JSON.parse(user));
};

export const setRxCountry = (myCountry) => {
  userCountry = myCountry;

  userCountry$.next(userCountry);
};

export const getRole = () => {
  role = localStorage.getItem("role");
  role$.next(role);
};

export const setRefreshUser = () => {
  refreshUsers = !refreshUsers;
  refreshUsers$.next(refreshUsers);
};

export const setCreateProject = (val) => {
  createProject = val;
  createProject$.next(createProject);
};

export const setUserjob = (job) => {
  userJob = job;
  userJob$.next(userJob);
};

export const displaySuccessMsgCreateProject = () => {};

export const displaySuccessModifieMsgCreateProject = () => {
  console.warn("enter");
};

export const setSuccessCreateProject = () => {
  successCreateProject = true;
  successCreateProject$.next(successCreateProject);
};

export const unSuccessCreateProject = () => {
  successCreateProject = false;
  successCreateProject$.next(successCreateProject);
};

export const setSuccessModifieProject = () => {
  successModifieProject = true;
  successModifieProject$.next(successModifieProject);
  displaySuccessModifieMsgCreateProject();
};

export const operationOnProject = (val) => {
  projectCreationModification = val;
  projectCreationModification$.next(projectCreationModification);
};

export const uncheckAllAction = () => {
  uncheckAll = !uncheckAll;
  uncheckAll$.next(uncheckAll);
};

export const setItemList = (val) => {
  itemList = val;
  itemList$.next(itemList);
};

export const setModPro = () => {
  modpro = !modpro;
  modpro$.next(modpro);
};

export const setSuccedMemberadd = () => {
  memberadd = true;
  memberadd$.next(true);
};

export const unSetSuccedMemberadd = () => {
  memberadd = false;
  memberadd$.next(false);
};

export const setChangePass = (val) => {
  changePass = val;
  changePass$.next(changePass);
};

export const setUserDelete = (val) => {
  userDelete = val;
  userDelete$.next(userDelete);
};

export const setChangeAccount = (val) => {
  changeAccount = val;
  changeAccount$.next(changeAccount);
};

export const setAccountData = (data) => {
  accountData[data.name] = data.value;

  sendData = Object.assign({}, accountData);
};

export const setProjectData = async (data) => {
  projectData[data.name] = data.value;

  sendProjectData = Object.assign({}, projectData);

  if (data.name === "id") {
    await submitProject(sendProjectData);
  }
};

export const saveAction = async () => {
  await submitAccount(sendData);
};

// export const InitialiseAccountData = () => {
//   let user = localStorage.getItem("userInfo");
//   let json = JSON.parse(user);
//   accountData = json.data;
// };

const submitAccount = async (data) => {
  let info = localStorage.getItem("userInfo");
  let user = JSON.parse(info);

  if (data["city"] !== undefined) user.data.city = data["city"];
  if (data["codeTel"] !== undefined) user.data.codeTel = data["codeTel"];
  if (data["country"] !== undefined) user.data.country = data["country"];
  if (data["country_id"] !== undefined)
    user.data.countryid = data["country_id"];
  if (data["first_name"] !== undefined)
    user.data.firstname = data["first_name"];
  if (data["fonction"] !== undefined) user.data.fonction = data["fonction"];
  if (data["last_name"] !== undefined) user.data.lastname = data["last_name"];
  if (data["organization"] !== undefined)
    user.data.organization = data["organization"];
  if (data["phone"] !== undefined) user.data.phone = data["phone"];
  if (data["sigle"] !== undefined) user.data.sigle = data["sigle"];

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.data.token}`,
    },
  };
  console.time("time-calc");
  axios
    .post(
      API + "api/user/update",
      data,
      // {
      //   city: data.location,
      //   country_id: pays[0].value,
      //   email: data.email,
      //   first_name: data.fname,
      //   fonction_id: job.value,
      //   last_name: data.lname,
      //   organization: data.organization,
      //   phone: telephone,
      //   code: codeTel,
      //   sigle: phoneSigle,
      // },
      config
    )
    .then(function (response) {
      setUser(user);
      if (data["fonction"] !== undefined)
        setUserjob({ value: data["fonction_id"], label: data["fonction"] });
      setChangeAccount(false);
      setModifiedAccount();
    })
    .catch(function (error) {
      console.log(error);
    });
  console.timeEnd("time-calc");
};

export const saveProjectAction = async () => {
  let project = await localStorage.getItem("selectedProject");
  let selectedProject = await JSON.parse(project);

  setProjectData({ name: "id", value: selectedProject.id });
  console.warn("location: here 01");
};

const submitProject = async (data) => {
  let info = localStorage.getItem("userInfo");
  let user = JSON.parse(info);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  axios
    .post(API + "api/project/update", data, config)
    .then(function (response) {
      operationOnProject(false);

      response.data.data["role"] = "admin";
      if (data.public !== undefined) response.data.data.public = data.public;

      localStorage.setItem("projectName", response.data.data.name);
      localStorage.setItem("projectId", response.data.data.id);
      setModPro();
      setProject(JSON.stringify(response.data.data));
      setSuccessModifieProject();
    })
    .catch(function (error) {
      console.log(error);
    });
  console.timeEnd("time-calc");
};

export const setModifiedAccount = () => {
  successModifieAccount = true;
  successModifieAccount$.next(successModifieAccount);
  displaySuccessMsgModifiedAccount();
};

export const displaySuccessMsgModifiedAccount = () => {
  console.warn("enter");
};
