import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { setRefreshUser, setUserDelete } from "../../services/rxjs";
import { removeUserAction } from "../../actions/userActions";
import { API_URL as API } from "../../services/API";

const styles = {
  select: { margin: "0 20px" },
  divstyle: {
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  buttonsCellContainer: {
    padding: "0 20px",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.3)"
    borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
  },
  editButton: {
    background: "transparent",
    outline: "none",
    cursor: "pointer",
    padding: 4,
    display: "inline-flex",
  },
};

const DeleteBtn = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const isUserAdmin = useSelector((state) => state.userRoleOnParticularProject);
  const { role } = isUserAdmin;

  const removeuser = async (userproject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const response = await axios
      .get(API + `api/userproject/delete?userproject_id=${userproject}`, config)
      .then(function (response) {
        setRefreshUser();
        setUserDelete(true);
        dispatch(removeUserAction({ isUserRemoved: true }));

        setTimeout(() => {
          setUserDelete(false);
          dispatch(removeUserAction({ isUserRemoved: false }));
        }, 4000);
        return true;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });

    return response;
  };

  const handleDeleteModal = () => {
    setShowModal(false);
  };

  const remove = () => {
    let result = removeuser(data.userprojectid);

    result.then(function (response) {
      if (response) {
        // tableManager.rowsApi.rows.splice(updatedRowIndex, 1);
      }
    });

    handleDeleteModal();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div style={styles.buttonsCellContainer}>
      {role && (
        <button
          title="Remove"
          style={styles.editButton}
          onClick={() => {
            if (data.id !== userInfo.data.id) {
              if (data.firstname !== "" && data.firstname !== null)
                setUserName(data.firstname);
              else {
                if (data.lastname !== "" && data.lastname !== null)
                  setUserName(data.lastname);
                else setUserName(data.email);
              }

              setShowModal(true);
            }
          }}
        >
          {data.id !== userInfo.data.id ? (
            <img src="assets/images/icon/removeuser.svg" alt="" />
          ) : (
            <img src="assets/images/icon/removeme.svg" alt="" />
          )}
        </button>
      )}

      <Modal
        show={showModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
        className="mx-auto"
        keyboard={false}
        // size="sm"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span
            className="pe-sm-2 pointer "
            onClick={() => {
              handleDeleteModal();
            }}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "15px", cursor: "pointer" }}
            />
          </span>
        </div>

        <Modal.Body className="pe-4 ps-4">
          <div className=" d-flex justify-content-center">
            <img
              src="assets/elements/images/delete_icon2.png"
              alt=""
              width={40}
            />
          </div>
          <div className="text-center h5 mt-3">
            Remove {userName} as team member?
          </div>
          <div className="text-center mb-3">
            {userName} will no longer have access to this project.
          </div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".5px solid #B3B3B3",
          }}
        >
          <div
            className="text-center p-2 fs-7 cancelbtn"
            style={{
              borderRight: ".5px solid #B3B3B3",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDeleteModal();
            }}
          >
            Cancel
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            style={{
              width: "50%",
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => {
              remove();
            }}
          >
            Remove
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteBtn;
