import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";
import { useFetchSiteSgmntListByProject } from "../../../../../queries/useFetchOptions";
import { useSelector } from "react-redux";

const EditObsSiteSegment = ({ editData }) => {
  const { t } = useTranslation();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const {
    site,
    segment,
    displaySite,
    displaySegment,
    setSite,
    setSegment,
    setDisplaySegment,
    setDisplaySite,
    setChangeTracker,
  } = useEditObservationStore();
  const {
    siteSegmentList,

    refetchSiteSegment,
    siteSegmentLoading,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntListByProject(localProjectId);

  const [customSegmentList, setCustomSegmentList] = useState(null);

  const handleSetSegmentList = (seletedSiteId) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((site) => {
        if (site.siteId === Number(seletedSiteId)) {
          setCustomSegmentList(site.segment);
        }
        return site.segment;
      });
    }
  };

  useEffect(() => {
    if (site) {
      handleSetSegmentList(site);
    }
  }, [site, siteSegmentList]);

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);

  const handleSetSiteName = (target) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((s) => {
        if (Number(target) === s.siteId) {
          setDisplaySite(s.site);
        }
        return s.site;
      });
    }
  };

  const handleSetSegmentName = (target) => {
    if (customSegmentList) {
      customSegmentList.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySegment(s.name);
        }
        return s.name;
      });
    }
  };

  useEffect(() => {
    if (editData) {
      setDisplaySite(editData.site);
      setDisplaySegment(editData.segment);
      setSite(editData.siteId);
      setSegment(editData.segmentId);
    }
  }, [editData]);

  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title">
        {t("site")}, {t("segment")}
      </div>
      <div className="col-7 modal__title__value d-flex">
        <div className="col-6  me-1" style={{ width: "50%" }}>
          <select
            defaultValue={displaySite}
            onChange={(e) => {
              setSite(e.target.value);
              handleSetSiteName(e.target.value);
              setChangeTracker(true);
              handleSetSegmentList(e.target.value);
              setDisplaySegment("");
              setSegment("");
            }}
            style={{
              cursor: `${
                siteSegmentLoading || siteSegmentIsRefetching
                  ? "not-allowed"
                  : "pointer"
              }`,
              width: "100%",
            }}
            disabled={
              siteSegmentLoading || siteSegmentIsRefetching ? true : false
            }
            className="crud_select crud-input"
          >
            {siteSegmentLoading ? (
              <option selected value="">
                Loading...
              </option>
            ) : (
              <option
                value={site}
                // disabled
                style={{
                  color: "#073b60",
                  fontWeight: "bold",
                }}
              >
                {displaySite}
              </option>
            )}
            {siteSegmentList &&
              siteSegmentList.data
                .filter((s) => s.siteId !== editData.siteId) // Filter out the item with matching id
                .map((s, i) => (
                  <option value={s.siteId} key={i}>
                    {s.site}
                  </option>
                ))}
          </select>
        </div>
        <div className="col-6 w-100" style={{ width: "52%" }}>
          <select
            defaultValue={displaySegment}
            onChange={(e) => {
              setSegment(e.target.value);
              handleSetSegmentName(e.target.value);
              setChangeTracker(true);
            }}
            className="crud_select crud-input"
            style={{
              cursor: `${
                siteSegmentLoading || siteSegmentIsRefetching
                  ? "not-allowed"
                  : "pointer"
              }`,
              width: "50%",
            }}
            disabled={
              siteSegmentLoading || siteSegmentIsRefetching ? true : false
            }
          >
            {editData && editData.segment ? (
              <option
                value={`${editData && editData.segmentId}`}
                // disabled
                style={{
                  color: "#073b60",
                  fontWeight: "bold",
                }}
              >
                {displaySegment}
              </option>
            ) : (
              <option
                value={`${editData && editData.segmentId}`}
                // disabled
                style={{
                  color: "#073b60",
                  fontWeight: "bold",
                }}
              >
                Segment
              </option>
            )}

            {customSegmentList &&
              customSegmentList
                .filter((s) => s.id !== editData.segmentId) // Filter out the item with matching id
                .map((s, i) => (
                  <option value={s.id} key={i}>
                    {s.name}
                  </option>
                ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default EditObsSiteSegment;
