import "./userList.css";
import ChangeRoleAdmin from "./ChangeRoleAdmin";
import DeleteBtn from "./deleteButton";
import { useTranslation } from "react-i18next";

const GetColumns = () => {
  const { t } = useTranslation();
  return [
    {
      id: "3",
      field: "firstname",
      label: t("admin.members.userTable.firstName"),
      //
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },

      pinned: false,
      className: "headTab",
      width: "150px",
      // headerCellRenderer: HeaderRender,
    },
    {
      id: "4",
      field: `lastname`,
      label: t("admin.members.userTable.lastName"),
      className: "headTab",
      pinned: false,
      width: "150px",
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },
      // headerCellRenderer: HeaderRender,
    },
    {
      id: "5",
      field: "role",
      label: "Role",
      className: "headTab",
      pinned: false,
      width: "150px",
      // headerCellRenderer: HeaderRender,
      cellRenderer: ChangeRoleAdmin,
    },
    {
      id: "6",
      field: "email",
      label: "Email",
      className: "headTab",
      pinned: false,
      width: "150px",
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },
      // headerCellRenderer: HeaderRender,
    },
    {
      id: "7",
      field: "phone",
      label: t("admin.members.userTable.phone"),
      className: "headTab",
      // sortable: true,
      pinned: false,
      width: "150px",
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },
    },
    {
      id: "8",
      field: "job",
      label: t("admin.members.userTable.job"),
      className: "headTab",
      pinned: false,
      width: "115px",
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },
      // headerCellRenderer: HeaderRender,
    },

    {
      id: "9",
      field: "organization",
      label: t("admin.members.userTable.org"),
      className: "headTab",
      pinned: false,
      width: "150px",
      // headerCellRenderer: HeaderRender,
    },
    {
      id: "10",
      field: "city",
      label: t("admin.members.userTable.location"),
      pinned: false,
      width: "125px",
      sort: ({ a, b, isAscending }) => {
        let aa =
          typeof a === "string"
            ? a.toLowerCase()
            : a === null
            ? isAscending
              ? "zzzzz"
              : ""
            : a;

        let bb =
          typeof b === "string"
            ? b.toLowerCase()
            : b === null
            ? isAscending
              ? "zzzzz"
              : ""
            : b;
        if (aa > bb) return isAscending ? 1 : -1;
        else if (aa < bb) return isAscending ? -1 : 1;
        return 0;
      },
      // headerCellRenderer: HeaderRender,
    },

    {
      id: "buttons",
      width: "max-content",
      pinned: true,
      sortable: false,
      resizable: false,
      cellRenderer: DeleteBtn,
    },
  ];
};
export default GetColumns;
