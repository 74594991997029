import { create } from "zustand";

export const useEditObservationStore = create((set) => ({
  site: null,
  segment: null,
  displaySite: null,
  displaySegment: null,
  observation: null,
  obsId: null,
  classData: null,
  family: null,
  species: null,
  displayObserVationType: "",
  displayGroupe: "",
  displaySpecies: "",
  displayFamily: "",
  newLocalLong: null,
  newLocalLat: null,
  currentTime: "",
  seconds: "",
  newLat: null,
  newLong: null,
  convertedDate: null,
  note: "",
  deadOrAlive: null,
  checkedAliveValue: false,
  status: "",
  prevImages: [],
  changeTracker: false,
  crawlWidth: 0,
  // new nest
  newNestId: "",
  newNestCollector: "",
  newNestHighTideDist: 0,
  newNestStatus: "",
  newNestEstHatchDate: "",
  newNestEstExhumDate: "",

  // Femal encounter
  newFemEncTagLeft: "",
  newFemEncTagRight: "",
  newFemEncLength: 0,
  newFemEncWidth: 0,

  setSite: (site) => set({ site }),
  setSegment: (segment) => set({ segment }),
  setDisplaySite: (site) => set({ displaySite: site }),
  setDisplaySegment: (segment) => set({ displaySegment: segment }),
  setObservation: (observation) => set({ observation }),
  setObsId: (obsId) => set({ obsId }),
  setClassData: (classData) => set({ classData }),
  setFamily: (family) => set({ family }),
  setSpecies: (species) => set({ species }),
  setDisplayObservationType: (type) => set({ displayObserVationType: type }),
  setDisplayGroupe: (group) => set({ displayGroupe: group }),
  setDisplaySpecies: (species) => set({ displaySpecies: species }),
  setDisplayFamily: (family) => set({ displayFamily: family }),
  setNewLocalLong: (long) => set({ newLocalLong: long }),
  setNewLocalLat: (lat) => set({ newLocalLat: lat }),
  setCurrentTime: (time) => set({ currentTime: time }),
  setSeconds: (seconds) => set({ seconds }),
  setConvertedDate: (cvDate) => set({ convertedDate: cvDate }),
  setNote: (note) => set({ note: note }),
  setDeadOrAlive: (status) => set({ deadOrAlive: status }),
  setCheckedAliveValue: (value) => set({ checkedAliveValue: value }),
  setStatus: (status) => set({ status }),
  setPrevImages: (prevImgs) => set({ prevImages: prevImgs }),
  setChangeTracker: (tracker) => set({ changeTracker: tracker }),
  setNewLat: (lat) => set({ newLat: lat }),
  setNewLong: (long) => set({ newLong: long }),
  setCrawlWidth: (crawlWith) => set({ crawlWidth: crawlWith }),
  // new nest
  setNewNestID: (newNestId) => set({ newNestId: newNestId }),
  setNewNestCollector: (newNestCollector) =>
    set({ newNestCollector: newNestCollector }),
  setNewNestHighTideDist: (newNestHighTideDist) =>
    set({ newNestHighTideDist: newNestHighTideDist }),
  setNewNestStatus: (newNestStatus) => set({ newNestStatus: newNestStatus }),
  setNewNestEstHatchDate: (newNestEstHatchDate) =>
    set({ newNestEstHatchDate: newNestEstHatchDate }),
  setNewNestEstExhumDate: (newNestEstExhumDate) =>
    set({ newNestEstExhumDate: newNestEstExhumDate }),

  // Female encounter
  setNewFemEncTagLeft: (tagLeft) => set({ newFemEncTagLeft: tagLeft }),
  setNewFemEncTagRight: (tagRight) => set({ newFemEncTagRight: tagRight }),
  setNewFemEncLength: (length) => set({ newFemEncLength: length }),

  setNewFemEncWidth: (width) => set({ newFemEncWidth: width }),

  // Reset function
  resetEditDataStore: () =>
    set({
      site: null,
      segment: null,
      displaySite: null,
      displaySegment: null,
      observation: null,
      obsId: null,
      classData: null,
      family: null,
      species: null,
      displayObserVationType: "",
      displayGroupe: "",
      displaySpecies: "",
      displayFamily: "",
      newLocalLong: null,
      newLocalLat: null,
      currentTime: "",
      seconds: "",
      newLat: null,
      newLong: null,
      convertedDate: null,
      deadOrAlive: null,
      checkedAliveValue: false,
      status: "",
      prevImages: [],
      changeTracker: false,
      newNestId: "",
      newNestCollector: "",
      newNestHighTideDist: 0,
      newNestStatus: "",
      newNestEstHatchDate: "",
      newNestEstExhumDate: "",
      newFemEncTagLeft: "",
      newFemEncTagRight: "",
      newFemEncLength: 0,
      newFemEncWidth: 0,
    }),
}));
