import React, { useEffect, useMemo } from "react";
import "./style.css";
import { useFetchObsCount } from "../../../queries/useFetchObsCount";
import { useTranslation } from "react-i18next";

const Information = ({
  setEndRow,
  setTotalRows,
  tableManager,
  dates,
  totalCount,
  pageCount,
  selectedCount,
}) => {
  const { totalObs, totalObsLoading } = useFetchObsCount({ dates });
  const {
    paginationApi: { page, pageSize },
  } = tableManager;
  const { t } = useTranslation();

  const startRow = useMemo(() => (page - 1) * pageSize + 1, [page, pageSize]);
  const endRow = useMemo(
    () => Math.min(page * pageSize, totalCount),
    [page, pageSize, totalCount]
  );

  useEffect(() => {
    setEndRow(endRow);
    setTotalRows(totalCount);
    //
  }, [endRow, totalCount]);

  return (
    <div className="footer-information">
      <span className="footer_totalRow">{t("gridTable.totalRows")} : </span>
      {totalCount} / {totalObs?.totalValidObs}
      <span className="table_hidden_footer_info">
        | {t("gridTable.rows")} {startRow} - {endRow} | {selectedCount}{" "}
        {t("gridTable.selected")}
      </span>
    </div>
  );
};

export default Information;
