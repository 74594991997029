import React from "react";

import { useTranslation } from "react-i18next";
import useCreateValidation from "../../../../../../lib/createObs/useCreateValidation";
import { useCreateObservationStore } from "../../../../../../lib/createObs/useCreateObservationData";

function CreateNewFemaleEncounter({
  errors,
  crawlWidth,
  setCrawlWidth,
  setChangeTracker,
}) {
  const {
    localFemEncTagLeftValidation,
    localFemEncTagRightValidation,
    localFemEncLengthValidation,
    localFemEncWidthValidation,

    setLocalFemEncTagLeftValidation,
    setLocalFemEncTagRightValidation,
    setLocalFemEncLengthValidation,
    setLocalFemEncWidthValidation,
  } = useCreateValidation();

  // Data management
  const {
    femEncTagLeft,
    femEncTagRight,
    femEncLength,
    femEncWidth,

    setFemEncTagLeft,
    setFemEncTagRight,
    setFemEncLength,
    setFemEncWidth,
  } = useCreateObservationStore();

  const { t } = useTranslation();

  return (
    <>
      {/* Female Tag ID Left */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createFemaleEncounterFields.femaleTagLeft")}`}
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="fem enc tag left"
            name="femEncTagLeft"
            className={`crud_select crud-input ${
              localFemEncTagLeftValidation ? "is-invalid" : ""
            }`}
            value={femEncTagLeft}
            style={{ width: "101%" }}
            onChange={(e) => {
              setFemEncTagLeft(e.target.value);
              if (e.target.value?.trim() === "") {
                setLocalFemEncTagLeftValidation(true);
                setChangeTracker(false);
              } else {
                setLocalFemEncTagLeftValidation(false);
                setChangeTracker(true);
              }
            }}
          />

          {localFemEncTagLeftValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Female Tag ID Right */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createFemaleEncounterFields.femaleTagRight")}`}
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="fem enc tag right"
            name="femEncTagRight"
            className={`crud_select crud-input ${
              localFemEncTagRightValidation ? "is-invalid" : ""
            }`}
            value={femEncTagRight}
            style={{ width: "101%" }}
            onChange={(e) => {
              setFemEncTagRight(e.target.value);
              if (e.target.value?.trim() === "") {
                setLocalFemEncTagRightValidation(true);
                setChangeTracker(false);
              } else {
                setLocalFemEncTagRightValidation(false);
                setChangeTracker(true);
              }
            }}
          />

          {localFemEncTagRightValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Female Length */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createFemaleEncounterFields.carapaceLength")}`}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              type="text"
              inputMode="numeric"
              id="fem-enc-length"
              name="femEncLength"
              className={`crud_select crud-input ${
                localFemEncLengthValidation ? "is-invalid" : ""
              }`}
              value={femEncLength && femEncLength}
              style={{ width: "101%" }}
              onChange={(e) => {
                setFemEncLength(e.target.value);
                setLocalFemEncLengthValidation(false);
                setChangeTracker(true);
              }}
            />
            <span className="unit">(cm)</span>
          </div>

          {localFemEncLengthValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Female Width */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createFemaleEncounterFields.carapaceWidth")}`}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              type="text"
              inputMode="numeric"
              id="fem-enc-width"
              name="femEncWidth"
              value={femEncWidth && femEncWidth}
              className={`crud_select crud-input ${
                localFemEncWidthValidation ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              onChange={(e) => {
                setFemEncWidth(e.target.value);
                if (e.target.value?.trim() === "") {
                  setLocalFemEncWidthValidation(true);
                  setChangeTracker(false);
                } else {
                  setLocalFemEncWidthValidation(false);
                  setChangeTracker(true);
                }
              }}
            />
            <span className="unit">(cm)</span>
          </div>
          {localFemEncWidthValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Crawl Width */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {" "}
          {`${t("createFemaleEncounterFields.crawlWidth")}`}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="text"
              type="text"
              id="crawl-width"
              name="crawlWidth"
              className={`crud_select crud-input`}
              style={{ width: "101%" }}
              value={crawlWidth && crawlWidth}
              onChange={(e) => {
                setCrawlWidth(e.target.value);

                if (e.target.value?.trim() === "") {
                  setChangeTracker(false);
                } else {
                  setChangeTracker(true);
                }
              }}
            />
            <span className="unit">(cm)</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewFemaleEncounter;
