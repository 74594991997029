import React from "react";
import { BiErrorCircle } from "react-icons/bi";
import "./style.css";
const CustomError = ({ message }) => {
  return (
    <div>
      <div className="alert-danger custom-error">
        <div className="ms-5">
          <span>
            {/* <img
              //   src={success}
              alt="error"
              width={"30px"}
              height={"30px"}
              style={{ marginRight: "10px" }}
            /> */}
            <BiErrorCircle style={{ marginRight: "10px", fontSize: "30px" }} />
          </span>
          <span>{message}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomError;
