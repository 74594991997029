import create from "zustand";

let today1 = new Date();
var presentTime = today1.getHours() + ":" + today1.getMinutes();
export const useCreateObservationStore = create((set) => ({
  newDate: new Date(),
  currentTime: presentTime,
  seconds: "",
  newLocalLat: null,
  newLocalLong: null,
  newLat: null,
  newLong: null,
  obsTypeState: "",
  displayObserVationType: null,
  groupeState: "",
  familyState: "",
  speciesState: "",
  siteState: null,
  segmentState: "",
  deadOrAlive: 1,
  status: "Dead",
  images: [],
  imageData: {
    img1: "",
    img2: "",
    img3: "",
    img4: "",
  },
  nestID: null,
  nestCollector: "",
  nestHighTideDist: null,
  nestStatus: null,
  nestHealth: null,
  nestRelocation: "",
  suspectedNestCreationDate: "",
  nestEstHatchDate: "",
  nestEstExhumDate: "",
  femEncTagLeft: "",
  femEncTagRight: "",
  femEncLength: null,
  femEncWidth: null,
  crawlWidth: null,
  notes: "",
  isFemalePresent: false,
  displaySpecies: "",
  displayGroupe: "",
  displayFamily: "",
  displaySegment: "",
  displaySite: "",
  customSegmentList: [],
  relocateSite: "",
  relocateSegment: "",
  numberOfEggs: "",
  reasonForLost: "",
  doesNewNestNeedRelocation: false,
  isNewNestIntact: true,

  setNewDate: (date) => set({ newDate: date }),
  setCurrentTime: (time) => set({ currentTime: time }),
  setSeconds: (seconds) => set({ seconds: seconds }),
  setNewLocalLat: (lat) => set({ newLocalLat: lat }),
  setNewLocalLong: (long) => set({ newLocalLong: long }),
  setNewLat: (lat) => set({ newLat: lat }),
  setNewLong: (long) => set({ newLong: long }),
  setObsTypeState: (type) => set({ obsTypeState: type }),
  setDisplayObserVationType: (type) => set({ displayObserVationType: type }),
  setGroupeState: (groupe) => set({ groupeState: groupe }),
  setFamilyState: (family) => set({ familyState: family }),
  setSpeciesState: (species) => set({ speciesState: species }),
  setSiteState: (site) => set({ siteState: site }),
  setSegmentState: (segment) => set({ segmentState: segment }),
  setCustomSegmentList: (segment) => set({ customSegmentList: segment }),
  setDeadOrAlive: (status) => set({ deadOrAlive: status }),
  setStatus: (status) => set({ status: status }),
  setImages: (images) => set({ images: images }),
  setImageData: (data) => set({ imageData: data }),
  setNestID: (id) => set({ nestID: id }),
  setNestCollector: (collector) => set({ nestCollector: collector }),
  setNestHighTideDist: (dist) => set({ nestHighTideDist: dist }),
  setNestStatus: (status) => set({ nestStatus: status }),
  setNestHealth: (health) => set({ nestHealth: health }),
  setNestRelocation: (relocation) => set({ nestRelocation: relocation }),
  setSuspectedNestCreationDate: (date) =>
    set({ suspectedNestCreationDate: date }),
  setNestEstHatchDate: (date) => set({ nestEstHatchDate: date }),
  setNestEstExhumDate: (date) => set({ nestEstExhumDate: date }),
  setFemEncTagLeft: (tag) => set({ femEncTagLeft: tag }),
  setFemEncTagRight: (tag) => set({ femEncTagRight: tag }),
  setFemEncLength: (length) => set({ femEncLength: length }),
  setFemEncWidth: (width) => set({ femEncWidth: width }),
  setCrawlWidth: (width) => set({ crawlWidth: width }),
  setNotes: (notes) => set({ notes: notes }),
  setIsFemalePresent: (present) => set({ isFemalePresent: present }),
  setDisplaySpecies: (species) => set({ displaySpecies: species }),
  setDisplayGroupe: (groupe) => set({ displayGroupe: groupe }),
  setDisplayFamily: (family) => set({ displayFamily: family }),
  setDisplaySegment: (segment) => set({ displaySegment: segment }),
  setDisplaySite: (site) => set({ displaySite: site }),
  setRelocateSite: (site) => set({ relocateSite: site }),
  setRelocateSegment: (segment) => set({ relocateSegment: segment }),
  setNumberOfEggs: (eggs) => set({ numberOfEggs: eggs }),
  setReasonForLost: (reason) => set({ reasonForLost: reason }),
  setDoesNewNestNeedRelocation: (value) =>
    set({ doesNewNestNeedRelocation: value }),
  setIsNewNestIntact: (value) =>
    set({
      isNewNestIntact: value,
    }),

  resetCreateValues: () =>
    set({
      newDate: new Date(),
      currentTime: presentTime,
      seconds: "",
      newLocalLat: null,
      newLocalLong: null,
      obsTypeState: "",
      displayObserVationType: null,
      groupeState: "",
      familyState: "",
      speciesState: "",
      siteState: null,
      segmentState: "",
      deadOrAlive: 1,
      status: "Dead",
      images: [],
      imageData: {
        img1: "",
        img2: "",
        img3: "",
        img4: "",
      },
      nestID: "",
      nestCollector: "",
      nestHighTideDist: null,
      nestStatus: null,
      nestHealth: null,
      nestRelocation: "",
      suspectedNestCreationDate: "",
      nestEstHatchDate: "",
      nestEstExhumDate: "",
      femEncTagLeft: "",
      femEncTagRight: "",
      femEncLength: null,
      femEncWidth: null,
      crawlWidth: null,
      notes: "",
      isFemalePresent: false,
      displaySpecies: "",
      displayGroupe: "",
      displayFamily: "",
      displaySegment: "",
      displaySite: "",
      customSegmentList: [],
      relocateSite: "",
      relocateSegment: "",
      numberOfEggs: "",
      doesNewNestNeedRelocation: false,
      isNewNestIntact: true,
    }),
}));
export const useCreateNewFemaleEncounter = create((set) => ({
  obsTypeState: "3",
  images: [],
  imageData: {
    img1: "",
    img2: "",
    img3: "",
    img4: "",
  },
  notes: "",
  crawlWidth: null,
  femaleCreated: false,
  femaleSuccessCreation: false,
  setImages: (images) => set({ images: images }),
  setImageData: (data) => set({ imageData: data }),
  setNotes: (notes) => set({ notes: notes }),
  setCrawlWidth: (width) => set({ crawlWidth: width }),

  setFemaleCreated: (value) => set({ femaleCreated: value }),
  setFemaleSuccessCreation: (value) => set({ femaleSuccessCreation: value }),

  resetFCreateValues: () =>
    set({
      crawlWidth: null,
      images: [],
      imageData: {
        img1: "",
        img2: "",
        img3: "",
        img4: "",
      },
      notes: "",
      // femaleCreated: false,
      // femaleSuccessCreation: false,
    }),
}));
