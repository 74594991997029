import { formatDate5 } from "../../utils/DateFormater";

export const handleFormatData = (data) => {
  const {
    projectId,
    newDate,
    currentTime,
    seconds,
    newLocalLat,
    newLocalLong,
    obsTypeState,
    groupeState,
    familyState,
    speciesState,
    siteState,
    relocateSite,
    relocateSegment,
    numberOfEggs,
    doesNewNestNeedRelocation,
    segmentState,
    deadOrAlive,
    images,
    imageData,
    nestID,
    nestHighTideDist,
    nestStatus,
    nestHealth,
    nestRelocation,
    reasonForLost,
    suspectedNestCreationDate,
    nestEstHatchDate,
    nestEstExhumDate,
    femEncTagLeft,
    femEncTagRight,
    femEncLength,
    femEncWidth,
    crawlWidth,
    notes,
  } = data;
  let convertedDate = formatDate5(newDate);

  const newData = {
    project_id: projectId,
    segment_id: segmentState,
    siteId: siteState,
    type_observation_id: obsTypeState,
    groupe: groupeState,
    family: familyState,
    specie_id: speciesState,
    img1: images[0]?.data_url ? images[0]?.data_url : "",
    img2: images[1]?.data_url ? images[1]?.data_url : "",
    img3: images[2]?.data_url ? images[2]?.data_url : "",
    img4: images[3]?.data_url ? images[3]?.data_url : "",
    date: convertedDate,
    time: currentTime + `:${seconds}`,
    coordX: newLocalLat,
    coordY: newLocalLong,
    dead: deadOrAlive,
    qa: [],
  };

  // Manually assign IDs to specific fields and add to qa array
  if (crawlWidth !== undefined && crawlWidth !== null && crawlWidth !== "") {
    newData.qa.push({ question_id: 313, content: crawlWidth });
  }
  if (
    femEncTagLeft !== undefined &&
    femEncTagLeft !== null &&
    femEncTagLeft !== ""
  ) {
    newData.qa.push({ question_id: 298, content: femEncTagLeft });
  }
  if (
    femEncTagRight !== undefined &&
    femEncTagRight !== null &&
    femEncTagRight !== ""
  ) {
    newData.qa.push({ question_id: 299, content: femEncTagRight });
  }
  if (
    nestHighTideDist !== undefined &&
    nestHighTideDist !== null &&
    nestHighTideDist !== ""
  ) {
    newData.qa.push({ question_id: 300, content: nestHighTideDist });
  }
  if (nestStatus !== undefined && nestStatus !== null && nestStatus !== "") {
    newData.qa.push({ question_id: 301, content: nestStatus });
  }
  if (
    nestEstHatchDate !== undefined &&
    nestEstHatchDate !== null &&
    nestEstHatchDate !== ""
  ) {
    newData.qa.push({
      question_id: 302,
      content: formatDate5(nestEstHatchDate),
    });
  }
  if (
    nestEstExhumDate !== undefined &&
    nestEstExhumDate !== null &&
    nestEstExhumDate !== ""
  ) {
    newData.qa.push({
      question_id: 303,
      content: formatDate5(nestEstExhumDate),
    });
  }

  if (nestID !== undefined && nestID !== null && nestID !== "") {
    newData.qa.push({ question_id: 321, content: nestID });
  }
  if (
    relocateSite !== undefined &&
    relocateSite !== null &&
    relocateSite !== ""
  ) {
    newData.qa.push({ question_id: 320, content: relocateSite });
  }
  if (
    relocateSegment !== undefined &&
    relocateSegment !== null &&
    relocateSegment !== ""
  ) {
    newData.qa.push({ question_id: 336, content: relocateSegment });
  }
  if (nestHealth !== undefined && nestHealth !== null && nestHealth !== "") {
    newData.qa.push({ question_id: 337, content: nestHealth });
  }
  if (notes !== undefined && notes !== null && notes !== "") {
    newData.qa.push({ question_id: 312, content: notes });
  }
  if (
    reasonForLost !== undefined &&
    reasonForLost !== null &&
    reasonForLost !== ""
  ) {
    newData.qa.push({ question_id: 338, content: reasonForLost });
  }
  if (
    numberOfEggs !== undefined &&
    numberOfEggs !== null &&
    numberOfEggs !== ""
  ) {
    newData.qa.push({ question_id: 339, content: numberOfEggs });
  }
  if (
    femEncWidth !== undefined &&
    femEncWidth !== null &&
    femEncWidth !== "" &&
    femEncWidth !== 0
  ) {
    newData.qa.push({ question_id: 314, content: femEncWidth });
  }
  if (
    femEncLength !== undefined &&
    femEncLength !== null &&
    femEncLength !== "" &&
    femEncWidth !== 0
  ) {
    newData.qa.push({ question_id: 315, content: femEncLength });
  }

  return newData;
};
