import Image from "react-bootstrap/Image";
import "./styles.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

const NavHeader = () => {
  // Translate switcher
  const { t, i18n } = useTranslation();

  const handleSetLang = useCallback(
    (val) => {
      setLanguage(val);
      localStorage.setItem("localLangVal", val);
      if (val === "French") {
        i18n.changeLanguage("fr");
      } else {
        i18n.changeLanguage("en");
      }
    },
    [i18n]
  );

  const lang = localStorage.getItem("localLangVal");

  useEffect(() => {
    if (!lang || lang === "English") {
      handleSetLang("English");
    } else {
      setLanguage(lang);
      handleSetLang("French");
    }
  }, [handleSetLang, lang]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [dropDownHidden, setDropDownHidden] = useState(true);
  const [mobileMenuStatus, setMobileMenuStatus] = useState("");
  const [mobileLanguageMenuStatus, setMobileLanguageMenuStatus] = useState("");
  const [language, setLanguage] = useState("English");
  const [languageHidden, setLanguageHidden] = useState(true);

  const languageMenuRef = useRef();
  const menuRef = useRef();

  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const handleDropDownClicker = () => {
    if (window.innerWidth <= 786) {
      setMobileMenuStatus("mobileShown");
    } else {
      setDropDownHidden((state) => !state);
    }
  };

  const hideMenu = () => {
    if (window.innerWidth <= 786) {
      setMobileMenuStatus("mobileHidden");
      setTimeout(() => {
        setMobileMenuStatus("");
        setMobileLanguageMenuStatus("");
      }, 500);
    } else {
      setDropDownHidden(true);
    }
  };

  const toggleLanguageDropDown = () => {
    if (mobileLanguageMenuStatus !== "expandLanguageMenu") {
      setMobileLanguageMenuStatus("expandLanguageMenu");
    } else {
      setMobileLanguageMenuStatus("shrinkLanguageMenu");
      setTimeout(() => {
        setMobileLanguageMenuStatus("");
      }, 500);
    }
  };

  useEffect(() => {
    const hideMenus = (e) => {
      if (
        languageMenuRef.current &&
        !languageMenuRef.current.contains(e.target)
      ) {
        setLanguageHidden(true);
      }

      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setDropDownHidden(true);
      }
    };

    document.addEventListener("click", hideMenus);

    return () => {
      document.removeEventListener("click", hideMenus);
    };
  }, []);

  if (userInfo) {
    history.push("/map");
  }

  return (
    <>
      <div id="landingHeader" className="container-fluid p-0 ">
        <div className="header px-3 position-relative">
          <div className="col-md-2 logo-center navHeaderLogoWrapper">
            <Link to="/">
              <img
                src="assets/elements/images/logo/logo.png"
                alt=""
                className="navHeaderLogo"
              />
            </Link>
          </div>

          <>
            <div className="menu-button-top h-100 mh-100 text-right ">
              <div
                className="navHeaderLanguageWrapper"
                onClick={() => setLanguageHidden((state) => !state)}
                ref={languageMenuRef}
              >
                <Image
                  className="landingHeaderFlag"
                  alt="flag"
                  src={`assets/landing${language}Flag.png`}
                />
                {t(language.toLocaleLowerCase())}
                <Image alt="flag" src="assets/elements/images/caret_down.png" />
                <div
                  className={`navHeaderLanguageDropDown  ${
                    languageHidden ? "dropDownHidden" : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      handleSetLang("English");
                    }}
                  >
                    {t("english")}
                    {language === "English" && (
                      <Image src="assets/images/selected.png" alt="check" />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleSetLang("French");
                    }}
                  >
                    {t("french")}
                    {language === "French" && (
                      <Image src="assets/images/selected.png" alt="check" />
                    )}
                  </div>
                </div>
              </div>
              <Link to="/login" className="navHeader-signup">
                <Image alt="lock" src="assets/elements/images/icon/lock.svg" />
                {t("login")} / {t("signup")}
              </Link>
              <span className="navHeader-dropDownWrapper" ref={menuRef}>
                <img
                  className="navHeader-sandwhich"
                  src="assets/elements/images/icon/sanduich.svg"
                  alt=""
                  onClick={handleDropDownClicker}
                />
                <div
                  className={`navHeader-dropDown ${
                    dropDownHidden ? "dropDownHidden" : ""
                  } ${mobileMenuStatus}`}
                >
                  <div className="navHeader-dropDown-header">
                    <h2>Menu</h2>
                    <Image
                      src="/static/media/close.b0b7aeeac8a9ea71e5743883d4b91647.svg"
                      alt="exit"
                      className="navHeader-dropDown-header-exit"
                      // onClick={() => setDropDownHidden(true)}
                      onClick={hideMenu}
                    />
                  </div>
                  <a href="#latestNews" onClick={hideMenu}>
                    {t("landingPage.navbar.latestNews")}
                  </a>
                  <a href="#howItWorks" onClick={hideMenu}>
                    {t("landingPage.navbar.howWorks")}
                  </a>
                  <a href="#getInvolved" onClick={hideMenu}>
                    {t("landingPage.navbar.getInvolved")}
                  </a>

                  <a href="mailto:info@ammco.org" onClick={hideMenu}>
                    {t("landingPage.navbar.contactUs")}
                  </a>
                  <div
                    className="navHeader-dropDown-div d-block d-md-none"
                    onClick={toggleLanguageDropDown}
                  >
                    {t("landingPage.navbar.language")}
                  </div>
                  <div
                    className={`mobile-language ${mobileLanguageMenuStatus}`}
                  >
                    <div
                      className="navHeader-dropDown-subDiv  d-flex d-md-none"
                      onClick={() => {
                        // setLanguage("English");
                        handleSetLang("English");
                      }}
                    >
                      <Image
                        className="landingHeaderFlag"
                        alt="flag"
                        src="assets/landingFrenchFlag.png"
                      />
                      {t("english")}
                      {language === "English" && (
                        <Image src="assets/images/selected.png" alt="check" />
                      )}
                    </div>
                    <div
                      className="navHeader-dropDown-subDiv d-flex d-md-none"
                      onClick={() => {
                        // setLanguage("French")
                        handleSetLang("French");
                      }}
                    >
                      <Image
                        className="landingHeaderFlag"
                        alt="flag"
                        src="assets/landingEnglishFlag.png"
                      />
                      {t("french")}
                      {language === "French" && (
                        <Image src="assets/images/selected.png" alt="check" />
                      )}
                    </div>
                  </div>

                  <Link className="navHeader-dropDown-login" to="/login">
                    {t("login")} / {t("signup")}
                  </Link>
                </div>
              </span>
            </div>
          </>
        </div>
      </div>
      <div className="landingHeader-fillerBlock"></div>
    </>
  );
};

export default NavHeader;
