import "./styles.css";
import Container from "react-bootstrap/Container";

function TitleComponent(props) {
  return (
    <Container fluid className="p-0">
      <h2 className="titleBackground text-left">{props.children}</h2>
    </Container>
  );
}

export default TitleComponent;
