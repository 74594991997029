import React from "react";
import TitleComponent from "../../TitleComponent";
import Container from "react-bootstrap/Container";
import GetInvolvedCard from "../../GetInvolvedCard";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function GetInvolved() {
  const { t } = useTranslation();
  const getInvolvedCards = [
    {
      img: "./assets/partnerWithUs.png",
      title: `${t("landingPage.content.getInvolved.card1.title")}`,
      text: `${t("landingPage.content.getInvolved.card1.details")}`,
      btnText: `${t("landingPage.content.getInvolved.card1.btn")}`,
      link: "mailto:info@ammco.org",
      // onClick: handleAmmcoEmailClick,
    },
    {
      img: "./assets/getInvolvedDonate.png",
      title: `${t("landingPage.content.getInvolved.card2.title")}`,
      text: `${t("landingPage.content.getInvolved.card2.details")}`,
      btnText: `${t("landingPage.content.getInvolved.card2.btn")}`,
      link: "https://donate.wildnet.org/?fund=african_manatee",
    },
    {
      img: "./assets/getInvolvedVolunteer.png",
      title: `${t("landingPage.content.getInvolved.card3.title")}`,
      text: `${t("landingPage.content.getInvolved.card3.details")}`,
      btnText: `${t("landingPage.content.getInvolved.card3.btn")}`,
      link: "https://ammco.org/volontaires",
    },
  ];

  function handleAmmcoEmailClick() {
    window.location.href = "mailto:info@ammco.org";
  }

  return (
    <section id="getInvolved">
      <TitleComponent>
        {t("landingPage.content.getInvolved.title")}
      </TitleComponent>
      <Container fluid className="getInvolved">
        {/* <h3 className="getInvolvedTitle">How Can You Help?</h3> */}
        <div className="getInvolvedCardWrapper">
          {getInvolvedCards.map((card, index) => (
            <div key={index}>
              <GetInvolvedCard
                img={card.img}
                title={card.title}
                text={card.text}
                btnText={card.btnText}
                link={card.link}
                onClick={card.onClick}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
