import { create } from "zustand";

export const usePatrolVariables = create((set) => ({
  currentPage: null,
  totalPageNumber: null,
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  setTotalPageNumber: (totalPageNumber) => {
    set({ totalPageNumber: totalPageNumber });
  },
}));

export const useObservationsQueryInfo = create((set) => ({
  validObscurrentPage: 0,
  validTotalObs: 0,
  setValidObscurrentPage: (page) => {
    set({ validObscurrentPage: page });
  },
  setValidTotalObs: (totalPageNumber) => {
    set({ validTotalObs: totalPageNumber });
  },
  // invalide
  invalidObscurrentPage: 0,
  invalidTotalObs: 0,
  setinvalidObscurrentPage: (page) => {
    set({ invalidObscurrentPage: page });
  },
  setinvalidTotalObs: (total) => {
    set({ invalidTotalObs: total });
  },
}));
