import { useMap } from "react-leaflet";
import ModalMarker from "../../map/leafletMap/ModalMarker";
import "../style.css";
function SetMap({ location, lat, long, typeObservation }) {
  const map = useMap();
  if (true) {
    map.flyTo(location, 8);
  }

  return lat && long ? (
    <ModalMarker
      observation={null}
      lat={lat}
      long={long}
      typeObservation={typeObservation}
    />
  ) : null;
}
export default SetMap;
