import { Icon } from "leaflet";
import iconMenace from "../assets/mapMarkrers/menace.svg";
import patrolStart from "../assets/mapMarkrers/PatrolStart.svg";
import patrolEnd from "../assets/mapMarkrers/patrolEnd.svg";
import obsSelectedIcon from "../assets/mapMarkrers/obsSelectedIcon.svg";
import others from "../assets/mapMarkrers/others.svg";

import threat from "../assets/mapMarkrers/treath.svg";
import crawl from "../assets/mapMarkrers/crawl.svg";
import fencounter from "../assets/mapMarkrers/fencounter.svg";
import flash from "../assets/mapMarkrers/flash.svg";
import presence from "../assets/mapMarkrers/presence.svg";
import nest from "../assets/mapMarkrers/nest.svg";
import animal from "../assets/mapMarkrers/animal.svg";

// selected iconsimport

import othersSelected from "../assets/mapSlectedIcons/otherSelected.svg";

import threatSelected from "../assets/mapSlectedIcons/threatSelected.svg";
import crawlSelected from "../assets/mapSlectedIcons/cwralSelected.svg";
import fencounterSelected from "../assets/mapSlectedIcons/femalEncounSelected.svg";
import flashSelected from "../assets/mapSlectedIcons/flashSelected.svg";
import presenceSelected from "../assets/mapSlectedIcons/sPrensenceSelected.svg";
import nestSelected from "../assets/mapSlectedIcons/nestSelected.svg";
import animalSelected from "../assets/mapSlectedIcons/animalSelected.svg";
import { useMapEvents } from "react-leaflet";
import { useState } from "react";

export const useCustomIconList = () => {
  const [iconSize, setIconSize] = useState([25, 25]);
  const [currentZoom, setCurrentZoom] = useState("");

  const map = useMapEvents({
    zoomend: () => {
      // Get the current zoom level
      const zoom = map.getZoom();

      setCurrentZoom(zoom);
      // Calculate the new size based on the zoom level
      const newSize = 3 * zoom;

      // Set the new size of the marker
      setIconSize([newSize, newSize]);
    },
  });
  const CustomIconList = {
    menace: new Icon({
      iconUrl: iconMenace,
      iconSize: iconSize,
      // className,
    }),
    threats: new Icon({
      iconUrl: threat,
      iconSize: iconSize,
      // className,
    }),
    animal: new Icon({
      iconUrl: animal,
      iconSize: iconSize,
      // className,
    }),
    nest: new Icon({
      iconUrl: nest,
      iconSize: iconSize,
      // className,
    }),
    presence: new Icon({
      iconUrl: presence,
      iconSize: iconSize,
      // className,
    }),
    flash: new Icon({
      iconUrl: flash,
      iconSize: iconSize,
      // className,
    }),
    fencounter: new Icon({
      iconUrl: fencounter,
      iconSize: iconSize,
      // className,
    }),
    crawl: new Icon({
      iconUrl: crawl,
      iconSize: iconSize,
      // className,
    }),
    others: new Icon({
      iconUrl: others,
      iconSize: iconSize,
      // className,
    }),

    colorIcon: new Icon({
      iconUrl: obsSelectedIcon,
      iconSize: iconSize,
    }),
  };
  const PatrolIcons = {
    patrolStart: new Icon({
      iconUrl: patrolStart,
      iconSize: iconSize,
      // className,
    }),
    patrolEnd: new Icon({
      iconUrl: patrolEnd,
      iconSize: iconSize,
      // className,
    }),
  };

  // selected obs
  const selectedIconList = {
    threat: new Icon({
      iconUrl: threatSelected,
      iconSize: iconSize,
      // className,
    }),

    animal: new Icon({
      iconUrl: animalSelected,
      iconSize: iconSize,
      // className,
    }),
    nest: new Icon({
      iconUrl: nestSelected,
      iconSize: iconSize,
      // className,
    }),
    presence: new Icon({
      iconUrl: presenceSelected,
      iconSize: iconSize,
      // className,
    }),
    flash: new Icon({
      iconUrl: flashSelected,
      iconSize: iconSize,
      // className,
    }),
    fencounter: new Icon({
      iconUrl: fencounterSelected,
      iconSize: iconSize,
      // className,
    }),
    crawl: new Icon({
      iconUrl: crawlSelected,
      iconSize: iconSize,
      // className,
    }),
    others: new Icon({
      iconUrl: othersSelected,
      iconSize: iconSize,
      // className,
    }),
  };
  return { CustomIconList, selectedIconList, currentZoom, PatrolIcons };
};

// selected obs
export const useSelectedIconList = () => {
  const [c_iconSize] = useState([50, 50]);
  const selectedIconList = {
    threat: new Icon({
      iconUrl: threatSelected,
      iconSize: c_iconSize,
      // className,
    }),

    animal: new Icon({
      iconUrl: animalSelected,
      iconSize: c_iconSize,
      // className,
    }),
    nest: new Icon({
      iconUrl: nestSelected,
      iconSize: c_iconSize,
      // className,
    }),
    presence: new Icon({
      iconUrl: presenceSelected,
      iconSize: c_iconSize,
      // className,
    }),
    flash: new Icon({
      iconUrl: flashSelected,
      iconSize: c_iconSize,
      // className,
    }),
    fencounter: new Icon({
      iconUrl: fencounterSelected,
      iconSize: c_iconSize,
      // className,
    }),
    crawl: new Icon({
      iconUrl: crawlSelected,
      iconSize: c_iconSize,
      // className,
    }),
    others: new Icon({
      iconUrl: othersSelected,
      iconSize: c_iconSize,
      // className,
    }),
  };
  return { selectedIconList };
};
