import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "react-date-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import useCreateValidation from "../../../../../../lib/createObs/useCreateValidation";
import {
  useCreateNewFemaleEncounter,
  useCreateObservationStore,
} from "../../../../../../lib/createObs/useCreateObservationData";
import { useFetchSiteSgmntForRelocation } from "../../../../../../queries/useFetchOptions";
import { useSelector } from "react-redux";

function CreateNewNest({ errors, setChangeTracker, modalRef }) {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const {
    siteSegmentList,
    siteSegmentLoading,
    refetchSiteSegment,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntForRelocation(localProjectId);

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);
  const { femaleCreated } = useCreateNewFemaleEncounter();
  const {
    localNestIDValidation,
    localNestStatusValidation,
    localNestHealthValidation,
    localNestRelocateSiteValidation,
    localNestFemalePresence,
    localSuspectedNestCreationDateValidation,
    localNestEstHatchDateValidation,
    localNestEstExhumDateValidation,
    localReasonForLostValidation,

    setLocalNestIDValidation,
    setLocalNestStatusValidation,
    setLocalNestHealthValidation,
    setLocalNestRelocationValidation,
    setLocalNestFemalePresenceValidation,
    setLocalSuspectedNestCreationDateValidation,
    setLocalNestEstHatchDateValidation,
    setLocalNestEstExhumDateValidation,
    setLocalNestRelocateSiteValidation,
    setLocalReasonForLostValidation,
  } = useCreateValidation();

  // Data management

  const {
    nestStatus,
    nestHealth,
    newDate,
    siteState,
    displaySite,
    suspectedNestCreationDate,
    isFemalePresent,
    crawlWidth,
    setCrawlWidth,
    nestEstHatchDate,
    nestEstExhumDate,
    doesNewNestNeedRelocation,
    isNewNestIntact,
    segmentState,
    // customSegmentList,
    relocateSite,
    relocateSegment,
    nestID,
    nestHighTideDist,
    setNestID,
    setNestHighTideDist,
    setNestStatus,
    setNestHealth,
    setNestRelocation,
    setSuspectedNestCreationDate,
    setNestEstHatchDate,
    setNestEstExhumDate,
    setIsFemalePresent,
    setRelocateSite,
    setRelocateSegment,
    setNumberOfEggs,
    setDoesNewNestNeedRelocation,
    setIsNewNestIntact,
    setReasonForLost,
  } = useCreateObservationStore();

  const [customSegmentList, setCustomSegmentList] = useState([]);
  const handleSetSegmentList = (seletedSiteId) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((site) => {
        if (site.siteId === Number(seletedSiteId)) {
          setCustomSegmentList(site.segment);
        }
        return site.segment;
      });
    }
  };
  // relocation sites

  useEffect(() => {
    if (siteState && !relocateSite) {
      setRelocateSite(siteState);
      handleSetSegmentList(siteState);
    }
  }, [siteState, segmentState]);
  console.log({ siteState, segmentState, relocateSite, relocateSegment });
  const { t } = useTranslation();

  const exhumation = 65;
  const hatch = 60;

  useEffect(() => {
    if (femaleCreated) {
      const curentDate = new Date(newDate);
      const exhumDate = new Date(curentDate); // Create a new date object
      exhumDate.setDate(exhumDate.getDate() + exhumation);
      setNestEstExhumDate(exhumDate);

      const hatchDate = new Date(curentDate); // Create another new date object
      hatchDate.setDate(hatchDate.getDate() + hatch);
      setNestEstHatchDate(hatchDate);
    } else {
      if (suspectedNestCreationDate) {
        const suspectedDate = new Date(suspectedNestCreationDate);
        const exhumDate = new Date(suspectedDate); // Create a new date object
        exhumDate.setDate(exhumDate.getDate() + exhumation);
        setNestEstExhumDate(exhumDate);

        const hatchDate = new Date(suspectedDate); // Create another new date object
        hatchDate.setDate(hatchDate.getDate() + hatch);
        setNestEstHatchDate(hatchDate);
        setLocalNestEstHatchDateValidation(false);
        setLocalNestEstExhumDateValidation(false);
      } else {
        const curentDate = new Date();
        setSuspectedNestCreationDate(curentDate);
        const exhumDate = new Date(curentDate); // Create a new date object
        exhumDate.setDate(exhumDate.getDate() + exhumation);
        setNestEstExhumDate(exhumDate);

        const hatchDate = new Date(curentDate); // Create another new date object
        hatchDate.setDate(hatchDate.getDate() + hatch);
        setNestEstHatchDate(hatchDate);
      }
    }
  }, [
    isFemalePresent,
    newDate,
    femaleCreated,
    setNestEstExhumDate,
    setNestEstHatchDate,
    suspectedNestCreationDate,
    setSuspectedNestCreationDate,
    setLocalNestEstHatchDateValidation,
    setLocalNestEstExhumDateValidation,
  ]);

  useEffect(() => {
    if (siteState) {
      setRelocateSite(siteState);
    }
  }, [setRelocateSite, siteState]);
  // filter segment lsit
  const [filteredSiteList, setFilteredSiteList] = useState([]);
  useEffect(() => {
    if (siteSegmentList) {
      // const filteredList = siteSegmentList.data.filter(
      //   (item) => item.siteId !== Number(siteState)
      // );
      setFilteredSiteList(siteSegmentList.data);
    }
  }, [siteSegmentList, siteState]);

  return (
    <>
      {/* Nest ID */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.nestId")} <sup>*</sup>
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="nest-id"
            name="nestID"
            value={nestID}
            className={`crud_select crud-input ${
              localNestIDValidation ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              setNestID(e.target.value);

              if (e.target.value?.trim() !== "") {
                setLocalNestIDValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestIDValidation(true);
              }
              setLocalNestIDValidation(false);
            }}
          />
          {localNestIDValidation && <p className="error">{t("required")}</p>}
        </div>
      </div>

      {/* Nest High Tide Distance */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.distanceHideTide")}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="numeric"
              type="number"
              id="high-tide-dist"
              name="highTideDist"
              value={nestHighTideDist ? nestHighTideDist : ""}
              className={`crud_select crud-input `}
              style={{ width: "101%" }}
              onChange={(e) => {
                setNestHighTideDist(e.target.value);
                if (e.target.value?.trim() !== "") {
                  setChangeTracker(true);
                } else {
                  setChangeTracker(false);
                }
              }}
            />
            <span className="unit">(m)</span>
          </div>
        </div>
      </div>
      {/* localNestIDValidation */}
      {/* Crawl Width */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.crawlWidth")}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="numeric"
              type="number"
              id="crawl-width"
              name="crawlWidth"
              className={`crud_select crud-input`}
              style={{ width: "101%" }}
              value={crawlWidth && crawlWidth}
              onChange={(e) => {
                setCrawlWidth(e.target.value);

                if (e.target.value?.trim() !== "") {
                  setChangeTracker(true);
                } else {
                  setChangeTracker(false);
                }
              }}
            />
            <span className="unit">(cm)</span>
          </div>
        </div>
      </div>

      {/* Nest Status */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.nestStatus")}
        </div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-status"
            name="nestStatus"
            className={`crud_select crud-input ${
              localNestStatusValidation ? "is-invalid" : ""
            }`}
            value={nestStatus}
            style={{ width: "101%" }}
            onChange={(e) => {
              setNestStatus(e.target.value);

              if (e.target.value?.trim() !== "") {
                setLocalNestStatusValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestStatusValidation(true);
                setChangeTracker(false);
              }
            }}
          >
            <option value="">({` ${t("select")}`})</option>
            <option value="Unhatched">
              {t("createNestFields.nestStatusOptions.unhatched")}
            </option>
            <option value="Hatched">
              {t("createNestFields.nestStatusOptions.hatched")}
            </option>
            <option value="Exhumed">
              {t("createNestFields.nestStatusOptions.exhumed")}
            </option>
          </select>
          {localNestStatusValidation && (
            <p className="error">{t("required")}</p>
          )}
        </div>
      </div>

      {/* Nest Health */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.nestHealth")}
        </div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-health"
            name="nestHealth"
            className={`crud_select crud-input ${
              localNestHealthValidation ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              const value = e.target.value;
              setNestHealth(value);
              if (value === "Intact") {
                setIsNewNestIntact(true);
                setLocalReasonForLostValidation(false);
              } else {
                setIsNewNestIntact(false);
              }

              if (value?.trim() !== "") {
                setLocalNestHealthValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestHealthValidation(true);
                setChangeTracker(false);
              }
            }}
            value={nestHealth}
          >
            <option value="">({` ${t("select")}`})</option>
            <option value="Intact">
              {t("createNestFields.nestHealthOptions.intact")}
            </option>
            <option value="Partially lost">
              {t("createNestFields.nestHealthOptions.partiallyLost")}
            </option>
            <option value="Totally lost">
              {t("createNestFields.nestHealthOptions.totallyLost")}
            </option>
            <option value="Others">
              {t("createNestFields.nestHealthOptions.other")}
            </option>
          </select>
          {/* Intact, Partially Lost, Totally Lost, Other */}
          {localNestHealthValidation && (
            <p className="error">{t("required")}</p>
          )}
        </div>
      </div>

      {/* Dynamic Reason for Loss */}
      {!isNewNestIntact && (
        <div className="row px-3 modal__obs__details__row mt-1">
          <div className="col-5 modal__detail__title">
            {t("createNestFields.reasonForLost")}
          </div>
          <div className="col-7 modal__title__value">
            <select
              id="reason-for-loss"
              name="reasonForLoss"
              className={`crud_select crud-input ${
                errors.reasonForLoss ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              onChange={(e) => {
                setReasonForLost(e.target.value);

                if (e.target.value?.trim() !== "") {
                  setLocalReasonForLostValidation(false);
                  setChangeTracker(true);
                } else {
                  setLocalReasonForLostValidation(true);
                  setChangeTracker(false);
                }
              }}
            >
              <option disabled selected value="">
                ({t("select")})
              </option>
              <option value="Flooding">
                {t("createNestFields.lostOptions.flooding")}
              </option>
              <option value="Other turtle">
                {" "}
                {t("createNestFields.lostOptions.otherTurtles")}
              </option>
              <option value="Predator">
                {" "}
                {t("createNestFields.lostOptions.predator")}
              </option>
              <option value="Unknown">
                {" "}
                {t("createNestFields.lostOptions.unknown")}
              </option>
              <option value="Other">
                {" "}
                {t("createNestFields.lostOptions.other")}
              </option>
            </select>
            {localReasonForLostValidation && (
              <p className="error">{t("required")}</p>
            )}
          </div>
        </div>
      )}

      {/* Nest Relocation */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {t("createNestFields.nestRelocate")}
        </div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-relocation"
            name="nestRelocation"
            className={`crud_select crud-input `}
            style={{ width: "101%" }}
            onChange={(e) => {
              const needsRelocation = e.target.value === "true";
              setDoesNewNestNeedRelocation(needsRelocation);
              if (e.target.value?.trim() !== "") {
                setLocalNestRelocationValidation(true);
                setChangeTracker(true);
              } else {
                setLocalNestRelocationValidation(false);
                setChangeTracker(false);
              }
              setNestRelocation(needsRelocation);
            }}
          >
            <option value="false"> {t("createNestFields.no")}</option>
            <option value="true"> {t("createNestFields.yes")}</option>
          </select>
        </div>
      </div>

      {/* Dynamic Relocation Details */}
      {doesNewNestNeedRelocation && (
        <>
          {/* Relocation Site*/}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {" "}
              {t("createNestFields.relocationSite")}
            </div>
            <div className="col-7 modal__title__value">
              <select
                className={`crud_select crud-input ${
                  errors.site ? "is-invalid" : ""
                }`}
                name="site"
                id="site"
                style={{
                  width: "101%",
                  cursor: `${
                    siteSegmentLoading || siteSegmentIsRefetching
                      ? "not-allowed"
                      : "pointer"
                  }`,
                }}
                value={relocateSite}
                disabled={
                  siteSegmentLoading || siteSegmentIsRefetching ? true : false
                }
                onChange={(e) => {
                  handleSetSegmentList(e.target.value);
                  setRelocateSite(e.target.value);
                  setRelocateSegment("");
                  if (e.target.value?.trim() !== "") {
                    setLocalNestRelocateSiteValidation(false);
                    setChangeTracker(true);
                  } else {
                    setLocalNestRelocateSiteValidation(true);
                    setChangeTracker(false);
                  }
                }}
              >
                {siteSegmentLoading ||
                siteSegmentLoading ||
                siteSegmentIsRefetching ? (
                  <option disabled selected value="">
                    Loading...
                  </option>
                ) : (
                  <option disabled selected value="">
                    ({` ${t("select")}`})
                  </option>
                )}
                <option disabled selected value="">
                  {displaySite}
                </option>

                {filteredSiteList.map((s, i) => (
                  <option value={s.siteId} key={i}>
                    {s.site}
                  </option>
                ))}
              </select>

              {localNestRelocateSiteValidation && (
                <p className="error">{t("required")}</p>
              )}
            </div>
          </div>

          {/* Relocation Segment */}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {" "}
              {t("createNestFields.relocateSegment")}
            </div>
            <div className="col-7 modal__title__value">
              <select
                className={`crud_select crud-input ${
                  errors.segment ? "is-invalid" : ""
                }`}
                id="segment"
                name="segment"
                style={{
                  width: "101%",
                  cursor: `${
                    siteSegmentLoading || siteSegmentIsRefetching
                      ? "not-allowed"
                      : "pointer"
                  }`,
                }}
                disabled={
                  siteSegmentLoading || siteSegmentIsRefetching ? true : false
                }
                onChange={(e) => {
                  setRelocateSegment(e.target.value);
                  setChangeTracker(true);
                }}
                value={relocateSegment}
              >
                {siteSegmentLoading ||
                siteSegmentLoading ||
                siteSegmentIsRefetching ? (
                  <option disabled selected value="">
                    Loading...
                  </option>
                ) : (
                  <option disabled selected value="">
                    ({` ${t("select")}`})
                  </option>
                )}
                {customSegmentList &&
                  customSegmentList.map((s, i) => (
                    <option value={s.id} key={i}>
                      {s.name}
                    </option>
                  ))}
              </select>
              <p className="error">{errors.segment?.message}</p>
            </div>
          </div>

          {/* Number of Eggs Relocated */}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {t("createNestFields.numOfEggsRelocalise")}
            </div>
            <div className="col-7 modal__title__value">
              <input
                type="number"
                className={`crud_select crud-input ${
                  errors.eggsRelocated ? "is-invalid" : ""
                }`}
                id="eggs-relocated"
                name="eggsRelocated"
                style={{ width: "101%" }}
                onChange={(e) => {
                  setNumberOfEggs(e.target.value);
                  setChangeTracker(true);
                }}
              />
              <p className="error">{errors.eggsRelocated?.message}</p>
            </div>
          </div>
        </>
      )}

      {/* Female Presence */}
      {!femaleCreated && (
        <div className="row px-3 modal__obs__details__row mt-1">
          <div className="col-5 modal__detail__title">
            {" "}
            {t("createNestFields.femalePresence")}
          </div>
          <div className="col-7 modal__title__value">
            <select
              id="is-female-present"
              name="isFemalePresent"
              className={`crud_select crud-input ${
                localNestFemalePresence ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              value={isFemalePresent.toString()}
              onChange={(e) => {
                const newValue = e.target.value === "true";
                setIsFemalePresent(newValue);
                if (e.target.value.trim() !== "") {
                  setLocalNestFemalePresenceValidation(false);
                  setChangeTracker(true);
                } else {
                  setLocalNestFemalePresenceValidation(true);
                  setChangeTracker(false);
                }
              }}
            >
              <option value="true">{t("createNestFields.yes")}</option>
              <option value="false">{t("createNestFields.no")}</option>
            </select>

            {localNestFemalePresence && (
              <p className="error">{t("required")}</p>
            )}
          </div>
        </div>
      )}

      {/* Dynamic Female Presence */}
      {!femaleCreated && (
        <div className="row px-3 modal__obs__details__row mt-1">
          <div className="col-5 modal__detail__title">
            {t("createNestFields.suspectedNestCreation")}
          </div>
          <div className="col-7 modal__title__value nest">
            <DatePicker
              calendarIcon={<AiOutlineCalendar />}
              clearIcon={null}
              onChange={(date) => {
                setSuspectedNestCreationDate(date);
                setLocalSuspectedNestCreationDateValidation(false);
              }}
              // calendarClassName="calender__picker"
              className={`calender__picker`}
              value={suspectedNestCreationDate}
              required={true}
              format={"y/MM/dd"}
            />
            {localSuspectedNestCreationDateValidation && (
              <p className="error">{t("required")}</p>
            )}
          </div>
        </div>
      )}

      {/* Estimated Hatch Date */}
      <div
        className="row px-3 modal__obs__details__row mt-1"
        onClick={() => {
          if (modalRef.current) {
            modalRef.current.scrollTop = modalRef.current.scrollHeight; // Scroll to the bottom
          }
        }}
      >
        <div className="col-5 modal__detail__title">
          {t("createNestFields.estimatedHatchDate")}
        </div>
        <div className="col-7 modal__title__value nest">
          <DatePicker
            calendarIcon={<AiOutlineCalendar />}
            clearIcon={null}
            onChange={(date) => {
              setNestEstHatchDate(date);
              setLocalNestEstHatchDateValidation(false);
            }}
            // calendarClassName="calender__picker"
            className={`calender__picker`}
            value={nestEstHatchDate}
            required={true}
            format={"y/MM/dd"}
          />

          {localNestEstHatchDateValidation && (
            <p className="error">{t("required")}</p>
          )}
        </div>
      </div>

      {/* Estimated Exhumation Date */}
      <div
        className="row px-3 modal__obs__details__row mt-1"
        onClick={() => {
          if (modalRef.current) {
            modalRef.current.scrollTop = modalRef.current.scrollHeight; // Scroll to the bottom
          }
        }}
      >
        <div className="col-5 modal__detail__title">
          {t("createNestFields.estimatedExhumDate")}
        </div>
        <div className="col-7 modal__title__value nest">
          <DatePicker
            calendarIcon={<AiOutlineCalendar />}
            clearIcon={null}
            onChange={(date) => {
              setNestEstExhumDate(date);
              setLocalNestEstExhumDateValidation(false);
            }}
            // calendarClassName="calender__picker"
            className={`calender__picker`}
            value={nestEstExhumDate}
            required={true}
            format={"y/MM/dd"}
          />

          {localNestEstExhumDateValidation && (
            <p className="error">{t("required")}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateNewNest;
