import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCreateObservationStore } from "../lib/createObs/useCreateObservationData";

export const useGetCountryCoordinates = () => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const [Latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [country, setCountry] = useState("");
  const [loadingCountryCoords, setLoadingCountryCoords] = useState(true);
  const { setNewLocalLat, setNewLocalLong } = useCreateObservationStore();

  useEffect(() => {
    const projectInfo = JSON.parse(localStorage.getItem("selectedProject"));
    if (projectInfo) {
      setCountry(projectInfo.country);
      setNewLocalLong(null);
      setNewLocalLat(null);
    }
  }, [localProjectId]);
  useEffect(() => {
    const getCountryCoordinates = async () => {
      try {
        setLoadingCountryCoords(true);
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${country}&limit=1`
        );
        const data = await response.json();

        if (data.length > 0) {
          const { lat, lon } = data[0];
          setLatitude(lat);
          setLongitude(lon);
        }
        setLoadingCountryCoords(false);
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        setLatitude(3.8077106784038484);
        setLongitude(11.938656757942695);

        setLoadingCountryCoords(false);
      }
    };

    getCountryCoordinates();
  }, [country, longitude, Latitude]);

  return {
    countryLat: Number(Latitude),
    countryLong: Number(longitude),

    loadingCountryCoords: loadingCountryCoords,
  };
};
