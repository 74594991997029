import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import HawksbillTurtlesCaseStudy from "./CaseStudies/HawksbillTurtlesCaseStudy";
import ElasmobranchiiCaseStudy from "./CaseStudies/ElasmobranchiiCaseStudy";
import { useLocation } from "react-router-dom";

function CaseStudy() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { studyName } = useParams();

  const studies = {
    "protecting-critically-endangered-hawksbill-turtles-in-the-middle-east-gulf":
      HawksbillTurtlesCaseStudy,
    "conserving-critically-endangered-sharks-and-rays-in-west-africa":
      ElasmobranchiiCaseStudy,
  };

  if (!(studyName in studies)) {
    return <Redirect to="/" />;
  }

  const Study = studies[studyName];
  return <Study />;
}

export default CaseStudy;
