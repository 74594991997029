const SucccessMsg = ({ message }) => {
  return (
    <div className="row pt-3 pb-3 succedmsg">
      <div className="col-md-12 offset-md-1 position-relative ">
        <svg
          width="32"
          height="22"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
            fill="#138101"
          />
        </svg>

        <span className="ml-5"> Success! {message}</span>
      </div>
    </div>
  );
};

export default SucccessMsg;
