import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import PatrolTable from "../../components/patrols/homeTable/PatrolTable";
import { useCallback } from "react";
import { useState } from "react";
import { useFetchPatrolList } from "../../queries/useFetchPatrols";
import { useSelector } from "react-redux";
import Loading2 from "../../components/LoadingError/Loading2";
import { useFetchProjectList } from "../../queries/useMapQueriesHook";
import { sirenInterNationalId } from "../../constants/projectConstants";

const Patrols = () => {
  const { projectList } = useFetchProjectList();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const {
    patrolData,
    patrolListLoading,

    isRefetchingPatrol,
    queryRefetchPatrolList,
  } = useFetchPatrolList({ id: localProjectId, enabled: true });

  const [rowData, setRowsData] = useState([]);

  useEffect(() => {
    const handleSetProjectPatrols = () => {
      const patrols =
        patrolData &&
        patrolData !== "undefined" &&
        patrolData.pages.flatMap((page) => {
          return page.data;
        });
      const reverseData = patrols?.reverse();

      setRowsData(reverseData);
    };
    handleSetProjectPatrols();
  }, [patrolData, isRefetchingPatrol]);

  // useEffect(() => {
  //   queryRefetchPatrolList();
  // }, [localProjectId, queryRefetchPatrolList, projectList]);

  const [idList, setIdList] = useState([]);

  const CallBack = useCallback(
    (childrenData) => {
      setIdList(childrenData);
      if (idList && idList.length !== 0) {
      } else {
      }

      return childrenData;
    },
    [idList]
  );

  return (
    <>
      <div className="patrol__homePage">
        <Header />
        <div className="patrole__subHeader"></div>
        {/* {isRefetchingPatrol && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",

              transform: "translate(-50%, -50%)}",
            }}
          >
            <Loading2 />
          </div>
        )} */}
        {/* {isRefetchingPatrol && (
          <div>
            <Loading2 />
          </div>
        )} */}
        <PatrolTable
          handleCallback={CallBack}
          patrolData={
            sirenInterNationalId !== Number(localProjectId) ? rowData : []
          }
          patrolListLoading={patrolListLoading}
        />
      </div>
    </>
  );
};

export default Patrols;
