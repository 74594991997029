import axios from "axios";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { API_URL } from "../../../services/API";
import { useSelector } from "react-redux";

const ShareLink = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [toggleGenerateBtn, setToggleGenerateBtn] = useState(false);
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  useEffect(() => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = userInfo.data.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(
        API_URL + `api/project/get_vip_code`,
        {
          project_id: localProjectId,
        },
        config
      )
      .then((res) => {
        setValue(
          `https://sirenammco.org/explore/map/vid=${res.data.data.vip_code}`
        );
        setLoading(false);
        setToggleGenerateBtn(true);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false);
        if (err.response.data.code === 422) {
          setToggleGenerateBtn(false);
        }

        setValue(`An error occurred. Please try again.`);
      });
  }, [localProjectId]);

  const handleGenerateLink = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = userInfo.data.token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setLoading(true);
      const response = await axios.post(
        API_URL + "api/project/vip_code",
        {
          project_id: localProjectId,
        },
        config
      );
      console.log(response);

      setValue(
        `https://sirenammco.org/explore/map/vid=${response.data.data.vip_code}`
      );
      setToggleGenerateBtn(true);
      setLoading(false);
    } catch (e) {
      console.log(e, "vip id eror ");
      setToggleGenerateBtn(false);
      setLoading(false);
      setValue(`An error occurred. Please try again.`);
    }
  };

  const [copied, setCopied] = useState(false);
  const otherChange = (e) => {
    setValue(e.target.value, { copied: false });
  };

  const otherCopy = () => setCopied(true);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [copied]);

  return (
    <>
      <div
        style={{
          maxWidth: "1060px",
          margin: "50px auto",
        }}
        className="  mt-12"
      >
        <div className=" title-text2"> Share data</div>
        <div className="d-flex">
          {!toggleGenerateBtn && (
            <button
              onClick={handleGenerateLink}
              type="button"
              className=" btn-block mt-4 login-btn admin-btn-dark"
            >
              {loading ? "Loading..." : " Generate Link"}
            </button>
          )}
          {toggleGenerateBtn && (
            <div
              className=" mt-4 flex-1 "
              style={{
                flex: "1",
                display: "flex",
                height: "41px",
              }}
            >
              <input
                type="text"
                disabled
                value={value}
                onChange={otherChange}
                className="form-control "
                style={{
                  border: "0",
                  cursor: "text",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                placeholder="https://example.com"
              />
              <CopyToClipboard onCopy={otherCopy} text={value}>
                <button
                  type="button"
                  style={{
                    height: "100%",
                    borderRadius: "0 5px 5px 0",
                    fontSize: "12px",
                    width: "90px",
                    border: "1px solid #ccc",
                    borderLeft: "0",
                    background: "#042b4a",
                    color: "#fff",
                  }}
                  className=" btn-block "
                >
                  {copied ? "Copied" : "Copy"}
                </button>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareLink;
